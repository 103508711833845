import React from 'react';
import { useNavigate, Link } from "react-router-dom";
import { authAxios } from "../../../api/axios";
import { AuthContext } from "../../../contexts/Auth";
import Button from '@mui/material/Button'
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import articleSettings from "../../../constants/fridgeArticle.json"

type Image = {
  id: string,
  type: string
}

type Article = {
  id: number,
  status: string,
  title: string,
  image: Image
}

const FridgeRecipe: React.FC = () => {
  const ctx = React.useContext(AuthContext);
  const [recipes, setRecipes] = React.useState<Article[]>([]);
  const [isLoadingDialogOpened, setLoadingDialogOpened] = React.useState<boolean>(true);
  const navigate = useNavigate();

  React.useEffect(() => {
    authAxios(ctx).get("/recipe").then((response) => {
      setRecipes(response.data.data as Article[]);

      closeLoadingDialog();
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 403) {
          ctx.value.access_token = "";
          ctx.value.expires = 0;
          ctx.value.email = "";

          navigate("/");
        }
      }

      setRecipes([{id: 0, title: 'エラー', image: {}}] as Article[]);

      closeLoadingDialog();
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const openLoadingDialog = () => {
    setLoadingDialogOpened(true);
  };

  const closeLoadingDialog = () => {
    setLoadingDialogOpened(false);
  };

  const m = recipes as Article[];

  const items = m.map((i) => {
    let status_icon = (
        <Button className="pink_button_color button_MEDIUM_width">
          不明
        </Button>
    );

    if (i.status === 'draft') {
      status_icon = (
        <Button size="small" className="blue_button_color button_MEDIUM_width">
          公開予定
        </Button>
      );
    } else if (i.status === 'published') {
      status_icon = (
        <Button className="Delete_Color button_MEDIUM_width">
          公開中
        </Button>
      );
    } else if (i.status === 'finished') {
      status_icon = (
        <Button size="small" className="grey_button_color button_MEDIUM_width">
          公開終了
        </Button>
      );
    } else if (i.status === 'archived') {
      status_icon = (
        <Button size="small" className="grey_button_color button_MEDIUM_width">
          アーカイブ済み
        </Button>
      );
    }

    const img_url = i.image === null ? `${process.env.PUBLIC_URL}/noimage.png` : "/api/v1/assets/" + i.image.id;
    const link_url = "/fridge/" + i.id;
    const alt_text = i.image === null ? '空の画像' : i.title + 'の画像';
    const displayRecipeTitle = (ri:Article) => {
      const name = ri.title?.slice(0,articleSettings.title.maxLength)||''
      const suffix = ri.title?.length > articleSettings.title.maxLength ? articleSettings.title.overSuffix : ''
      return `${name}${suffix}`
    }
    return (
      <Grid item>
        <Card variant="outlined" sx={{ minWidth: 300, maxWidth: 300 }}>
          <CardActionArea to={link_url} component={Link}>
            <CardMedia
              component="img"
              image={img_url}
              alt={alt_text}
            />
            <CardContent>
              <Typography sx={{ fontWeight: 'bold', fontSize: 'h6.fontSize' }}>
                {displayRecipeTitle(i)}
              </Typography>
            </CardContent>
            <CardActions>
              {status_icon}
            </CardActions>
          </CardActionArea>
        </Card>
      </Grid>
    );
  });

  return (
    <>
      <Box>
        <Box component="span" display="flex" justifyContent="space-between">
          <Link className="font-thema-color bolder" to="/fridge">
            コンテンツ管理・配信＞冷蔵コース管理
          </Link>
          <Box sx={{display:"flex","flex-direction":"column"}}>
            <Button className="Thema_Color button_LONG_width" variant="contained" component={Link} to="/fridge/+">
              コースを追加
            </Button>
            <Button className="Thema_Color button_LONG_width" variant="contained">
              コースを公開
            </Button>
            <Button className="Thema_White_Color button_LONG_width" variant="contained" sx={{ border: 1 }}>
              公開中の一覧表示へ
            </Button>
          </Box>
        </Box>
        <Divider sx={{ mb: 2 }}>
          <Chip label="コース一覧" />
        </Divider>
        <Grid container spacing={{ xs: 2 }}>
          {items}
        </Grid>
      </Box>
      <Dialog open={isLoadingDialogOpened}>
        <DialogContent>
          <Box>
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default FridgeRecipe;
