import React from 'react';
import { Link } from 'react-router-dom';
import { Box, List, ListItem, ListItemButton, ListItemText, Collapse, Toolbar, Divider } from '@mui/material';
import { ExpandLess, ExpandMore, KeyboardArrowLeft,KeyboardArrowRight, Home, AutoGraph, Dashboard, PhoneAndroid, NotificationsNone, Error, KitchenOutlined, Settings, Logout, HelpOutline, Restaurant, MenuBook, Storage, Edit, Devices, AddToHomeScreen, Keyboard, Sensors, AdminPanelSettings } from '@mui/icons-material';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import { AuthContext } from "../contexts/Auth";


type Props = {
  children?: React.ReactNode;
  onClick?: () => void;
}

const drawerWidth = 280;
const openSidebar = (theme: Theme): CSSObject => 
({
  width: drawerWidth,//開けた幅の設定
  transition: theme.transitions.create('width'),//動きの設定
  overflowX: 'hidden'//カーソルバー出ないようにする
});

const closeSidebar = (theme: Theme): CSSObject => 
({
  width: `calc(${theme.spacing(7)} + 1px)`,[theme.breakpoints.up('sm')]: {width: `calc(${theme.spacing(8)} + 1px)`},
  transition: theme.transitions.create('width'),
  overflowX: 'hidden',
  overflowY: 'hidden'
});

const DrawerHeader = styled('div')
(
  ({ theme }) => 
    ({
       display: 'flex',
       alignItems: 'center',
       padding: theme.spacing(0, 1),
       top:"60px"
    })
);

const Drawer = styled
(
  MuiDrawer, {shouldForwardProp: (prop) => prop !== 'open' }
  
)
  (
   ({ theme, open }) => 
   (
    {
      width: drawerWidth,
      whiteSpace: 'nowrap',
      boxSizing: 'border-box',
      ...(open && {...openSidebar(theme),'.MuiDrawer-paper': openSidebar(theme)}),
      ...(!open &&{...closeSidebar(theme),'.MuiDrawer-paper': closeSidebar(theme)})  
    }
  ),
);

const SideBarOpenClose: React.FC<Props> = ({ children,onClick }) => 
{ 
  const auth = React.useContext(AuthContext);
  //サイドメニュー内の表示非表示
  const [visible, setVisible] = React.useState(true);
  //サイドバーの開閉
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => 
  {
    setOpen(true);
    setVisible(true);
  };
  const handleDrawerClose = () => 
  {
    setOpen(false);
    setVisible(false);
  };
  //アコーディオンメニューの開閉
  const [detail1Opened, setDetail1Opened] = React.useState(sessionStorage.getItem('SideBarOpened_detail1Opened') ? (sessionStorage.getItem('SideBarOpened_detail1Opened') === 'true') : false);
  const [detail2Opened, setDetail2Opened] = React.useState(sessionStorage.getItem('SideBarOpened_detail2Opened') ? (sessionStorage.getItem('SideBarOpened_detail2Opened') === 'true') : false);
  const [detail3Opened, setDetail3Opened] = React.useState(sessionStorage.getItem('SideBarOpened_detail3Opened') ? (sessionStorage.getItem('SideBarOpened_detail3Opened') === 'true') : false);
  const [detail4Opened, setDetail4Opened] = React.useState(sessionStorage.getItem('SideBarOpened_detail4Opened') ? (sessionStorage.getItem('SideBarOpened_detail4Opened') === 'true') : false);
  const [detail5Opened, setDetail5Opened] = React.useState(sessionStorage.getItem('SideBarOpened_detail5Opened') ? (sessionStorage.getItem('SideBarOpened_detail5Opened') === 'true') : false);
  const [detail6Opened, setDetail6Opened] = React.useState(sessionStorage.getItem('SideBarOpened_detail6Opened') ? (sessionStorage.getItem('SideBarOpened_detail6Opened') === 'true') : false);

  const handleToggle = (e: React.MouseEvent<HTMLDivElement, Event>, id_name: string) => 
  {
    if (id_name === 'Accordion1') 
    {
      const next_state = !detail1Opened;
      setDetail1Opened(next_state);
      sessionStorage.setItem('SideBarOpened_detail1Opened', next_state ? 'true' : 'false');
    } 
    else if (id_name === 'Accordion2') 
    {
      const next_state = !detail2Opened;
      setDetail2Opened(next_state);
      sessionStorage.setItem('SideBarOpened_detail2Opened',next_state ? 'true' : 'false');
    } 
    else if (id_name === 'Accordion3') 
    {
      const next_state = !detail3Opened;
      setDetail3Opened(next_state);
      sessionStorage.setItem('SideBarOpened_detail3Opened',next_state ? 'true' : 'false');
    } 
    else if (id_name === 'Accordion4') 
    {
      const next_state = !detail4Opened;
      setDetail4Opened(next_state);
      sessionStorage.setItem('SideBarOpened_detail4Opened',next_state ? 'true' : 'false');
    }
    else if (id_name === 'Accordion5')
    {
      const next_state = !detail5Opened;
      setDetail5Opened(next_state);
      sessionStorage.setItem('SideBarOpened_detail5Opened',next_state ? 'true' : 'false');
    }
    else if (id_name === 'Accordion6')
    {
      const next_state = !detail6Opened;
      setDetail6Opened(next_state);
      sessionStorage.setItem('SideBarOpened_detail6Opened',next_state ? 'true' : 'false');
    }
  }
  const dashboard_url = auth.value.dashboard_url ? auth.value.dashboard_url : '';
  let dashboard_tags = (
    <>
    </>
  );

  let apps_tags = (
    <>
    </>
  );

  let has_apps_tags = false;
  let appinfo_tags = (
    <>
    </>
  );

  let apperr_tags = (
    <>
    </>
  );

  let contents_tags = (
    <>
    </>
  );

  let has_contents_tags = false;
  let cooker_tags = (
    <>
    </>
  );

  let fridge_tags = (
    <>
    </>
  );

  let devices_tags = (
    <>
    </>
  );

  let has_devices_tags = false;
  let device_tags = (
    <>
    </>
  );

  let firmware_tags = (
    <>
    </>
  );

  let aiot_tags = (
    <>
    </>
  );

  if (auth.value.roles?.includes("wiziotmgr_full_admin") || (
        auth.value.customer_roles?.includes("dashboard") && (
          auth.value.roles?.includes("wiziotmgr_dashboard_ro") ||
          auth.value.roles?.includes("wiziotmgr_customer_admin") ||
          auth.value.roles?.includes("wiziotmgr_contract_admin")
      )
    )
  ) {
    dashboard_tags = (
      <>
        <Divider />
        <ListItemButton onClick={(e) => handleToggle(e, 'Accordion1')} className="sidebar_button_menu">
          <AutoGraph />
          <ListItemText primary={
            <Typography className="sidebar_text_padding">データの可視化</Typography>
          } />
          { detail1Opened ? <ExpandLess /> : <ExpandMore /> }
        </ListItemButton>
        <Collapse in={detail1Opened}>
          <List component="div" disablePadding>
            <ListItemButton
              component="a"
              href={dashboard_url}
              target="_blank"
              rel="noopener noreferrer"
              sx={{ pl: 4 }}
              className="sidebar_button_menu"
            >
              <Dashboard />
              <ListItemText primary={
                <Typography className="sidebar_text_padding">ダッシュボード</Typography>
              } />
            </ListItemButton>
          </List>
        </Collapse>
      </>
    );
  }

  if (auth.value.roles?.includes("wiziotmgr_full_admin") || (
        auth.value.customer_roles?.includes("appinfo") && (
          auth.value.roles?.includes("wiziotmgr_appinfo_rw") ||
          auth.value.roles?.includes("wiziotmgr_appinfo_ro") ||
          auth.value.roles?.includes("wiziotmgr_customer_admin") ||
          auth.value.roles?.includes("wiziotmgr_contract_admin")
      )
    )
  ) {
    appinfo_tags = (
      <>
        <ListItemButton component={Link} to="/app_info" sx={{ pl: 4 }} className="sidebar_button_menu">
          <NotificationsNone />
          <ListItemText primary={
            <Typography className="sidebar_text_padding">お知らせ管理</Typography>
          } />
        </ListItemButton>
      </>
    );
    has_apps_tags = true;
  }

  if (auth.value.roles?.includes("wiziotmgr_full_admin") || (
        auth.value.customer_roles?.includes("apperr") && (
          auth.value.roles?.includes("wiziotmgr_apperr_rw") ||
          auth.value.roles?.includes("wiziotmgr_apperr_ro") ||
          auth.value.roles?.includes("wiziotmgr_customer_admin") ||
          auth.value.roles?.includes("wiziotmgr_contract_admin")
       )
     )
  ) {
    apperr_tags = (
      <>
        <ListItemButton component={Link} to="/app_err" sx={{ pl: 4 }} className="sidebar_button_menu">
          <Error />
          <ListItemText primary={
            <Typography className="sidebar_text_padding">エラー管理</Typography>
          } />
        </ListItemButton>
      </>
    );
    has_apps_tags = true;
  }

  if (has_apps_tags) {
    apps_tags = (
      <>
        <Divider />
        <ListItemButton onClick={(e) => handleToggle(e, 'Accordion2')} className="sidebar_button_menu">
          <PhoneAndroid />
          <ListItemText primary={
            <Typography className="sidebar_text_padding">アプリ管理</Typography>
          } />
          { detail2Opened ? <ExpandLess /> : <ExpandMore /> }
        </ListItemButton>
        <Collapse in={detail2Opened}>
          <List component="div" disablePadding>
            {appinfo_tags}
            {apperr_tags}
          </List>
        </Collapse>
      </>
    );
  }

  if (auth.value.roles?.includes("wiziotmgr_full_admin") ||
      auth.value.roles?.includes("wiziotmgr_cooker_rw") ||
      auth.value.roles?.includes("wiziotmgr_cooker_ro") || (
      auth.value.customer_roles?.includes("cooker") && (
        auth.value.roles?.includes("wiziotmgr_content_ro") ||
        auth.value.roles?.includes("wiziotmgr_content_rw") ||
        auth.value.roles?.includes("wiziotmgr_customer_admin") ||
        auth.value.roles?.includes("wiziotmgr_contract_admin")
      )
    )
  ) {
    cooker_tags = (
      <>
        <ListItemButton component={Link} to="/cooker" sx={{ pl: 4 }} className="sidebar_button_menu">
          <Restaurant />
          <ListItemText primary = {
            <Typography className="sidebar_text_padding">調理レシピ管理</Typography>
          } />
        </ListItemButton>
        <ListItemButton component={Link} to="/cooker_info" sx={{ pl: 4 }} className="sidebar_button_menu">
          <MenuBook />
          <ListItemText primary = {
            <Typography className="sidebar_text_padding">調理関連コンテンツ</Typography>
          } />
        </ListItemButton>
      </>
    );

    has_contents_tags = true;
  }

  if (auth.value.roles?.includes("wiziotmgr_full_admin") ||
      auth.value.roles?.includes("wiziotmgr_fridge_rw") ||
      auth.value.roles?.includes("wiziotmgr_fridge_ro") || 
      (auth.value.customer_roles?.includes("fridge") && (
        auth.value.roles?.includes("wiziotmgr_content_ro") ||
        auth.value.roles?.includes("wiziotmgr_content_rw") ||
        auth.value.roles?.includes("wiziotmgr_customer_admin") ||
        auth.value.roles?.includes("wiziotmgr_contract_admin")
      )
    )
  ) {
    fridge_tags = (
      <ListItemButton component={Link} to="/fridge" sx={{ pl: 4 }} className="sidebar_button_menu">
        <KitchenOutlined />
        <ListItemText primary = {
          <Typography className="sidebar_text_padding">冷蔵コース管理</Typography>
        } />
      </ListItemButton>
    );

    has_contents_tags = true;
  }

  if (has_contents_tags) {
    contents_tags = (
      <>
        <Divider />
        <ListItemButton onClick={(e) => handleToggle(e, 'Accordion3')} className="sidebar_button_menu">
          <Storage />
          <ListItemText primary={<Typography className="sidebar_text_padding">コンテンツ管理・配信</Typography>}/>
          { detail3Opened ? <ExpandLess /> : <ExpandMore /> }
        </ListItemButton>
        <Collapse in={detail3Opened}>
          <List component="div" disablePadding>
            {cooker_tags}
            {fridge_tags}
          </List>
        </Collapse>
      </>
    );
  }

  if (auth.value.roles?.includes("wiziotmgr_full_admin") ||
      (auth.value.customer_roles?.includes("device") && (
        auth.value.roles?.includes("wiziotmgr_device_ro") ||
        auth.value.roles?.includes("wiziotmgr_device_rw") ||
        auth.value.roles?.includes("wiziotmgr_customer_admin") ||
        auth.value.roles?.includes("wiziotmgr_contract_admin")
      )
    )
  ) {
    device_tags = (
      <ListItemButton component={Link} to="/model_name" sx={{ pl: 4 }} className="sidebar_button_menu">
        <AddToHomeScreen />
        <ListItemText primary = {
          <Typography className="sidebar_text_padding">機種名登録</Typography>
        } />
      </ListItemButton>
    );

    has_devices_tags = true;
  }

  if (auth.value.roles?.includes("wiziotmgr_full_admin") ||
      (auth.value.customer_roles?.includes("firmware") && (
        auth.value.roles?.includes("wiziotmgr_firmware_ro") ||
        auth.value.roles?.includes("wiziotmgr_firmware_rw") ||
        auth.value.roles?.includes("wiziotmgr_customer_admin") ||
        auth.value.roles?.includes("wiziotmgr_contract_admin")
      )
    )
  ) {
    firmware_tags = (
      <ListItemButton component={Link} to="/firmware" sx={{ pl: 4 }} className="sidebar_button_menu">
        <Keyboard />
        <ListItemText primary = {
          <Typography className="sidebar_text_padding">公開ファームウェア管理</Typography>
        } />
      </ListItemButton>
    );

    has_devices_tags = true;
  }


  if (has_devices_tags) {
    devices_tags = (
      <>
        <Divider />
        <ListItemButton onClick={(e) => handleToggle(e, 'Accordion5')} className="sidebar_button_menu">
          <Devices />
          <ListItemText primary={
            <Typography className="sidebar_text_padding">機器管理</Typography>
          } />
          { detail5Opened ? <ExpandLess /> : <ExpandMore /> }
        </ListItemButton>
        <Collapse in={detail5Opened}>
          <List component="div" disablePadding>
            {device_tags}
            {firmware_tags}
          </List>
        </Collapse>
      </>
    );
  }

  if (auth.value.roles?.includes("wiziotmgr_full_admin") || 
      (auth.value.customer_roles?.includes("aiot") && (
        auth.value.roles?.includes("wiziotmgr_customer_admin") ||
        auth.value.roles?.includes("wiziotmgr_contract_admin")
      )
    )
  ) {
    aiot_tags = (
      <>
        <Divider />
        <ListItemButton onClick={(e) => handleToggle(e, 'Accordion6')} className="sidebar_button_menu">
          <AdminPanelSettings />
          <ListItemText primary={
            <Typography className="sidebar_text_padding">AIoT専用メニュー</Typography>
          } />
          { detail6Opened ? <ExpandLess /> : <ExpandMore /> }
        </ListItemButton>
        <Collapse in={detail6Opened}>
          <List component="div" disablePadding>
            <ListItemButton component={Link} to="/wireless" sx={{ pl: 4 }} className="sidebar_button_menu">
              <Sensors />
              <ListItemText primary = {
                <Typography className="sidebar_text_padding">無線モジュールFW管理</Typography>
              } />
            </ListItemButton>
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <Drawer variant="permanent" open={open}>
        <Box sx={{ flexGrow: 1 ,display: 'flex'}}>
          <List sx={{width: drawerWidth }}>
            <Toolbar />
            <DrawerHeader sx={{display: 'flex',"padding":"0px"}}>
              <ListItem disablePadding>
                {(<ListItemButton className={ open ? "Chevron_open" : "Chevron_close" } onClick={(open? handleDrawerClose:handleDrawerOpen)}>
                {open?<KeyboardArrowLeft />:<KeyboardArrowRight />}
                </ListItemButton>)}
              </ListItem>
            </DrawerHeader>
            <Divider />
            <List style={{ visibility: visible ? "visible" : "hidden" }}>
              <ListItemButton component={Link} to="/home" className="sidebar_button_menu">
                <Home />
                <ListItemText primary = {<Typography className="sidebar_text_padding">ホーム</Typography>}/>
              </ListItemButton>
              { dashboard_tags }
              { apps_tags }
              { contents_tags }
              { devices_tags }
              { aiot_tags }
              <Divider />
              <ListItemButton onClick={(e) => handleToggle(e, 'Accordion4')} className="sidebar_button_menu">
                <Settings />
                <ListItemText primary = {<Typography className="sidebar_text_padding">設定</Typography>} />
                { detail4Opened ? <ExpandLess /> : <ExpandMore /> }
              </ListItemButton>
              <Collapse in={detail4Opened}>
                <List component="div" disablePadding>
                  <ListItemButton component={Link} to="/setting" sx={{ pl: 4 }} className="sidebar_button_menu">
                    <Edit />
                    <ListItemText primary = {<Typography className="sidebar_text_padding">ユーザー設定</Typography>} />
                  </ListItemButton>
                </List>
              </Collapse>
              <Divider />
              <ListItemButton component={Link} to="/inquiry" className="sidebar_button_menu">
                <HelpOutline />
                <ListItemText primary = {<Typography className="sidebar_text_padding">お問い合わせ</Typography>}/>
              </ListItemButton>
              <Divider />
              <ListItemButton component={Link} to="/logout" className="sidebar_button_menu">
                <Logout />
                <ListItemText primary={<Typography className="sidebar_text_padding">ログアウト</Typography>}/>
              </ListItemButton>
            </List>
          </List>
        </Box>
      </Drawer>
    </Box>
  );
};
export default SideBarOpenClose;
