import React from 'react';
import { authAxios } from "../../../api/axios";
import { AuthContext } from "../../../contexts/Auth";
import { Link, useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { useForm, SubmitHandler, Controller, useFieldArray } from 'react-hook-form';

import WIZIoTSettings from "../../../constants/wiziot.json";

const Thema_Color = WIZIoTSettings.style.regular.main_color;
const border_left_size = 1;
const border_right_size = 1;
const table_style = {
  borderLeft: border_left_size,
  borderRight: border_right_size
};
const columnCount = 3;//表の列数
const column_width = 100 / columnCount + "%";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Thema_Color,
    color: "white",
    borderColor: "gray",
    "text-align": "center"
  },
[`&.${tableCellClasses.body}`]: {
    borderColor: "gray"
  }
}));

type Article = {
  id: number;
  model: string;
  description: string;
}

type Props = {
  children?: React.ReactNode;
  category: string;
}

type Inputs = {
  articles: Article[];
}

type OnDoneDialogButtonClickedFuncType = {
  (): void;
};

type DoneDialogDescType = {
  isOpened: boolean;
  message: string;
  onButtonClicked: OnDoneDialogButtonClickedFuncType | undefined;
};

const EditModelName: React.FC<Props> = ({children, category}) => {
  // --- hook群
  const ctx = React.useContext(AuthContext);
  const [article, setArticle] = React.useState<Article[]>([]);
  const [doneDialogDesc, setDoneDialogDesc] = React.useState<DoneDialogDescType>({
    isOpened: false,
    message: '',
    onButtonClicked: undefined
  });
  const [isLoadingDialogOpened, setLoadingDialogOpened] = React.useState<boolean>(true);
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    getValues,
    setValue
  } = useForm<Inputs>({
    mode: 'onChange'
  });
  const { remove } = useFieldArray({
    control,
    name: 'articles'
  });

  React.useEffect(() => {
    let path = '/';

    if (category === 'cooker') {
      path = '/cooker_models';
    } else if (category === 'fridge') {
      path = '/fridge_models';
    } else if (category === 'air') {
      path = '/air_models';
    } else if (category === 'cellar') {
      path = '/cellar_models';
    } else {
      setArticle([
        {
          id: 0,
          model: 'エラー',
          description: 'エラーです'
        }] as Article[]);

      closeLoadingDialog();

      return;
    }

    authAxios(ctx).get(path).then((response) => {
      const working_article = response.data.data as Article[];

      for (let i = working_article.length; i < 5; i++) {
        working_article.push(
          {
            id: 0,
            model: '',
            description: ''
          } as Article
        );
      }

      for (let i = 0; i < working_article.length; i++) {
        setValue(`articles.${i}.id`, working_article[i].id);
        setValue(`articles.${i}.model`, working_article[i].model);
        setValue(`articles.${i}.description`, working_article[i].description);
      }

      setArticle(working_article);

      closeLoadingDialog();
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 403) {
          ctx.value.access_token = "";
          ctx.value.expires = 0;
          ctx.value.email = "";

          navigate("/");
        }
      }

      setArticle([
        {
          id: 0,
          model: 'エラー',
          description: 'エラーです'
        }] as Article[]
      );

      closeLoadingDialog();
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --- 関数群

  const onRowAddClicked = () => {
    const a = getValues('articles');

    if (Array.isArray(a)) {
      const working_article = [
        ...a,
        {
          id: 0,
          model: '',
          description: ''
        } as Article
      ];

      for (let i = 0; i < working_article.length; i++) {
        setValue(`articles.${i}.id`, working_article[i].id);
        setValue(`articles.${i}.model`, working_article[i].model);
        setValue(`articles.${i}.description`, working_article[i].description);
      }

      setArticle(working_article);
    }
  };

  const onRowRemoveClicked = (index: number) => {
    const a = getValues('articles');

    if (Array.isArray(a)) {
      const working_article = [...a];

      working_article.splice(index, 1);

      for (let i = 0; i < working_article.length; i++) {
        setValue(`articles.${i}.id`, working_article[i].id);
        setValue(`articles.${i}.model`, working_article[i].model);
        setValue(`articles.${i}.description`, working_article[i].description);
      }

      remove(working_article.length);

      setArticle(working_article);
    }
  };

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    openLoadingDialog();

    const patch_value = [] as Article[];

    for (let i = 0; i < data.articles.length; i++) {
      if (data.articles[i].model && data.articles[i].model !== '') {
        const item = {
          id: data.articles[i].id,
          model: data.articles[i].model,
          description: data.articles[i].description ? data.articles[i].description : '',
        };

        patch_value.push(item);
      }
    }

    let message = '';
    let path = '/';

    if (category === 'cooker') {
      path = '/cooker_models';
    } else if (category === 'fridge') {
      path = '/fridge_models';
    } else if (category === 'air') {
      path = '/air_models';
    } else if (category === 'cellar') {
      path = '/cellar_models';
    } else {
      message = 'エラーが発生しました';

      closeLoadingDialog();
      openDoneDialog(message, () => {closeDoneDialog(); navigate('/model_name');});

      return;
    }

    try {
        const response = await authAxios(ctx).patch(path, patch_value, {
          withCredentials: true,
        });

        if(response.status === 200)
        {
          message = '保存しました';
        } else {
          message = `保存に失敗しました(HTTP${response.status})`;
        }
    } catch (err) {
      message = 'エラーが発生しました';
    }
    closeLoadingDialog();
    openDoneDialog(message, () => {closeDoneDialog(); navigate('/model_name');});
  };

  const openDoneDialog = (message: string, func: OnDoneDialogButtonClickedFuncType) => {
    const desc = {
      isOpened: true,
      message: message,
      onButtonClicked: func
    } as DoneDialogDescType;

    setDoneDialogDesc(desc);
  };

  const closeDoneDialog = () => {
    const desc = {
      isOpened: false,
      message: doneDialogDesc.message,
      onButtonClicked: doneDialogDesc.onButtonClicked
    } as DoneDialogDescType;

    setDoneDialogDesc(desc);
  };

  const openLoadingDialog = () => {
    setLoadingDialogOpened(true);
  };

  const closeLoadingDialog = () => {
    setLoadingDialogOpened(false);
  };

  // --- 定数群

  const validationRules = {
    model: {
      maxLength: {
        value: 32,
        message: '入力可能な範囲を超えています' 
      }
    },
    description: {
      maxLength: {
        value: 32,
        message: '入力可能な範囲を超えています'
      }
    }
  };

  // --- 変数群

  const model_desc = WIZIoTSettings.categories.find((e) => e.category === category)?.description;

  // --- メイン処理

  const table_rows = [];

  const a = getValues('articles');

  if (Array.isArray(a)) {
    for (let i = 0; i < a.length; i++) {
      table_rows.push(
        <TableRow>
          <StyledTableCell sx={table_style}>
            <Controller
              name={`articles.${i}.model`}
              control={control}
              rules={validationRules.model}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="text"
                  error={fieldState.error !== undefined}
                  helperText={fieldState.error?.message}
                  fullWidth
                />
              )}
            />
          </StyledTableCell>
          <StyledTableCell sx={table_style}>
            <Controller
              name={`articles.${i}.description`}
              control={control}
              rules={validationRules.description}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  type="text"
                  error={fieldState.error !== undefined}
                  helperText={fieldState.error?.message}
                  fullWidth
                />
              )}
            />
          </StyledTableCell>
          <StyledTableCell sx={table_style}>
            <Button variant="contained" className="Thema_White_Color button_SHORT_width" sx={{ border: 1 }} onClick={() => {onRowRemoveClicked(i);}}>
              削除
            </Button>
          </StyledTableCell>
        </TableRow>
      );
    }
  } else {
    table_rows.push(
      <>
      </>
    );
  }

  return (
    <>
      <Box>
        <Box component="span" display="flex" justifyContent="space-between">
          <Box>
            <Link className="font-thema-color bolder" to="/model_name">
              機器管理＞機種名登録
            </Link>＞編集
          </Box>
          <Box>
          </Box>
        </Box>
        <Divider sx={{ mt: 2, mb: 2 }}></Divider>
        <Box sx={{display:"flex", "align-items": "center" ,marginBottom:"8px"}}>
          <Typography variant="h5" gutterBottom sx={{margin:0}}>{model_desc}</Typography>
          <Box sx={{"margin-left": "auto"}}>
            <Link  to="/model_name">
              <Button variant="contained" sx={{ border: 1 }} className="Thema_White_Color button_MEDIUM_width">キャンセル</Button>
            </Link>
            <Button variant="contained" className="Thema_Color button_MEDIUM_width" onClick={handleSubmit(onSubmit)}>
              <span className="text_justify">保存</span>
            </Button>
          </Box>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell width={column_width} sx={table_style}>機種名</StyledTableCell>
                <StyledTableCell width={column_width} sx={table_style}>説明（任意）</StyledTableCell>
                <StyledTableCell width={column_width} sx={table_style}>&nbsp;</StyledTableCell>
               </TableRow>
            </TableHead>
            <TableBody>
              {table_rows}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ mt: 2}}>
          <Button variant="contained" className="Thema_Color button_MEDIUM_width" onClick={() => {onRowAddClicked();}}>行追加</Button>
        </Box>
      </Box>
      <Dialog open={doneDialogDesc.isOpened}>
        <DialogContent>
          <Typography component="h2">
            { doneDialogDesc.message }
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className="Thema_Color" onClick={() => {if (doneDialogDesc.onButtonClicked) doneDialogDesc.onButtonClicked();}}>OK</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isLoadingDialogOpened}>
        <DialogContent>
          <Box>
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default EditModelName;
