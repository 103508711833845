import React from 'react';
import { Navigate, useLocation, useParams } from "react-router-dom";
import { Box, CssBaseline, Toolbar } from '@mui/material';

import TopMenu from '../components/TopMenu';
import SideBar from '../components/SideBarOpenClose';
import { AuthContext } from "../contexts/Auth";
import { EditRecipeContainer } from '../components/appliances/EditRecipeContainer';
import { CommonSettingType } from '../components/appliances/recipe/SettingType';
import settingList from '../constants/cooker.json';

const RouteEditCooker: React.FC = () => {
  const auth = React.useContext(AuthContext);
  const location = useLocation();
  const params = useParams()

  if (!auth.value.isAuthenticated) {
    const state = {
      'redirect' : location.pathname
    }

    return <Navigate replace to="/login" state={state} />;
  }

  if (!(
    auth.value.customer_roles?.includes("cooker") && (
      auth.value.roles?.includes("wiziotmgr_full_admin") ||
      auth.value.roles?.includes("wiziotmgr_contract_admin") ||
      auth.value.roles?.includes("wiziotmgr_customer_admin") ||
      auth.value.roles?.includes("wiziotmgr_content_po") ||
      auth.value.roles?.includes("wiziotmgr_content_ro") ||
      auth.value.roles?.includes("wiziotmgr_content_rw"))
   )) {
    return (
      <>
      </>
    );
  }

  const email = auth.value.email;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <TopMenu email={ email } />
      <SideBar />
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <EditRecipeContainer
          action='edit'
          createUrl='/cooker'
          recipeUrl={`/cooker/${params.id}`}
          returnUrl='/cooker'
          linkLabel='調理レシピ管理'
          settingList={settingList as CommonSettingType[]}
        />
      </Box>
    </Box>
  );
};

export default RouteEditCooker;
