import React from 'react';
import { Navigate } from "react-router-dom";
import { Box, Typography, CssBaseline, Toolbar } from '@mui/material';
import Grid from '@mui/material/Grid';

import TopMenu from '../components/TopMenu';
import SideBar from '../components/SideBarOpenClose';
import HomeInformation from '../components/home/HomeInformation';
import HomeVisualize from '../components/home/HomeVisualize';
import HomeApplication from '../components/home/HomeApplication';
import HomeContents from '../components/home/HomeContents';
import HomeDevices from '../components/home/HomeDevices';
import HomeAIoT from '../components/home/HomeAIoT';

import { AuthContext } from "../contexts/Auth";

const RouteHome: React.FC = () => {
  const auth = React.useContext(AuthContext);

  if (!auth.value.isAuthenticated) {
    return <Navigate replace to="/login" />;
  }

  const email = auth.value.email;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <TopMenu email={ email } />
      <SideBar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Typography sx={{ mb: 2 }}>
          WIZIoTマネージャーは、IoT機器のセンサーデータやアプリの利用データの可視化から、アプリに表示するコンテンツの管理・配信まで、IoT運用業務をトータルで支援するツールです。
        </Typography>
        <Grid container spacing={2}>
          <HomeInformation />
          <HomeVisualize />
          <HomeApplication />
          <HomeContents />
          <HomeDevices />
          <HomeAIoT />
        </Grid>
      </Box>
    </Box>
  );
};

export default RouteHome;
