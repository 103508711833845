import React from 'react';
import { useNavigate, Link } from "react-router-dom";
import { authAxios } from "../../../api/axios";
import { AuthContext } from "../../../contexts/Auth";
import Button from '@mui/material/Button'
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import AttachFile from '@mui/icons-material/AttachFile';

import TableBody from '@mui/material/TableBody';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

import WIZIoTSettings from "../../../constants/wiziot.json";

const Thema_Color = WIZIoTSettings.style.regular.main_color;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Thema_Color,
    color: "white",
    borderColor: "gray",
    "text-align": "center"
  },
  [`&.${tableCellClasses.body}`]: {
    borderColor: "gray"
  }
}));

type Article = {
  id: number;
  order: number;
  icon: string;
  link: string;
}

const CookerSpecial: React.FC = () => {
  const ctx = React.useContext(AuthContext);
  const [article, setArticle] = React.useState<Article[]>([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    authAxios(ctx).get("/cooker_special").then((response) => {
      setArticle(response.data.data as Article[]);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 403) {
          ctx.value.access_token = "";
          ctx.value.expires = 0;
          ctx.value.email = "";

          navigate("/");
        }
      }

      setArticle([
        {
          id: 0,
          order: 0,
          icon: null as any as string,
          link: ''
        }] as Article[]
      );
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cell_style = {
    borderLeft: 1,
    borderRight: 1,
    borderTop: 1,
    borderBottom: 1
  };

  const th_style = {
    borderLeft: 1,
    borderRight: 1,
    borderTop: 1,
    borderBottom: 1,
    backgroundColor: Thema_Color,
    color: "white",
    "text-align": "center"//デフォルトの左寄せをセンタリングで上書き
  };

  const table_row_1 = [];
  const table_row_2 = [];
  const table_row_3 = [];

  for (let i = 0; i < article.length; i++) {
    table_row_1.push(
      <StyledTableCell sx={th_style}>{article[i].order}</StyledTableCell>
    );
    let link = article[i].link;
    if (article[i].icon !== null && article[i].icon !== undefined) {
      const img_url = "/api/v1/assets/" + article[i].icon;

      table_row_2.push(
        <StyledTableCell sx={cell_style}>
          <div className="info_horizon">
            <div>
              <Button className="Thema_Color" variant="contained" style={{ maxWidth: '38px', maxHeight: '38px', minWidth: '38px', minHeight: '38px' }}>
                <AttachFile />
              </Button>
            </div>
            <div>
              <Link className="image" to={link} target="_blank">
                <img className="info_special_image" src={img_url} alt="画像" />
              </Link>
            </div>
          </div>
        </StyledTableCell>

      );
    } else {
      table_row_2.push(
        <StyledTableCell sx={cell_style}>&nbsp;</StyledTableCell>
      );
    }

    if (link.length > 16) {
      link = link.substring(0, 15) + ' ...';
    }

    table_row_3.push(
      <StyledTableCell sx={cell_style}>{link}</StyledTableCell>
    );
  }

  return (
    <Table sx={{ Width: 'auto', tableLayout: 'fixed' }} aria-label="simple table">
      <TableBody>
        <TableRow>
          <StyledTableCell sx={th_style}>掲載順</StyledTableCell>
          {table_row_1}
        </TableRow>
        <TableRow>
          <StyledTableCell sx={cell_style}>画像</StyledTableCell>
          {table_row_2}
        </TableRow>
        <TableRow>
          <StyledTableCell sx={cell_style}>遷移先</StyledTableCell>
          {table_row_3}
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default CookerSpecial;
