import React from 'react';
import { useNavigate, Link } from "react-router-dom";
import { authAxios } from "../../../api/axios";
import { AuthContext } from "../../../contexts/Auth";
import { HelpOutline, ModeEdit, Check, Block, Archive } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';

import WIZIoTSettings from "../../../constants/wiziot.json";

const Thema_Color = WIZIoTSettings.style.regular.main_color;

const border_left_size = 1;
const border_right_size = 1;
const table_style = {
  borderLeft: border_left_size,
  borderRight: border_right_size
};
const table_style_nowrap = {
  borderLeft: border_left_size,
  borderRight: border_right_size,
  "white-space": "nowrap"
}
const columnCount = 6; //表の列数
const column_width = 100 / columnCount + "%";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Thema_Color,
    color: "white",
    borderColor: "gray",
    "text-align": "center"
  },
[`&.${tableCellClasses.body}`]: {
    borderColor: "gray"
  }
}));

type NotificationItem = {
  id: number;
  status: string;
  title: string;
  body: string;
  phoneNumber: string;
  date_publish: string;
  date_unpublish: string;
  fireTime: string;
}

const ListNotification: React.FC = () => {
  const ctx = React.useContext(AuthContext);
  const [notifications, setNotifications] = React.useState<NotificationItem[]>([]);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = React.useState<boolean>(false);
  const [isOpenDoneModal, setIsOpenDoneModal] = React.useState<boolean>(false);
  const [isLoadingDialogOpened, setLoadingDialogOpened] = React.useState<boolean>(true);
  const [doneModalMessage, setDoneModalMessage] = React.useState<string>('');
  const [confirmingId, setConfirmingId] = React.useState<number>(-1);
  const navigate = useNavigate();

  React.useEffect(() => {
    authAxios(ctx).get("/notification").then((response) => {
      setNotifications(response.data.data as NotificationItem[]);

      closeLoadingDialog();
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 403) {
          ctx.value.access_token = "";
          ctx.value.expires = 0;
          ctx.value.email = "";

          navigate("/");
        }
      }

      setNotifications([
        {
          id: 0,
          status: 'draft',
          title: 'エラー',
          body: '記事が取得できませんでした',
          phoneNumber: '',
          date_publish: '1970-01-01T00:00:00',
          date_unpublish: '1970-01-01T00:00:00',
          fireTime: '1970-01-01T00:00:00'
        }
      ] as NotificationItem[]);

      closeLoadingDialog();
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --- function群

  const deleteNotification = async (id: number) => {
    openLoadingDialog();

    // お知らせを削除
    try {
      const response = await authAxios(ctx).delete(`/notification/${id}`,
      {
        withCredentials: true
      });

      if(response.status === 200) {
        setDoneModalMessage('削除しました');

        const items = [] as NotificationItem[];

        for (let i = 0; i < notifications.length; i++) {
          if (notifications[i].id === id) {
            continue;
          }

          items.push(notifications[i]);
        }

        setNotifications(items);

      } else {
        setDoneModalMessage(`削除に失敗しました(HTTP${response.status})`)
      }
    } catch(err) {
      setDoneModalMessage('エラーが発生しました');
    }
    closeLoadingDialog();
    openDoneModal();
  };

  const openDeleteModal = () => {
    setIsOpenDeleteModal(true);
  }

  const closeDeleteModal = () => {
    setIsOpenDeleteModal(false);
  }

  const openDoneModal = () => {
    setIsOpenDoneModal(true);
  }

  const closeDoneModal = () => {
    setIsOpenDoneModal(false);
  }

  const openLoadingDialog = () => {
    setLoadingDialogOpened(true);
  };

  const closeLoadingDialog = () => {
    setLoadingDialogOpened(false);
  };

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, id: number, type_str: string) => {
    e.preventDefault();

    if (type_str === 'show_modal') {
      setConfirmingId(id);
      openDeleteModal();
    } else if (type_str === 'cancel_modal') {
      closeDeleteModal();
    } else if (type_str === 'decide_modal') {
      closeDeleteModal();

      deleteNotification(confirmingId);
    }
  }

  // --- UI作成

  const items = [];

  for (let i = 0; i < notifications.length; i++) {
    const link_url = '/notification/' + notifications[i].id;
    let body = notifications[i].body;

    if (body === null || body === undefined) {
      body = '';
    } else {
      if (body.length > 12) {
        body = body.substring(0, 12) + '...';
      }
    }

    let status_icon = (
      <HelpOutline />
    );

    if (notifications[i].status === 'draft') {
      status_icon = (
        <ModeEdit />
      );
    } else if (notifications[i].status === 'published') {
      status_icon = (
        <Check />
      );
    } else if (notifications[i].status === 'finished') {
      status_icon = (
        <Block />
      );
    } else if (notifications[i].status === 'archived') {
      status_icon = (
        <Archive />
      );
    }

    const date_date_publish = new Date(notifications[i].date_publish);
    const date_fireTime = new Date(notifications[i].fireTime);
    const date_date_unpublish = new Date(notifications[i].date_unpublish);

    let str_date_publish = '';

    if (notifications[i].date_publish) {
      str_date_publish = date_date_publish.getFullYear() +
                         '/' +
                         (1 + date_date_publish.getMonth()) +
                         '/' +
                         date_date_publish.getDate() +
                         ' ' +
                         date_date_publish.getHours() +
                         ':';

      if (date_date_publish.getMinutes() < 10) {
        str_date_publish = str_date_publish + '0';
      }

      str_date_publish = str_date_publish + date_date_publish.getMinutes();
    }

    let str_fireTime = '';

    if (notifications[i].fireTime) {
      str_fireTime = date_fireTime.getFullYear() +
                     '/' +
                     (1 + date_fireTime.getMonth()) +
                     '/' +
                     date_fireTime.getDate() +
                     ' ' +
                     date_fireTime.getHours() +
                     ':';

      if (date_fireTime.getMinutes() < 10) {
        str_fireTime = str_fireTime + '0';
      }

      str_fireTime = str_fireTime + date_fireTime.getMinutes();
    }

    let str_date_unpublish = '';

    if (notifications[i].date_unpublish) {
      str_date_unpublish = date_date_unpublish.getFullYear() +
                           '/' +
                           (1 + date_date_unpublish.getMonth()) +
                           '/' +
                           date_date_unpublish.getDate() +
                           ' ' +
                           date_date_unpublish.getHours() +
                           ':';

      if (date_date_unpublish.getMinutes() < 10) {
        str_date_unpublish = str_date_unpublish + '0';
      }

      str_date_unpublish = str_date_unpublish + date_date_unpublish.getMinutes();
    }

    items.push(
      <TableRow>
        <StyledTableCell sx={table_style}>{status_icon}{ notifications[i].title }</StyledTableCell>
        <StyledTableCell sx={table_style}>{ body }</StyledTableCell>
        <StyledTableCell sx={table_style}>{ str_date_publish }</StyledTableCell>
        <StyledTableCell sx={table_style}>{ str_fireTime }</StyledTableCell>
        <StyledTableCell sx={table_style}>{ str_date_unpublish }</StyledTableCell>
        <StyledTableCell sx={table_style_nowrap}>
          <Button variant="contained" className="Thema_Color button_SHORT_width" component={Link} to={link_url}>
            編集
          </Button>
        </StyledTableCell>
      </TableRow>
    );
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table id="table">
          <TableHead>
            <TableRow>
              <StyledTableCell width={column_width} sx={table_style}>状態 / タイトル</StyledTableCell>
              <StyledTableCell width={column_width} sx={table_style}>本文</StyledTableCell>
              <StyledTableCell width={column_width} sx={table_style}>公開開始日時</StyledTableCell>
              <StyledTableCell width={column_width} sx={table_style}>通知日時</StyledTableCell>
              <StyledTableCell width={column_width} sx={table_style}>公開終了日時</StyledTableCell>
              <StyledTableCell width={column_width} sx={table_style}>操作</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={isOpenDeleteModal}>
        <DialogContent>
          <Typography component="h2">
            削除しますか？
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className="Thema_White_Color" sx={{ border: 1 }} onClick={(e) => {handleButtonClick(e, -1, 'cancel_modal')}}>キャンセル</Button>
          &nbsp;
          <Button variant="contained" className="Thema_Color" onClick={(e) => {handleButtonClick(e, -1, 'decide_modal')}}>OK</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isOpenDoneModal}>
        <DialogContent>
          <Typography component="h2">
            { doneModalMessage }
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className="Thema_Color" onClick={() => {closeDoneModal()}}>OK</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isLoadingDialogOpened}>
        <DialogContent>
          <Box>
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
 
export default ListNotification;
