import { TextField, Typography,MenuItem } from '@mui/material';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';


export type FormDropdownProps = {
  name:string
  values: {value:any,label:string}[]
  rules?:{}
  fullWidth?:boolean
  placeholder?:string
  label?:string
}
export const FormDropdown:React.FC<FormDropdownProps> = (props) => {
  const {control} = useFormContext()
  
  return (
    <Controller 
      control={control} 
      name={props.name}
      rules={props.rules}
      render={({field,fieldState})=>{
        return (
          <TextField
            {...field} 
            select
            label={props.label}
            fullWidth={props.fullWidth}
            placeholder={props.placeholder}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
          >
            {
              props.values?.map((v,i)=>{
                return (
                  <MenuItem key={v.value} value={v.value}>
                    <Typography>{v.label}</Typography>
                  </MenuItem>
                )
              })
            } 
          </TextField>
        )
      }}
    />
  )
}
