import axios from "axios";
import mem from 'mem'
import { AuthContextType } from "../contexts/Auth";

type RefreshResponse = {
  access_token: string,
  expires: number,
  refresh_token: string
}

const BASE_URL = '/api/v1';

export default axios.create({ baseURL: BASE_URL });

export const authAxios = (ctx: AuthContextType,uploadProgressCb:((progressEvent: any) => void) | undefined = undefined) => {
  let retry_count = 0;

  const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: { "Content-Type": "application/json" },
    withCredentials: true,
    onUploadProgress: uploadProgressCb,
  });

  axiosInstance.interceptors.request.use((config) => {
    const access_token = ctx.value.access_token;

    config.headers.authorization = `Bearer ${access_token}`;
    return config;
  });

  axiosInstance.interceptors.response.use(
    (response) => response,
    async function (error) {
      if (error?.response?.status === 401) {

        const new_token = await memoizedRefreshToken();

        ctx.value.access_token = new_token;

        ctx.setValue(ctx.value);

        retry_count++;

        if (retry_count < 2) {
            error.config.headers.authorization = `Bearer ${new_token}`;

            return axiosInstance.request(error.config);
        }
      }

      return Promise.reject(error);
    }
  );

  return axiosInstance;
};


const refreshTokenFn = async () => {
  let ret = "";

  const a = axios.create({
    baseURL: BASE_URL,
    headers: { "Content-Type": "application/json" },
    withCredentials: true
  });

  await a
    .post("/auth/refresh", {})
    .then((response) => {
      const data = response.data.data as RefreshResponse;
      ret = data.access_token;
    })
    .catch((error) => {
      return Promise.reject(error);
    })

  return ret;
}

const maxAge = 10000;
export const memoizedRefreshToken = mem(refreshTokenFn, {
  maxAge,
})
