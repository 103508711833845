import React from 'react';
import { EditRecipe, RecipeItem } from './recipe/EditRecipe';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { authAxios } from '../../api/axios';
import { AuthContext } from '../../contexts/Auth';
import { CommonSettingType, getDefaultValues } from './recipe/SettingType';
import { RecipeSubmitDialog, RecipeSubmitHandlerReturnProp, RecipeSubmitResultType } from './recipe/RecipeSubmitDialog';

type EditRecipeActionType = 'edit' | 'add'
type EditRecipeContainerProps = {
  action: EditRecipeActionType
  createUrl: string // レシピ新規作成用URL
  recipeUrl: string // レシピのURL(レシピ更新用URLを兼ねている)
  returnUrl: string // 完了、削除後に遷移するURL
  settingList: CommonSettingType[]
  linkLabel: string
}

const WrapRecipeSubmitDialog = (props:{
  open: boolean
  actionLabel: string
  method: 'post' | 'patch'
  submitUrl: string
  values: RecipeItem[]
  onClose: (result: RecipeSubmitResultType) => void
}) => {
  const {open,values,actionLabel,onClose,submitUrl,method} = props
  return(
    <RecipeSubmitDialog 
      open={open}
      url={submitUrl}
      values={values} 
      method={method} 
      label={`レシピを${actionLabel}しますか？`}
      onResponseSuccess={
        (response: any): RecipeSubmitHandlerReturnProp => {
          if(response.status === 200){
            return {
              status: 'success',
              message: `レシピの${actionLabel}に成功しました`
            }
          }
          return {
            status: 'failed',
            message: `レシピの${actionLabel}に失敗しました`
          }
        } 
      } 
      onResponseErrorCatch={
        (error: any): RecipeSubmitHandlerReturnProp => {
          const submitResultFailedPrefix = `レシピの${actionLabel}に失敗しました`
          const statusCode = error.response.status 
          const resData = error.response.data
          const resStatusIndex = Math.floor(statusCode/100)

          if(statusCode === 400) {
            if(resData?.error.message === 'seqenceNumber not unique' || resData?.error.message === 'recipeNumber not unique') {
              return {
                status: 'cancel',
                message: `${submitResultFailedPrefix}：同じ番号のレシピが既にあります`
              }
            }
          }
          if (resStatusIndex === 4) {
            return {
              status: 'failed',
              message: `${submitResultFailedPrefix}：認証エラー(HTTP${statusCode})`
            }
          }  else if (resStatusIndex === 5) {
            return {
              status: 'failed',
              message: `${submitResultFailedPrefix}：サーバーエラー（HTTP${statusCode}）`
            }
          }
          return {
            status: 'failed',
            message: `${submitResultFailedPrefix}：レスポンスエラー（HTTP${statusCode}）`
          }
        } 
      } 
      onClose={onClose}
    />
  )
}

const WrapRecipeDeleteDialog = (props:{
  open: boolean
  deleteUrl: string
  method: 'delete' | '_noAction'
  onClose: (result: RecipeSubmitResultType) => void
}) => {
  const {open,onClose,deleteUrl,method} = props

  return(
    <RecipeSubmitDialog 
      open={open} 
      url={deleteUrl} 
      values={[]} 
      method={method}
      label={'レシピを削除しますか？'}
      onResponseSuccess={
        (response: any): RecipeSubmitHandlerReturnProp => {
          if(method === '_noAction') {
            return {
              status: 'success',
              message: 'レシピを削除しました'
            }
          } else if(method === 'delete') {
            if(response.status === 200) {
              return {
                status: 'success',
                message: 'レシピを削除しました'
              }
            }
          }
          return {
            status: 'failed',
            message: 'レシピの削除に失敗しました'
          }
        } 
      } 
      onResponseErrorCatch={
        (error: any): RecipeSubmitHandlerReturnProp => {
          return {
            status: 'failed',
            message: `削除に失敗しました(HTTP${error.response.status})`
          }
        } 
      } 
      onClose={onClose}
    />
  )
}

export const EditRecipeContainer:React.FC<EditRecipeContainerProps> = (props) => {
  const {action,createUrl,linkLabel,recipeUrl,returnUrl,settingList} = props
  const param = useParams()
  const [recipeItemList, setRecipeItemList] = React.useState<RecipeItem[] | undefined>(undefined)
  const [recipeItemDefaultValue,setRecipeItemDefaultValue] = React.useState<{}>({})
  const [isRecipeLoading, setIsRecipeLoading] = React.useState(true)
  const [isSubmitOpen, setIsSubmitOpen] = React.useState(false)
  const [isDeleteOpen, setIsDeleteOpen] = React.useState(false)
  const ctx = React.useContext(AuthContext)
  const navigate = useNavigate()

  React.useEffect(() => {
    const getRecipeValues = (rlist: RecipeItem[]) => {
      const recipeValues = {}
      rlist.forEach((item,index)=>{
        const name = item.name
        const value = item.value
        Object.assign(recipeValues,{[name]:value})
      })
      return recipeValues
    }

    setIsRecipeLoading(true)
    authAxios(ctx)
    .get(recipeUrl)
    .then((response) => {
      const loadedRecipeItemList = response.data.data as RecipeItem[]
      setRecipeItemList(loadedRecipeItemList)

      //デフォルト値設定
      if(action === 'add') {
        setRecipeItemDefaultValue(getDefaultValues(ctx,settingList))
      } else if(action === 'edit') {
        setRecipeItemDefaultValue(getRecipeValues(loadedRecipeItemList))
      }

    })
    .catch((error) => {
      setRecipeItemList(undefined)
      if (error.response) {
        if (error.response.status === 403) {
          ctx.value.access_token = ""
          ctx.value.expires = 0
          ctx.value.email = ""
          navigate("/")
        }
      }
    })
    .finally(()=>{
      setIsRecipeLoading(false)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return(
    <>
      <Box>
        <Link className="font-thema-color bolder" to={returnUrl}>
          コンテンツ管理・配信＞{linkLabel}
        </Link>＞{action==='add' ? '新規作成': action==='edit' ? '編集' :''}
      </Box>
      <Box>
        {/* ロード中ならくるくる表示 */}
        {isRecipeLoading ? (
          // くるくる表示
          <Grid container alignItems={'center'} justifyItems={'center'} direction={'column'}>
            <Grid item xs={12}>
              <CircularProgress />
            </Grid>
          </Grid>
        ) : (
          // 編集画面
          recipeItemList && (
            <EditRecipe
              recipeItemList={recipeItemList}
              defaultValues={recipeItemDefaultValue}
              settingList={settingList}
              deleteRecipeItems={()=>{
                setIsDeleteOpen(true)
              }}
              submitRecipeItems={(data)=>{
                setRecipeItemList(data)
                setIsSubmitOpen(true)
              }}
              returnUrl={returnUrl}
            />
          ) || (
            <Typography>エラー（リロードしてください）</Typography>
          )
        )}
      </Box>
      <WrapRecipeSubmitDialog
        open={isSubmitOpen}
        actionLabel={action==='add' ? '作成' : '更新'}
        submitUrl={action==='add' ? createUrl : recipeUrl}
        method={action==='add' ? 'post' : 'patch'}
        values={recipeItemList || []}
        onClose={
          (result: RecipeSubmitResultType): void =>  {
            if(result === 'success') {
              navigate(returnUrl)
            } else if(result === 'failed') {
              navigate(returnUrl)
            } else if(result === 'cancel') {
              /* do nothing */
            }
            setIsSubmitOpen(false)
          }
        }
      />
      <WrapRecipeDeleteDialog
        open={isDeleteOpen}
        deleteUrl={recipeUrl}
        method={action === 'add' ? '_noAction' : 'delete'}
        onClose={
          (result: RecipeSubmitResultType): void =>  {
            if(result === 'success') {
              navigate(returnUrl)
            } else if(result === 'failed') {
              navigate(returnUrl)
            } else if(result === 'cancel') {
              /* do nothing */
            }
            setIsDeleteOpen(false)
          }
        }
      />
      &nbsp;
      <br />
      &nbsp;
      <br />
      &nbsp;
      <br />
    </>
  )
}
