import React from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import { AuthContext, AuthUserContextType } from "../contexts/Auth";
import jwt_decode from "jwt-decode";
import axios from "../api/axios";
import { authAxios } from "../api/axios";

type RefreshResponse = {
  access_token: string,
  expires: number,
  refresh_token: string
};

type ConfigResponse = {
  dashboard_url: string;
};

type LocationState = {
  redirect: string;
};

const RouteLogin: React.FC = () => {
  const auth = React.useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  const location_state = location.state as LocationState;

  if (!auth.value.isAuthenticated) {
    const refreshToken = async () => {
      try {
        const response = await axios.post("/auth/refresh", {},
        {
          withCredentials: true
        });

        const data = response.data.data as RefreshResponse;

        const decoded = jwt_decode<{ [name: string]: string }>(data.access_token);

        const auth_user = {
          email: decoded["email"],
          delegate: decoded["delegate"],
          isAuthenticated: true,
          access_token: data.access_token,
          expires: data.expires,
          roles: decoded["roles"] as any as string[],
          customer_roles: decoded["custr"] as any as string[]
        } as AuthUserContextType;

        auth.setValue(auth_user);

      } catch(err) {
        navigate("/");
      }
    };

    refreshToken();

  }

  if (auth.value.isAuthenticated) {
    const loadConfig = async () => {
      await authAxios(auth).get('/configuration').then((response) => {
        const data = response.data.data as ConfigResponse;

        const auth_user = {
          ...auth.value,
          dashboard_url: data.dashboard_url
        };

        auth.setValue(auth_user);

      }).catch((error) => {
        navigate("/");
      });
    };

    loadConfig();

    if (location_state && location_state.redirect !== '') {
        navigate(location_state.redirect);
    } else { 
        navigate("/home");
    }
  } else {
    navigate("/");
  }

  return null;
};

export default RouteLogin;
