import React from 'react';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import CookerSummary from "./CookerSummary";
import CookerSearch from "./CookerSearch";
import CookerSpecial from "./CookerSpecial";
import CookerTags from "./CookerTags";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';

const CookerInfo: React.FC = () => {
  return (
    <Box sx={{ Width: 'auto' }}>
      <Link className="font-thema-color bolder" to="#">コンテンツ管理・配信＞調理関連コンテンツ</Link>
        <Grid item>
          <Divider sx={{ mb: 2, mt:2 }}>
            <Chip label="レシピTOP画面見出し" />
          </Divider>
          <div className="info_bg">
            <div className="info_horizon">
              <div className="info_button">
                <Button variant="contained" className="Thema_Color button_MEDIUM_width">
                  <span className="text_justify">
                    保存
                  </span>
                </Button>
              </div>
            </div>
            <CookerSummary />
          </div>
          <Divider sx={{ mb: 2, mt:2 }}>
            <Chip label="検索絞り込みメニュー" />
          </Divider>
          <div className="info_bg">
            <div className="info_horizon">
              <div className="info_button">
                <Button variant="contained" className="Thema_Color button_MEDIUM_width">
                  <span className="text_justify">
                    保存
                  </span>
                </Button>
              </div>
            </div>
            <CookerSearch />
          </div>
          <Divider sx={{ mb: 2, mt:2 }}>
            <Chip label="特集画面" />
          </Divider>
          <div className="info_bg">
            <div className="info_horizon">
              <div className="info_button">
                <Button variant="contained" className="Thema_Color button_MEDIUM_width">
                  <span className="text_justify">
                    保存
                  </span>
                </Button>
              </div>
            </div>
            <CookerSpecial />
          </div>
          <Divider sx={{ mb: 2, mt:2 }}>
            <Chip label="レシピ関連情報" />
          </Divider>
          <div className="info_horizon">
            <div className="info_button">
              <Button variant="contained" className="Thema_Color button_MEDIUM_width">
                <span className="text_justify">
                  保存
                </span>
              </Button>
            </div>
          </div>
          <div className="info_models">
            <div className="info_model_tag">
              <CookerTags />
            </div>
          </div>
        </Grid>
    </Box>
  )
}

export default CookerInfo;
