import React from 'react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { useNavigate, Link, useParams } from "react-router-dom";
import { authAxios } from "../../api/axios";
import { AuthContext } from "../../contexts/Auth";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import WIZIoTSettings from "../../constants/wiziot.json";

const Thema_Color = WIZIoTSettings.style.regular.main_color;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Thema_Color,
    color: "white",
    borderColor: "gray",
    "text-align": "center"
  },
[`&.${tableCellClasses.body}`]: {
    borderColor: "gray"
  }
}));

// --- 型定義

type WIZIoTUser = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  password: string;
  temporary: boolean;
  roles: string[];
}

type Inputs = {
  email: string;
  firstName: string;
  lastName: string;
  usertype: string;
  password1: string;
  password2: string;
  temporary: string;
  dashboard: string;
  information: string;
  error: string;
  content: string;
  device: string;
  firmware: string;
};

type OnConfirmDialogClosedFuncType = {
  (id: string | undefined): void;
};

type Props = {
  children?: React.ReactNode;
  action: string;
}

// --- state以外のグローバル変数定義
let onConfirmDialogClosed = undefined as OnConfirmDialogClosedFuncType | undefined; 

// ---
const EditUser: React.FC<Props> = ({children, action}) => {
  // --- hook群
  const ctx = React.useContext(AuthContext);
  const params = useParams();
  const navigate = useNavigate();
  const [isDialogOpened, setDialogOpened] = React.useState<boolean>(false);
  const [isLoadingDialogOpened, setLoadingDialogOpened] = React.useState<boolean>(true);
  const [isConfirmDialogOpened, setConfirmDialogOpened] = React.useState<boolean>(false);
  const [doneModalMessage, setDoneModalMessage] = React.useState<string>('');
  const [confirmDialogMessage, setConfirmDialogMessage] = React.useState<string>('');
  const [userItem, setUserItem] = React.useState<WIZIoTUser>({} as WIZIoTUser);
  const {
    control,
    handleSubmit,
    getValues,
    clearErrors,
    setValue,
    formState: { errors }
  } = useForm<Inputs>({
    mode: 'onChange',
    defaultValues: { 
      email: '',
      firstName: '',
      lastName: '',
      usertype: 'nop',
      password1: '',
      password2: '',
      temporary: 'true',
      dashboard: 'nop',
      information: 'nop',
      error: 'nop',
      content: 'nop',
      device: 'nop',
      firmware: 'nop'
    }
  });

  React.useEffect(() => {
    let target_id = '0';

    if (action ==='add') {
      target_id = '0';
    } else if (action ==='edit') {
      target_id = params.id ? params.id : '0';
    } else if (action === 'self') {
      target_id = 'self';
    }

    authAxios(ctx).get("/user/" + target_id).then((response) => {
      setUserItem(response.data.data as WIZIoTUser);

      setValue('email', (response.data.data as WIZIoTUser).email);
      setValue('firstName', (response.data.data as WIZIoTUser).firstName);
      setValue('lastName', (response.data.data as WIZIoTUser).lastName);

      // dashboard
      if ((response.data.data as WIZIoTUser).roles?.includes('wiziotmgr_dashboard_ro')) {
        setValue('dashboard', 'wiziotmgr_dashboard_ro');
      } else {
        setValue('dashboard', 'nop');
      }

      // information
      if ((response.data.data as WIZIoTUser).roles?.includes('wiziotmgr_appinfo_ro')) {
        setValue('information', 'wiziotmgr_appinfo_ro');
      } else if ((response.data.data as WIZIoTUser).roles?.includes('wiziotmgr_appinfo_rw')) {
        setValue('information', 'wiziotmgr_appinfo_rw');
      }

      // error
      if ((response.data.data as WIZIoTUser).roles?.includes('wiziotmgr_apperr_ro')) {
        setValue('error', 'wiziotmgr_apperr_ro');
      } else if ((response.data.data as WIZIoTUser).roles?.includes('wiziotmgr_apperr_rw')) {
        setValue('error', 'wiziotmgr_apperr_rw');
      }

      // content
      if ((response.data.data as WIZIoTUser).roles?.includes('wiziotmgr_content_po')) {
        setValue('content', 'wiziotmgr_content_po');
      } else if ((response.data.data as WIZIoTUser).roles?.includes('wiziotmgr_content_ro')) {
        setValue('content', 'wiziotmgr_content_ro');
      } else if ((response.data.data as WIZIoTUser).roles?.includes('wiziotmgr_content_rw')) {
        setValue('content', 'wiziotmgr_content_rw');
      }

      // device
      if ((response.data.data as WIZIoTUser).roles?.includes('wiziotmgr_device_ro')) {
        setValue('device', 'wiziotmgr_device_ro');
      } else if ((response.data.data as WIZIoTUser).roles?.includes('wiziotmgr_device_rw')) {
        setValue('device', 'wiziotmgr_device_rw');
      }

      // firmware
      if ((response.data.data as WIZIoTUser).roles?.includes('wiziotmgr_firmware_ro')) {
        setValue('firmware', 'wiziotmgr_firmware_ro');
      } else if ((response.data.data as WIZIoTUser).roles?.includes('wiziotmgr_firmware_rw')) {
        setValue('firmware', 'wiziotmgr_firmware_rw');
      }

      closeLoadingDialog();

    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 403) {
          ctx.value.access_token = "";
          ctx.value.expires = 0;
          ctx.value.email = "";

          navigate("/");
        }
      }
      closeLoadingDialog();
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --- 関数群

  const getAdminTags = (roles: string[]) => {
    let admin_tags = (
      <>
        一般ユーザー
      </>
    );

    if (roles?.includes('wiziotmgr_customer_admin')) {
      admin_tags = (
        <>
          管理者
        </>
      );
    }

    if (roles?.includes('wiziotmgr_contract_admin')) {
      admin_tags = (
        <>
          契約管理者
        </>
      );
    }

    if (roles?.includes('wiziotmgr_full_admin')) {
      admin_tags = (
        <>
          AIoT管理者
        </>
      );
    }

    return admin_tags;
  };

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    openLoadingDialog();

    let user: WIZIoTUser = {
      id: params.id !== undefined ? params.id : '', 
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      password: data.password1,
      temporary: false,
      roles: []
    };

    if (data.dashboard !== 'nop') {
      user.roles.push(data.dashboard);
    }

    if (data.information !== 'nop') {
      user.roles.push(data.information);
    }

    if (data.error !== 'nop') {
      user.roles.push(data.error);
    }

    if (data.content !== 'nop') {
      user.roles.push(data.content);
    }

    if (data.device !== 'nop') {
      user.roles.push(data.device);
    }

    if (data.firmware !== 'nop') {
      user.roles.push(data.firmware);
    }

    if (action ==='add') {
      if (data.usertype !== 'nop') {
        user.roles.push(data.usertype);
      }

      if (data.temporary === 'true') {
        user.temporary = true;
      }
    }

    try {
      if (action ==='add') {
        const response = await authAxios(ctx).post('/user', user, {
          withCredentials: true,
        });

        if(response.status === 200)
        {
          setDoneModalMessage('保存しました');
        } else {
          setDoneModalMessage(`保存に失敗しました(HTTP${response.status})`);
        }
      } else if (action === 'edit') {
        const response = await authAxios(ctx).patch(`/user/${params.id}`, user, {
          withCredentials: true,
        });

        if(response.status === 200)
        {
          setDoneModalMessage('保存しました');
        } else {
          setDoneModalMessage(`保存に失敗しました(HTTP${response.status})`);
        }
      } else if (action === 'self') {
        const response = await authAxios(ctx).patch('/user/self', user, {
          withCredentials: true,
        });

        if(response.status === 200)
        {
          setDoneModalMessage('保存しました');
        } else {
          setDoneModalMessage(`保存に失敗しました(HTTP${response.status})`);
        }
      } else {
        setDoneModalMessage('エラーが発生しました');
      }

    } catch (err) {
      setDoneModalMessage('エラーが発生しました');
    }
    closeLoadingDialog();
    openDoneDialog();
  };

  const deleteThisUser = async (id: string | undefined) => {
    if (id === undefined) {
      setDoneModalMessage('エラーが発生しました');
    } else {
      openLoadingDialog();

      try {
        const response = await authAxios(ctx).delete(`/user/${params.id}`, {
          withCredentials: true,
        });

        if(response.status === 200) {
          setDoneModalMessage('削除しました');
        } else {
          setDoneModalMessage(`削除に失敗しました(HTTP${response.status})`);
        }
      } catch (err) {
        setDoneModalMessage('エラーが発生しました');
      }
      closeLoadingDialog();
    }

    openDoneDialog();
  };

  const resetThisUser = async (id: string | undefined) => {
    if (id === undefined) {
      setDoneModalMessage('エラーが発生しました');
    } else {
      openLoadingDialog();

      const data = {
        'operation': 'reset_password'
      };

      try {
        const response = await authAxios(ctx).patch(`/user/operation/${params.id}`, data, {
          withCredentials: true,
        });

        if(response.status === 200) {
          setDoneModalMessage('パスワードをリセットしました');
        } else {
          setDoneModalMessage(`パスワードのリセットに失敗しました(HTTP${response.status})`);
        }
      } catch (err) {
        setDoneModalMessage('エラーが発生しました');
      }
      closeLoadingDialog();
    }

    openDoneDialog();
  };

  const openDoneDialog = () => {
    setDialogOpened(true);
  };

  const closeDoneDialog = () => {
    setDialogOpened(false);
  };

  const openLoadingDialog = () => {
    setLoadingDialogOpened(true);
  };

  const closeLoadingDialog = () => {
    setLoadingDialogOpened(false);
  };

  const openConfirmDialog = (msg: string) => {
    setConfirmDialogMessage(msg);
    setConfirmDialogOpened(true);
  };

  const closeConfirmDialog = () => {
    setConfirmDialogOpened(false);
  };

  // --- 定義群

  const validationRules = {
    email: {
      required: 'メールアドレスを入力してください',
      minLength: { value: 4, message: 'メールアドレスが不正です' },
      maxLength: { value: 128, message: '入力可能な範囲を超えています' }
    },
    lastName: {
      maxLength: { value: 20, message: '入力可能な範囲を超えています' }
    },
    firstName: {
      maxLength: { value: 20, message: '入力可能な範囲を超えています' }
    },
    password1: {
      validate: (data: string) => {
        if (data === getValues('password2') && data.length >= 8 && data.length <= 32) {
          clearErrors('password2');
        }

        if (action === 'edit' || action === 'self') {
          if (data !== getValues('password2')) {
            return 'パスワードが一致しません';
          } else if (data.length > 0 && data.length < 8) {
            return '8文字以上で入力ください';
          } else if (data.length > 32) {
            return '入力可能な範囲を超えています';
          }
        } else if (action === 'add') {
          if (data !== getValues('password2')) {
            return 'パスワードが一致しません';
          } else if (data.length < 8) {
            return '8文字以上で入力ください';
          } else if (data.length > 32) {
            return '入力可能な範囲を超えています';
          }
        }
      }
    },
    password2: {
      validate: (data: string) => {
        if (data === getValues('password1') && data.length >= 8 && data.length <= 32) {
          clearErrors('password1');
        }

        if (action === 'edit' || action === 'self') {
          if (data !== getValues('password1')) {
            return 'パスワードが一致しません';
          } else if (data.length > 0 && data.length < 8) {
            return '8文字以上で入力ください';
          } else if (data.length > 32) {
            return '入力可能な範囲を超えています';
          }
        } else if (action === 'add') {
          if (data !== getValues('password1')) {
            return 'パスワードが一致しません';
          } else if (data.length < 8) {
            return '8文字以上で入力ください';
          } else if (data.length > 32) {
            return '入力可能な範囲を超えています';
          }
        }
      }
    }
  };

  const cell_style = {
    borderLeft: 1,
    borderRight: 1,
    borderTop: 1,
    borderBottom: 1
  };

  const th_style = {
    borderLeft: 1,
    borderRight: 1,
    borderTop: 1,
    borderBottom: 1,
    backgroundColor: WIZIoTSettings.style.regular.main_color,
    color: "white",
    "text-align": "center"//デフォルトの左寄せをセンタリングで上書き
  };

  let link_text = "編集";

  if (action === 'add') {
    link_text = "追加";
  } else if (action === 'self') {
    link_text = "自身の情報";
  }

  const is_contract_admin = userItem.roles?.includes('wiziotmgr_contract_admin') ? true : false;

  // --- タグ群

  let admin_tags = (
    <>
    </>
  );

  if (action === 'edit' || action === 'self') {
    admin_tags = getAdminTags(userItem.roles);
  } else if (action === 'add') {
    admin_tags = (
      <Controller
        name="usertype"
        control={control}
        render={({ field, fieldState: {error} }) => (
          <TextField
            {...field}
            select
            error={!!error?.message}
            fullWidth
          >
            <MenuItem value="nop">一般ユーザー</MenuItem>
            <MenuItem value="wiziotmgr_customer_admin">管理者</MenuItem>
          </TextField>
        )}
      />
    );
  }

  // --- メイン処理

  return (
    <>
      <Box>
        <Box component="span" display="flex" justifyContent="space-between">
          <Box>
            <Link className="font-thema-color bolder" to="/setting">
              設定＞ユーザー設定
            </Link>
            ＞{link_text}
          </Box>
          <Box  sx={{"display":"flex","flex-direction":"column"}}>
            <Box>
              <Link to="/setting">
                <Button variant="contained" sx={{ border: 1 }} className="Thema_White_Color button_MEDIUM_width">キャンセル</Button>
              </Link>
              <Button variant="contained" className="Thema_Color button_MEDIUM_width" onClick={handleSubmit(onSubmit)}>
                <span className='text_justify'>保存</span>
              </Button>
            </Box>
            {
              action === 'edit' && userItem.email !== ctx.value.email &&
              <Box display="flex">
              <Box sx={{ flexGrow: 1 }}></Box>
                <Button variant="contained" className="Thema_White_Color button_LONG_width" sx={{ border: 1 }}
                onClick={() => {onConfirmDialogClosed = deleteThisUser; openConfirmDialog('削除しますか？');}}>
                  このユーザーを削除
                </Button>
              </Box>
            }
            {
              action === 'edit' && !is_contract_admin &&
              <Box display="flex">
              <Box sx={{ flexGrow: 1 }}></Box>
                <Button variant="contained" className="Thema_White_Color button_LONG_width" sx={{ border: 1 }}
                onClick={() => {onConfirmDialogClosed = resetThisUser; openConfirmDialog('このユーザーのパスワードをリセットしますか？');}}>
                  パスワードをリセット
                </Button>
              </Box>
            }
          </Box>
        </Box>
        <Divider sx={{ mt: 2, mb: 2 }}>
        </Divider>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <StyledTableCell sx={th_style}>ユーザーの種類</StyledTableCell>
                <StyledTableCell sx={cell_style}>{admin_tags}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={th_style}>姓</StyledTableCell>
                <StyledTableCell sx={cell_style}>
                  <Controller
                     name="lastName"
                     control={control}
                     rules={validationRules.lastName}
                     render={({ field }) => (
                       <TextField
                         {...field}
                         type="text"
                         error={errors.lastName !== undefined}
                         helperText={errors.lastName?.message}
                         fullWidth
                       />
                     )}
                  />
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={th_style}>名</StyledTableCell>
                <StyledTableCell sx={cell_style}>
                  <Controller
                     name="firstName"
                     control={control}
                     rules={validationRules.firstName}
                     render={({ field }) => (
                       <TextField
                         {...field}
                         type="text"
                         error={errors.firstName !== undefined}
                         helperText={errors.firstName?.message}
                         fullWidth
                       />
                     )}
                  />
                </StyledTableCell>
              </TableRow>
              { action !== 'self' && !is_contract_admin &&
              <TableRow>
                <StyledTableCell sx={th_style}>メールアドレス</StyledTableCell>
                <StyledTableCell sx={cell_style}>
                  <Controller
                     name="email"
                     control={control}
                     rules={validationRules.email}
                     render={({ field }) => (
                       <TextField
                         {...field}
                         type="text"
                         error={errors.email !== undefined}
                         helperText={errors.email?.message}
                         fullWidth
                       />
                     )}
                  />
                </StyledTableCell>
              </TableRow>
              }
              { false && action === 'self' &&
              <>
                <TableRow>
                  <StyledTableCell sx={th_style}>パスワード</StyledTableCell>
                  <StyledTableCell sx={cell_style}>
                    <Controller
                       name="password1"
                       control={control}
                       rules={validationRules.password1}
                       render={({ field }) => (
                         <TextField
                           {...field}
                           type="password"
                           error={errors.password1 !== undefined}
                           helperText={errors.password1?.message}
                           fullWidth
                         />
                       )}
                    />
                  </StyledTableCell>
                </TableRow>
                <TableRow>
                  <StyledTableCell sx={th_style}>パスワード（確認）</StyledTableCell>
                  <StyledTableCell sx={cell_style}>
                    <Controller
                       name="password2"
                       control={control}
                       rules={validationRules.password2}
                       render={({ field }) => (
                         <TextField
                           {...field}
                           type="password"
                           error={errors.password2 !== undefined}
                           helperText={errors.password2?.message}
                           fullWidth
                         />
                       )}
                    />
                  </StyledTableCell>
                </TableRow>
              </>}
              { false && action === 'add' &&
              <TableRow>
                <StyledTableCell sx={th_style}>パスワードの変更</StyledTableCell>
                <StyledTableCell sx={cell_style}>
                  <Controller
                    name="temporary"
                    control={control}
                    render={({ field, fieldState: {error} }) => (
                      <TextField
                        {...field}
                        select
                        error={!!error?.message}
                        fullWidth
                      >
                        <MenuItem value="false">ユーザーによるパスワード変更不要</MenuItem>
                        <MenuItem value="true">ユーザーによるパスワード変更必要</MenuItem>
                      </TextField>
                   )}
                  />
                </StyledTableCell>
              </TableRow>
              }
              { action !== 'self' && <>
              <TableRow>
                <StyledTableCell sx={th_style}>ダッシュボード</StyledTableCell>
                <StyledTableCell sx={cell_style}>
                  <Controller
                    name="dashboard"
                    control={control}
                    render={({ field, fieldState: {error} }) => (
                      <TextField
                        {...field}
                        select
                        error={!!error?.message}
                        fullWidth
                      >
                        <MenuItem value="nop">閲覧不可</MenuItem>
                        <MenuItem value="wiziotmgr_dashboard_ro">閲覧可</MenuItem>
                      </TextField>
                   )}
                  />
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={th_style}>お知らせ管理</StyledTableCell>
                <StyledTableCell sx={cell_style}>
                  <Controller
                    name="information"
                    control={control}
                    render={({ field, fieldState: {error} }) => (
                      <TextField
                        {...field}
                        select
                        error={!!error?.message}
                        fullWidth
                      >
                        <MenuItem value="nop">閲覧不可</MenuItem>
                        <MenuItem value="wiziotmgr_appinfo_ro">閲覧可・編集不可</MenuItem>
                        <MenuItem value="wiziotmgr_appinfo_rw">閲覧・編集可</MenuItem>
                      </TextField>
                   )}
                  />
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={th_style}>エラー管理</StyledTableCell>
                <StyledTableCell sx={cell_style}>
                  <Controller
                    name="error"
                    control={control}
                    render={({ field, fieldState: {error} }) => (
                      <TextField
                        {...field}
                        select
                        error={!!error?.message}
                        fullWidth
                      >
                        <MenuItem value="nop">閲覧不可</MenuItem>
                        <MenuItem value="wiziotmgr_apperr_ro">閲覧可・編集不可</MenuItem>
                      </TextField>
                   )}
                  />
                </StyledTableCell>
              </TableRow>
              { (ctx.value.customer_roles?.includes("cooker") ||
                 ctx.value.customer_roles?.includes("fridge")) && 
              <TableRow>
                <StyledTableCell sx={th_style}>コンテンツ管理</StyledTableCell>
                <StyledTableCell sx={cell_style}>
                  <Controller
                    name="content"
                    control={control}
                    render={({ field, fieldState: {error} }) => (
                      <TextField
                        {...field}
                        select
                        error={!!error?.message}
                        fullWidth
                      >
                        <MenuItem value="nop">閲覧不可</MenuItem>
                        <MenuItem value="wiziotmgr_content_po">公開済みのみ閲覧可</MenuItem>
                        <MenuItem value="wiziotmgr_content_ro">閲覧可・編集不可</MenuItem>
                        <MenuItem value="wiziotmgr_content_rw">閲覧・編集可</MenuItem>
                      </TextField>
                   )}
                  />
                </StyledTableCell>
              </TableRow>
              }
              <TableRow>
                <StyledTableCell sx={th_style}>機種名登録</StyledTableCell>
                <StyledTableCell sx={cell_style}>
                  <Controller
                    name="device"
                    control={control}
                    render={({ field, fieldState: {error} }) => (
                      <TextField
                        {...field}
                        select
                        error={!!error?.message}
                        fullWidth
                      >
                        <MenuItem value="nop">閲覧不可</MenuItem>
                        <MenuItem value="wiziotmgr_device_ro">閲覧可・編集不可</MenuItem>
                        <MenuItem value="wiziotmgr_device_rw">閲覧・編集可</MenuItem>
                      </TextField>
                   )}
                  />
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={th_style}>ファームウェア管理</StyledTableCell>
                <StyledTableCell sx={cell_style}>
                  <Controller
                    name="firmware"
                    control={control}
                    render={({ field, fieldState: {error} }) => (
                      <TextField
                        {...field}
                        select
                        error={!!error?.message}
                        fullWidth
                      >
                        <MenuItem value="nop">閲覧不可</MenuItem>
                        <MenuItem value="wiziotmgr_firmware_ro">閲覧可・編集不可</MenuItem>
                        <MenuItem value="wiziotmgr_firmware_rw">閲覧・編集可</MenuItem>
                      </TextField>
                   )}
                  />
                </StyledTableCell>
              </TableRow>
              </> }
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Dialog open={isDialogOpened}>
        <DialogContent>
          <Typography component="h2">
            { doneModalMessage }
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className="Thema_Color" onClick={() => {closeDoneDialog(); navigate('/setting');}}>OK</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isLoadingDialogOpened}>
        <DialogContent>
          <Box>
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={isConfirmDialogOpened}>
        <DialogContent>
          <Typography component="h2">
            { confirmDialogMessage }
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className="Thema_White_Color" sx={{ border: 1 }} onClick={() => {closeConfirmDialog();}}>キャンセル</Button>
           &nbsp;
          <Button variant="contained" className="Thema_Color" onClick={() => {closeConfirmDialog(); if (onConfirmDialogClosed !== undefined) onConfirmDialogClosed(params.id); }}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default EditUser;

