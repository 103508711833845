import React from 'react';
import { useNavigate, Link, useParams } from "react-router-dom";
import { authAxios } from "../../../api/axios";
import { AuthContext } from "../../../contexts/Auth";
import Button from '@mui/material/Button'
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { styled } from '@mui/material/styles';
import dayjs, { Dayjs } from 'dayjs';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import { useForm , Controller } from "react-hook-form"

import WIZIoTSettings from "../../../constants/wiziot.json";

const Thema_Color = WIZIoTSettings.style.regular.main_color;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Thema_Color,
    color: "white",
    borderColor: "gray",
    "text-align": "center"
  },
[`&.${tableCellClasses.body}`]: {
    borderColor: "gray"
  }
}));


type NotificationItem = {
  id: number;
  status: string;
  title: string;
  body: string;
  phoneNumber: string;
  date_publish: string;
  date_unpublish: string;
  fireTime: string;
}

type Props = {
  children?: React.ReactNode;
  action: string;
}

const EditNotification: React.FC<Props> = ({children, action}) => {
  const ctx = React.useContext(AuthContext);
  const params = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = React.useState<string>('draft');
  const [title, setTitle] = React.useState<string>('');
  const [body, setBody] = React.useState<string>('');
  const [phoneNumber, setPhoneNumber] = React.useState<string>('');
  const [datePublished, setDatePublished] = React.useState<Dayjs | null>(null);
  const [dateUnpublished, setDateUnpublished] = React.useState<Dayjs | null>(null);
  const [fireTime, setFireTime] = React.useState<Dayjs | null>(null);
  const [isLoadingDialogOpened, setLoadingDialogOpened] = React.useState<boolean>(true);
  const [isOpenDoneModal, setIsOpenDoneModal] = React.useState<boolean>(false);
  const [deleteConfirmDialogOpened, setDeleteConfirmDialogOpened] = React.useState<boolean>(false);
  const [doneModalMessage, setDoneModalMessage] = React.useState<string>('');
  const [isOpenDateErrorModal,setIsOpenDateErrorModal] = React.useState<boolean>(false)
  const [dateErrorList,setDateErrorList] = React.useState<string[]>([] as string[])

  const titleLabel = 'タイトル';
  const datePublishedLabel = '公開開始日時';
  const dateUnpublishedLabel ='公開終了日時';
  const fireTimeLabel = '通知日時';

  const { 
    control, 
  } = useForm<NotificationItem>({ 
    mode: "onChange"
  })

  // validation管理 //
  const validationRules = {
    title: {
      required: "タイトルは必須です。"
    },
    body: {
      maxLength: {value: 191, message: "文字数を191文字以下にしてください。"}
    },
    phoneNumber: {
      pattern: { 
        value: /0[0-9]{1,4}-[0-9]{1,6}-[0-9]{0,5}?/, 
        message: "半角ハイフンありで正しい電話番号を入力してください。"
      }
    },
    date_publish: {
    },
    date_unpublish: {
    },
    fireTime: {
    }
  }
  // validation管理 // 

  React.useEffect(() => {
    let target_id = '0';

    if (action ==='add') {
      target_id = '0';
    } else if (action ==='edit') {
      target_id = params.id ? params.id : '0';
    }

    authAxios(ctx).get("/notification/" + target_id).then((response) => {
      setStatus(response.data['status']);
      setTitle(response.data['title']);
      setBody(response.data['body']);
      setPhoneNumber(response.data['phoneNumber']);
      setDatePublished(response.data['date_publish'] !== null ? dayjs(response.data['date_publish']) : null);
      setDateUnpublished(response.data['date_unpublish'] !== null ? dayjs(response.data['date_unpublish'].substring(0, 19)) : null);

      if (response.data['fireTime'] !== null) {
        const date_fireTime = new Date(response.data['fireTime']);

        let str_fireTime = date_fireTime.getFullYear() + '-';

        if (date_fireTime.getMonth() < 9) {
          str_fireTime = str_fireTime + '0';
        }

        str_fireTime = str_fireTime + (1 + date_fireTime.getMonth()) + '-';

        if (date_fireTime.getDate() < 10) {
          str_fireTime = str_fireTime + '0';
        }

        str_fireTime = str_fireTime + date_fireTime.getDate() + 'T';

        if (date_fireTime.getHours() < 10) {
          str_fireTime = str_fireTime + '0';
        }

        str_fireTime = str_fireTime + date_fireTime.getHours() + ':';

        if (date_fireTime.getMinutes() < 10) {
          str_fireTime = str_fireTime + '0';
        }

        str_fireTime = str_fireTime + date_fireTime.getMinutes() + ':00';

        setFireTime(dayjs(str_fireTime));
      } else {
        setFireTime(null);
      }

      closeLoadingDialog();

    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 403) {
          ctx.value.access_token = "";
          ctx.value.expires = 0;
          ctx.value.email = "";

          navigate("/");
        }
      }

      closeLoadingDialog();
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --- 関数群

  const handleChanged = (e: React.ChangeEvent<HTMLElement>) => {
    const { target } = e;

    if (target instanceof HTMLInputElement) {
    } else if (target instanceof HTMLTextAreaElement) {
    } else if (target instanceof HTMLSelectElement) {
    } else {
      return;
    }

    const value = target.value;
    const name = target.name;

    if (name === 'status') {
      setStatus(value);
    } else if(name === 'title') {
      setTitle(value);
    } else if(name === 'body') {
      setBody(value);
    } else if(name === 'phoneNumber') {
      setPhoneNumber(value);
    } else if(name === 'datePublished') {
      setDatePublished(dayjs(value));
    } else if(name === 'dateUnpublished') {
      setDateUnpublished(dayjs(value));
    } else if(name === 'fireTime') {
      setFireTime(dayjs(value));
    }
  }

  const handleSelectChange = (event: SelectChangeEvent) => {
    if (event?.target?.name === 'status') {
      setStatus(event.target.value as string);
    }
  }

  const getDateErrorList = () => {
    const errorDateList = []

    if (title !== '') {
      // OK
    } else {
      // NG
      errorDateList.push(titleLabel)
    }

    if(datePublished) {
      if (datePublished.year() >= 1000) {
        // OK
      } else {
        // NG
        errorDateList.push(datePublishedLabel)
      }
    }

    if(dateUnpublished) {
      if (dateUnpublished.year() >= 1000) {
        // OK
      } else {
        // NG
        errorDateList.push(dateUnpublishedLabel)
      }  
    }
    if(fireTime) {
      if (fireTime.year() >= 1000) {
        // OK
      } else {
        // NG
        errorDateList.push(fireTimeLabel)
      }
    }
    return errorDateList
  }

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    const errors = getDateErrorList()

    if(errors.length > 0) {
      openDateErrorModal(errors)
      return
    }

    let data: {[key: string]: any} = {};

    data['status'] = status;
    data['title'] = title;
    data['body'] = body;
    data['phoneNumber'] = phoneNumber;
    data['date_publish'] = datePublished !== null ? datePublished.format('YYYY-MM-DD HH:mm:ss') : null;
    data['date_unpublish'] = dateUnpublished !== null ? dateUnpublished.format('YYYY-MM-DD HH:mm:ss') : null;
    data['fireTime'] = fireTime !== null ? fireTime.format('YYYY-MM-DD HH:mm:ss') : null;


    submitValue(data);
  }

  const handleDeleteButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    openDeleteConfirmDialog();
  };

  const submitValue = async (data: {[key: string]: any}) => {
    openLoadingDialog();

    try {
      if (action ==='add') {
        const response = await authAxios(ctx).post('notification', data, {
          withCredentials: true,
        });

        if(response.status === 200)
        {
          setDoneModalMessage('保存しました');
        } else {
          setDoneModalMessage(`保存に失敗しました(HTTP${response.status})`);
        }
      } else if (action === 'edit') {
        const response = await authAxios(ctx).patch(`/notification/${params.id}`, data, {
          withCredentials: true,
        });

        if(response.status === 200)
        {
          setDoneModalMessage('保存しました');
        } else {
          setDoneModalMessage(`保存に失敗しました(HTTP${response.status})`);
        }
      } else {
        setDoneModalMessage('エラーが発生しました');
      }

    } catch (err) {
      setDoneModalMessage('エラーが発生しました');
    }
    closeLoadingDialog();
    openDoneModal();
  };

  const deleteNotification = async () => {
    openLoadingDialog();

    // お知らせを削除
    try {
      const response = await authAxios(ctx).delete(`/notification/${params.id}`,
      {
        withCredentials: true
      });

      if(response.status === 200) {
        setDoneModalMessage('削除しました');
      } else {
        setDoneModalMessage(`削除に失敗しました(HTTP${response.status})`)
      }
    } catch(err) {
      setDoneModalMessage('エラーが発生しました');
    }
    closeLoadingDialog();
    openDoneModal();
  };

  const openLoadingDialog = () => {
    setLoadingDialogOpened(true);
  };

  const closeLoadingDialog = () => {
    setLoadingDialogOpened(false);
  };

  const openDoneModal = () => {
    setIsOpenDoneModal(true);
  }

  const closeDoneModal = () => {
    setIsOpenDoneModal(false);
  }

  const openDeleteConfirmDialog = () => {
    setDeleteConfirmDialogOpened(true);
  }

  const closeDeleteConfirmDialog = () => {
    setDeleteConfirmDialogOpened(false);
  }

  const openDateErrorModal = (errors:string[]) => {
    setDateErrorList(errors)
    setIsOpenDateErrorModal(true);
  }
  const closeDateErrorModal = () => {
    setIsOpenDateErrorModal(false)
  }

  // ---

  const cell_style = {
    borderLeft: 1,
    borderRight: 1,
    borderTop: 1,
    borderBottom: 1
  };

  const th_style = {
    borderLeft: 1,
    borderRight: 1,
    borderTop: 1,
    borderBottom: 1,
    backgroundColor: Thema_Color,
    color: "white",
    "text-align": "center"//デフォルトの左寄せをセンタリングで上書き
  };

  return (
    <>
      <Box>
        <Box component="span" display="flex" justifyContent="space-between">
          <Box>
            <Link className="font-thema-color bolder" to="/app_info">
              アプリ管理＞お知らせ管理
            </Link>
            ＞編集
          </Box>
          <Box sx={{"display":"flex","flex-direction":"column"}}>
            <Box>
              <Link to="/app_info">
                <Button variant="contained" className="Thema_White_Color button_MEDIUM_width" sx={{ border: 1 }}>
                  キャンセル
                </Button>
              </Link>
              <Button variant="contained" className="Thema_Color button_MEDIUM_width" onClick={(e) => {handleButtonClick(e)}}>
                <span className="text_justify">
                  保存
                </span>
              </Button>
            </Box>
            <Box>
              {action === 'edit' &&
                <Button variant="contained" className="Thema_White_Color button_LONG_width" sx={{ border: 1 }} onClick={(e) => {handleDeleteButtonClick(e)}}>
                  お知らせを削除
                </Button>
              }
            </Box>
          </Box>
        </Box>
        <Divider sx={{ m: 2 }}>
        </Divider>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <StyledTableCell sx={th_style}>状態</StyledTableCell>
                <StyledTableCell sx={cell_style}>
                  <FormControl sx={{ mb: 2 }} required={true}>
                    <Select
                      labelId="id-status-label"
                      value={status}
                      name="status"
                      onChange={(e) => {handleSelectChange(e)}}
                    >
                      <MenuItem value="draft">公開前</MenuItem>
                      <MenuItem value="published">公開中</MenuItem>
                      <MenuItem value="finished">公開終了</MenuItem>
                      <MenuItem value="archived">アーカイブ済み</MenuItem>
                    </Select>
                  </FormControl>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={th_style}>タイトル</StyledTableCell>
                <StyledTableCell sx={cell_style}>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <Controller
                      name="title"
                      control={control}
                      rules={validationRules.title}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          name="title"
                          placeholder="タイトル(必須)"
                          variant="outlined"
                          value={title}
                          required={true}
                          onChange={(e) => {
                            handleChanged(e)
                            field.onChange(e)
                          }}
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </FormControl>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={th_style}>本文</StyledTableCell>
                <StyledTableCell sx={cell_style}>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <Controller
                      name="body"
                      control={control}
                      rules={validationRules.body}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          name="body"
                          placeholder="本文"
                          variant="outlined"
                          value={body}
                          onChange={(e) => {
                            handleChanged(e)
                            field.onChange(e)
                          }}
                          multiline
                          minRows={4}
                          maxRows={4}
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </FormControl>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={th_style}>電話番号</StyledTableCell>
                <StyledTableCell sx={cell_style}>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <Controller
                      name="phoneNumber"
                      control={control}
                      rules={validationRules.phoneNumber}
                      render={({ field, fieldState }) => (
                        <TextField
                          {...field}
                          name="phoneNumber"
                          placeholder="半角ハイフンありで入力"
                          type="text"
                          variant="outlined"
                          value={phoneNumber}
                          onChange={(e) => {
                            handleChanged(e)
                            field.onChange(e)
                          }}
                          error={fieldState.invalid}
                          helperText={fieldState.error?.message}
                        />
                      )}
                    />
                  </FormControl>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={th_style}>{datePublishedLabel}</StyledTableCell>
                <StyledTableCell sx={cell_style}>
                  <Controller
                    name="date_publish"
                    control={control}
                    rules={validationRules.date_publish}
                    render={() => (
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                      >
                        <DateTimePicker
                          value={dayjs(datePublished)}
                          ampm={false}
                          timeSteps={{ hours: 1, minutes: 10, seconds: 5 }}
                          onChange={(newValue: Dayjs | null) => { setDatePublished(newValue); }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={th_style}>{dateUnpublishedLabel}</StyledTableCell>
                <StyledTableCell sx={cell_style}>
                  <Controller
                    name="date_unpublish"
                    control={control}
                    rules={validationRules.date_unpublish}
                    render={() => (
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                      >
                        <DateTimePicker
                          value={dayjs(dateUnpublished)}
                          ampm={false}
                          timeSteps={{ hours: 1, minutes: 10, seconds: 5 }}
                          onChange={(newValue: Dayjs | null) => { setDateUnpublished(newValue); }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={th_style}>{fireTimeLabel}</StyledTableCell>
                <StyledTableCell sx={cell_style}>
                  <Controller
                    name="fireTime"
                    control={control}
                    rules={validationRules.fireTime}
                    render={() => (
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                      >
                        <DateTimePicker
                          value={dayjs(fireTime)}
                          ampm={false}
                          timeSteps={{ hours: 1, minutes: 10, seconds: 5 }}
                          onChange={(newValue: Dayjs | null) => { setFireTime(newValue); }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Dialog open={isLoadingDialogOpened}>
        <DialogContent>
          <Box>
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={deleteConfirmDialogOpened}>
        <DialogContent>
          <Typography component="h2">
            削除しますか？
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className="Thema_White_Color" sx={{ border: 1 }} onClick={(e) => {closeDeleteConfirmDialog();}}>キャンセル</Button>
           &nbsp;
          <Button variant="contained" className="Thema_Color" onClick={(e) => {
            closeDeleteConfirmDialog();
            deleteNotification();
          }}>削除</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isOpenDoneModal}>
        <DialogContent>
          <Typography component="h2">
            { doneModalMessage }
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className="Thema_Color" onClick={() => {closeDoneModal(); navigate('/app_info');}}>OK</Button>
        </DialogActions>
      </Dialog>
      {/* 日付エラーのモーダル */}
      <Dialog
        open={isOpenDateErrorModal}
      >
        <DialogContent>
          <Typography component="h2">
            下記の日付が不正な値です
          </Typography>
          {dateErrorList.map((v)=>{
            return <p>・{v}</p>
          })}
            &nbsp;
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className="Thema_Color" onClick={() => {closeDateErrorModal()}}>OK</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
 
export default EditNotification;
