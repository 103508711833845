import React from 'react';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
const Inquiry: React.FC = () => {
  const inquiryContents= [
    {
      'body': 'WIZIoTマネージャー及びアプリに関して、ご不明点がございましたら、下記お問い合わせ窓口までお願いします。',
      'mail': 'wiziot-support@aiotcloud.co.jp'
    }
  ];
  const mail_tags=[];
  for (let i = 0; i < inquiryContents.length; i++)
  {
    mail_tags.push(
        <Box>
          {inquiryContents[i].body}
        </Box>
    );
    if (inquiryContents[i].mail)
    {
      mail_tags.push(
        <Link to={"mailto:"+inquiryContents[i].mail as string} target="_blank" rel="noopener noreferrer">
          {inquiryContents[i].mail}
        </Link>
      );
    }
  }
  return (
    <Box>
      <Box component="span" display="flex" justifyContent="space-between">
        <Link className="font-thema-color bolder" to="/inquiry">
          お問い合わせ
        </Link>
        <Box>
        </Box>
      </Box>
      <Divider sx={{ mb: 2 }}>
      </Divider>
      <Box>
       <Box sx={{width: '100%'}}>
          <Card variant="outlined">
            <CardContent>
             {mail_tags}
            </CardContent>
          </Card>
        </Box>
      </Box>
    </Box>
  );
}
export default Inquiry;
