import React from 'react';
import { useNavigate } from "react-router-dom";
import { authAxios } from "../../../api/axios";
import { AuthContext } from "../../../contexts/Auth";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

import WIZIoTSettings from "../../../constants/wiziot.json";

const Thema_Color = WIZIoTSettings.style.regular.main_color;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Thema_Color,
    color: "white",
    borderColor: "gray",
    "text-align": "center"
  },
  [`&.${tableCellClasses.body}`]: {
    borderColor: "gray"
  }
}));

type Article = {
  id: number;
  tag: string;
}

const CookerTags: React.FC = () => {
  const ctx = React.useContext(AuthContext);
  const [article, setArticle] = React.useState<Article[]>([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    authAxios(ctx).get("/cooker_tags").then((response) => {
      setArticle(response.data.data as Article[]);

    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 403) {
          ctx.value.access_token = "";
          ctx.value.expires = 0;
          ctx.value.email = "";

          navigate("/");
        }
      }

      setArticle([
        {
          id: 0,
          tag: 'エラー'
        }] as Article[]
      );
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 const cell_style = {
    borderLeft: 1,
    borderRight: 1,
    borderTop: 1,
    borderBottom: 1
  };

  const th_style = {
    borderLeft: 1,
    borderRight: 1,
    borderTop: 1,
    borderBottom: 1,
    backgroundColor: Thema_Color,
    color: "white",
    "text-align": "center"//デフォルトの左寄せをセンタリングで上書き
  };

  const table_row_1 = [];

  for (let i = 0; i < article.length; i++) {
    table_row_1.push(
      <TableRow>
        <StyledTableCell sx={cell_style}>{article[i].tag}</StyledTableCell>
      </TableRow>
    );

  }

  return (
    <Box sx={{display:"flex", "align-items":"end"}}>
      <Box component="div" className="info_table_models">
        <Table sx={{ tableLayout: 'fixed'}} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell sx={th_style}>タグ</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {table_row_1}
          </TableBody>
        </Table>
       </Box>
       <Box component="div" sx={{width:"110px","margin-left":"8px"}}>
         <Button variant="contained" className="Thema_Color button_MEDIUM_width">行追加</Button>
       </Box>
    </Box>
   );


};

export default CookerTags;
