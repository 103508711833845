import React from 'react';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { KitchenOutlined, Restaurant, MenuBook } from '@mui/icons-material';

import { AuthContext } from "../../contexts/Auth";

const HomeContents: React.FC = () => {
  const auth = React.useContext(AuthContext);

  let contents_cnt = 0;

  let cooker_tags = (
    <>
    </>
  );

  let fridge_tags = (
    <>
    </>
  );

  let header_tags = (
    <>
    </>
  );

  let footer_tags = (
    <>
    </>
  );

  if (auth.value.roles?.includes("wiziotmgr_full_admin") ||
      auth.value.roles?.includes("wiziotmgr_cooker_rw") ||
      auth.value.roles?.includes("wiziotmgr_cooker_ro") || (
      auth.value.customer_roles?.includes("cooker") && (
        auth.value.roles?.includes("wiziotmgr_content_ro") ||
        auth.value.roles?.includes("wiziotmgr_content_rw") ||
        auth.value.roles?.includes("wiziotmgr_customer_admin") ||
        auth.value.roles?.includes("wiziotmgr_contract_admin")
      )
    )
  ) {
    cooker_tags = (
      <>
        <Grid item xs={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                <Restaurant />&nbsp;調理レシピ管理
              </Typography>
              <Typography>
                調理レシピの追加・編集・削除などを行います。
              </Typography>
            </CardContent>
            <CardActions>
              <Button className="Thema_Color" variant="contained" to="/cooker" component={Link} fullWidth>
                調理レシピ管理画面へ
              </Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                <MenuBook />&nbsp;調理関連コンテンツ
              </Typography>
              <Typography>
                調理レシピ等に関連するコンテンツの追加・編集・削除などを行います
              </Typography>
            </CardContent>
            <CardActions>
              <Button className="Thema_Color" variant="contained" to="/cooker_info" component={Link} fullWidth>
                調理関連コンテンツ画面へ
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </>
    );

    contents_cnt = contents_cnt + 2;
  }

  if (auth.value.roles?.includes("wiziotmgr_full_admin") ||
      auth.value.roles?.includes("wiziotmgr_fridge_rw") ||
      auth.value.roles?.includes("wiziotmgr_fridge_ro") ||
      (auth.value.customer_roles?.includes("fridge") && (
        auth.value.roles?.includes("wiziotmgr_content_ro") ||
        auth.value.roles?.includes("wiziotmgr_content_rw") ||
        auth.value.roles?.includes("wiziotmgr_customer_admin") ||
        auth.value.roles?.includes("wiziotmgr_contract_admin")
      )
    )
  ) {
    fridge_tags = (
      <>
        <Grid item xs={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                <KitchenOutlined />&nbsp;冷蔵コース管理
              </Typography>
              <Typography>
                冷蔵機器の温度管理のデータの追加・編集・削除などを行います。
              </Typography>
            </CardContent>
            <CardActions>
              <Button className="Thema_Color" variant="contained" to="/fridge" component={Link} fullWidth>
                冷蔵コース管理画面へ
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </>
    );

    contents_cnt = contents_cnt + 1;
  }

  if (contents_cnt > 0) {
    header_tags = (
      <Grid item xs={12}>
        <Box component="div" className="Thema_Header_Color" sx={{ p: 2 }}>
          コンテンツ管理・配信
        </Box>
      </Grid>
    );

    if (contents_cnt % 2 === 1) {
      footer_tags = (
        <Grid item xs={6}>
        </Grid>
      );
    }
  }

  return (
    <>
      { header_tags }
      { cooker_tags }
      { fridge_tags }
      { footer_tags }
    </>
  );
};

export default HomeContents;
