import { AuthContextType } from '../../../contexts/Auth';

export type CommonSettingInterfaceType =
  'input'
  | 'textarea'
  | 'radio'
  | 'checkbox'
  | 'dropdown'
  | 'image' 
  | 'file'
  | 'modalRadio' 
  | 'modalCheckbox'
  | 'cookerSequence'
  | 'cookerOperation' //cookerの「作り方」
  | 'cookerStuff'     //cookerの「材料」


export type CommonSettingInputType = 'string' | 'number'

export type CommonSettingRulesType = {
  maxLength?: {
    value: number
    message: string
  }
  minLength?: {
    value: number
    message: string
  }
  required?: string
  pattern?: {
    value: RegExp
    message: string
  }
  // ファイルアップロードで有効なルール
  accept?: {
    value: string
    match: RegExp
    message: string
  }
  maxSize?: {
    value: number
    message: string
  }
}

export type CommonSettingType = {
  name: string
  interface: CommonSettingInterfaceType
  default: any
  text?: string
  inputType?: CommonSettingInputType
  rules?: CommonSettingRulesType
  children?: CommonSettingType[]
  alt?:string
}

//ログインIDを取得する
const getLoginId = (ctx:AuthContextType) => {
  const email = ctx.value.email
  return email.split('@')?.[0].split('_')?.[1].toUpperCase() // hoge_LOGINID@example.com
}

// name置換する文字列を変換する
const getValidName = (
    name:string, 
    ctx:AuthContextType
  ):string => {
  // json_ から始まるものは置換する可能性が高い
  if(name.startsWith('json_')) {
    const loginId = getLoginId(ctx)
    return name.replace(/\[LOGINID\]/g,loginId)
  }
  return name
}

export const isEqualName = (
    s:CommonSettingType,
    name:string,
    ctx:AuthContextType
  ):boolean => {
  return getValidName(s.name,ctx) === name
}

export const getSetting = (
    name:string,
    ctx:AuthContextType,
    settings:CommonSettingType[]
):CommonSettingType|undefined => {
  return settings.filter((v) => {
    return isEqualName(v,name,ctx)
  })[0]
}

export const getDefaultValues = (
  ctx:AuthContextType|undefined,
  settings:CommonSettingType[]
) => {
  const defaults = {}
  settings.forEach((setting) => {
    const name = ctx ? getValidName(setting.name,ctx) :setting.name
    const value = setting.default
    Object.assign(defaults,{[name] : value})
  })
  return defaults
}
