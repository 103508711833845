import React from 'react';
import { useNavigate } from "react-router-dom";
import { authAxios } from "../../../api/axios";
import { AuthContext } from "../../../contexts/Auth";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { DialogActions, FormHelperText, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import WIZIoTSettings from "../../../constants/wiziot.json";

const Thema_Color = WIZIoTSettings.style.regular.main_color;

type EditFirmwareActionType = 'edit' | 'add';
type EditFirmwareProps = {
  action: EditFirmwareActionType;
  linkLabel: string;
  category: any;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Thema_Color,
    color: "white",
    borderColor: "gray",
    "text-align": "center"
  },
[`&.${tableCellClasses.body}`]: {
    borderColor: "gray"
  }
}));

const cell_style = {
  borderLeft: 1,
  borderRight: 1,
  borderTop: 1,
  borderBottom: 1
};

const th_style = {
  borderLeft: 1,
  borderRight: 1,
  borderTop: 1,
  borderBottom: 1,
  backgroundColor: Thema_Color,
  color: "white",
  "text-align": "center"//デフォルトの左寄せをセンタリング
};

type Article = {
  model: string;
  name: string;
  version: string;
  firmware1: string;
  firmware2: string;
  description: string;
};

type WirelessArticle = {
  model: string;
  name: string;
  subtype: string;
  version: string;
};

export const EditFirmware: React.FC<EditFirmwareProps> = (props) => {
  const navigate = useNavigate();
  const [article, setArticle] = React.useState<Article[]>([]);
  const [WirelessArticle, setWirelessArticle] = React.useState<WirelessArticle[]>([]);
  const {action,linkLabel,category} = props;
  const ctx = React.useContext(AuthContext);
  const [isLoadingDialogOpened, setLoadingDialogOpened] = React.useState<boolean>(false);
  const {
    control,
  } = useForm<Article>({
    mode: 'onChange'
  });
  const [isOpenDoneModal, setIsOpenDoneModal] = React.useState<boolean>(false);
  const [isOpenErrorDoneModal, setIsOpenErrorDoneModal] = React.useState<boolean>(false);
  const [SelectErrorList,setSelectErrorList] = React.useState<string[]>([] as string[]);
  const [isOpenSelectErrorModal,setIsOpenSelectErrorModal] = React.useState<boolean>(false);
  const [doneModalMessage, setDoneModalMessage] = React.useState<string>('');
  const [isOpenConfirmModal, setIsOpenConfirmModal] = React.useState<boolean>(false);
  const [model, setModel] = React.useState<string>('');
  const [firmware1, setFirmware1] = React.useState<string>('');
  const [firmware2, setFirmware2] = React.useState<string>('');
  const [description, setDescription] = React.useState<string>('');

  React.useEffect(() => {
    openLoadingDialog();
    authAxios(ctx).get('/mainfw/' + category).then((response) => {

      setArticle(response.data.data as Article[]);

      closeLoadingDialog();
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 403) {
          ctx.value.access_token = "";
          ctx.value.expires = 0;
          ctx.value.email = "";

          navigate("/");
        }
      }

      closeLoadingDialog();
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  React.useEffect(() => {
    openLoadingDialog();
    authAxios(ctx).get('/wirelessfw').then((response) => {

      setWirelessArticle(response.data.data as WirelessArticle[]);

      closeLoadingDialog();
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 403) {
          ctx.value.access_token = "";
          ctx.value.expires = 0;
          ctx.value.email = "";

          navigate("/");
        }
      }

      closeLoadingDialog();
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);


  // --- 関数群

  const getSelectErrorList = () => {
    const errorSelectList = [];

    if (model !== '') {
      // OK
    } else {
      // NG
      errorSelectList.push('機種名')
    }

    if (firmware1 !== '') {
      // OK
    } else {
      // NG
      errorSelectList.push('本体FW')
    }
    
    if (firmware2 !== '') {
      // OK
    } else {
      // NG
      errorSelectList.push('無線モジュールFW')
    }
    return errorSelectList
  };

  const openLoadingDialog = () => {
    setLoadingDialogOpened(true);
  };

  const closeLoadingDialog = () => {
    setLoadingDialogOpened(false);
  };

  const openSelectErrorModal = (errors:string[]) => {
    setSelectErrorList(errors);
    setIsOpenSelectErrorModal(true);
  };

  const closeSelectErrorModal = () => {
    setIsOpenSelectErrorModal(false);
    setIsOpenConfirmModal(false);
  };

  const openDoneModal = () => {
    setIsOpenDoneModal(true);
  };

  const closeAddPage = () => {
    setIsOpenDoneModal(false);
    setIsOpenConfirmModal(false);
  };

  const openErrorDoneModal = () => {
    setIsOpenErrorDoneModal(true);
  };

  const closeAllOpenedModal = () => {
    setIsOpenErrorDoneModal(false);
    setIsOpenConfirmModal(false);
  };

  const openConfirmModal = () => {
    setIsOpenConfirmModal(true);
  };

  const closeConfirmModal = () => {
    setIsOpenConfirmModal(false);
  };

  const handleConfirmButtonClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, type_str: string) => {
    e.preventDefault();

    if (type_str === 'show_modal') {
      if (description.length > 32){
        setDoneModalMessage('備考の文字数が上限を超えています');
        openErrorDoneModal();
      } else {
        openConfirmModal();
      }
    } else if (type_str === 'cancel_modal') {
      closeConfirmModal();
    } else if (type_str === 'open_submit_modal') {
      closeConfirmModal();

      e.preventDefault();

      const errors = getSelectErrorList();

      if(errors.length > 0) {
        openSelectErrorModal(errors)
        return
      }
  
      let data: {[key: string]: any} = {};
  
      data['model'] = model;
      data['firmware1'] = firmware1.replace(model + '_', '');
      data['firmware2'] = firmware2;
      data['description'] = description;

      openLoadingDialog();
      submitValue(data);
    }
  };

  const handleButtonClickError = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, type_str: string) => {
    e.preventDefault();

    if (type_str === 'close_error_modal') {
      closeSelectErrorModal();
    }
  };

  const submitValue = async (data: {[key: string]: any}) => {
    if (action === 'add') {
      authAxios(ctx).post('/firmware/' + category, data, {withCredentials: true,}).then((response) => {
        if(response.status === 200 && description.length <= 32){
          setDoneModalMessage('保存しました');
          closeLoadingDialog();
          openDoneModal();
        }
      }).catch((error) => {
        if (error.response.data.error['message'] === 'already exists') {
          setDoneModalMessage('既に同じ組み合わせが存在します。\n組み合わせを変更してください。');
        }
        closeLoadingDialog();
        openErrorDoneModal();
      })
    }
    if (action !=='add') {
      setDoneModalMessage('ボタン動作にエラーが発生しました');
      closeLoadingDialog();
      openErrorDoneModal();
    }
  };


  // --- 変数群

  const model_desc = WIZIoTSettings.categories.find((e) => e.category === props.category)?.description;


  // --- 定数群

  const validationRules = {
    description: {
      maxLength: {
        value: 32,
        message: '入力可能な範囲を超えています'
      }
    }
  };


  // --- メイン処理

  const table_rows_model = [];
  const table_rows_firmware1 = [];
  const table_rows_firmware2 = [];
  const table_rows_description = [];

  let data_model: string;
  let data_firmware1: string;
  let data_firmware2: string;

  let arr_data_model = [];
  let arr_data_firmware1 = [];
  let arr_data_firmware2 = [];

  const handleChangeModel = (event: SelectChangeEvent) => {
    setModel(event.target.value);
  };

  const handleChangeFirmware1 = (event: SelectChangeEvent) => {
    setFirmware1(event.target.value);
  };

  const handleChangeFirmware2 = (event: SelectChangeEvent) => {
    setFirmware2(event.target.value);
  };

  const handleChangeDescription = (e: React.ChangeEvent<HTMLElement>) => {
    const { target } = e;

    if (target instanceof HTMLInputElement) {
    } else if (target instanceof HTMLTextAreaElement) {
    } else if (target instanceof HTMLSelectElement) {
    } else {
      return;
    }

    const value = target.value;

    setDescription(value);

  };


  // --- 機種名、本体FW、無線モジュールFWのデータを配列化

  for (let i = 0; i < article.length; i++) {
    data_model = article[i].model;
    data_firmware1 = article[i].name;

    arr_data_model.push(data_model);
    arr_data_firmware1.push(data_firmware1);
  }
  for (let i = 0; i < WirelessArticle.length; i++) {
    data_firmware2 = WirelessArticle[i].version;

    arr_data_firmware2.push(data_firmware2);
  }
  // ---


  // --- 各配列をフィルターする

  const filtered_arr_data_model = arr_data_model.filter(
    (val_model, i_model, self_model) => { return i_model === self_model.indexOf(val_model);
  });

  function removeSubstring(inputArray: string[], substring: string): string[] {
    return inputArray.map((element) => element.replace(substring, ''));
  }
  const tmp_arr_data_firmware1: string[] = removeSubstring(arr_data_firmware1,".pkg");
  const filtered_arr_data_firmware1 = tmp_arr_data_firmware1.filter((data_firmware1) => { return data_firmware1.includes(model)});

  const filtered_arr_data_firmware2 = arr_data_firmware2.filter(
    (val_firmware2, i_firmware2, self_firmware2) => { return i_firmware2 === self_firmware2.indexOf(val_firmware2);
  });
  // ---


  // --- フィルターした配列をアスキーコード順にソート

  const sorted_filtered_arr_data_model = filtered_arr_data_model.sort();
  const sorted_filtered_arr_data_firmware1 = filtered_arr_data_firmware1.sort();
  const sorted_filtered_arr_data_firmware2 = filtered_arr_data_firmware2.sort();
  // ---


  // --- UI実装

  for (let i = 0; i < sorted_filtered_arr_data_model.length; i++) {
    table_rows_model.push(
      <MenuItem value={sorted_filtered_arr_data_model[i]}>{ sorted_filtered_arr_data_model[i] }</MenuItem>
    );
  };

  for (let i = 0; i < sorted_filtered_arr_data_firmware1.length; i++) {
    if(model !== ''){
      table_rows_firmware1.push(
        <MenuItem value = {sorted_filtered_arr_data_firmware1[i]}>{ sorted_filtered_arr_data_firmware1[i] }</MenuItem>
      );
    } else if(model === ''){table_rows_firmware1.push();}
  };
  const ErrorText = (model !== '') ? '' : <FormHelperText>先に機種名を選んでください</FormHelperText>;

  for (let i = 0; i < sorted_filtered_arr_data_firmware2.length; i++) {
    table_rows_firmware2.push(
      <MenuItem value = {sorted_filtered_arr_data_firmware2[i]}>{ sorted_filtered_arr_data_firmware2[i] }</MenuItem>
    );
  };

  table_rows_description.push(
    <StyledTableCell sx={cell_style}>
      <Controller
        name="description"
        control={control}
        rules={validationRules.description}
        render={({ field, fieldState }) => (
          <TextField
            {...field}
            name="description"
            label="Description"
            placeholder="備考欄に記載する内容を記入"
            variant="outlined"
            value={description}
            onChange={(e) => {
              handleChangeDescription(e);
              field.onChange(e);
            }}
            multiline
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            fullWidth
          />
        )}
      />
    </StyledTableCell>
  );
  // ---

  return (
    <>
      <Box>
        <Box component="span" display="flex" justifyContent="space-between">
          <Box>
            <Link className="font-thema-color bolder" to="/firmware">
              機器管理＞{linkLabel}
            </Link>＞公開情報の追加
          </Box>
          <Box sx={{"display":"flex","flex-direction":"column"}}>
            <Box>
              <Button variant="contained" sx={{ border: 1 }} className="Thema_White_Color button_MEDIUM_width" component={Link} to="/firmware">
                キャンセル
              </Button>
              <Button variant="contained" className="Thema_Color button_MEDIUM_width" onClick={(e) => {handleConfirmButtonClick(e, 'show_modal')}}>
                <span className="text_justify">保存</span>
              </Button>
            </Box>
          </Box>
        </Box>
        <Divider sx={{ mt: 2, mb: 2 }}>
        </Divider>
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              <TableRow>
                <StyledTableCell sx={th_style}>カテゴリー</StyledTableCell>
                <StyledTableCell sx={cell_style}>{model_desc}</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={th_style}>機種名</StyledTableCell>
                <StyledTableCell sx={cell_style}>
                  <Select
                    labelId="model-label"
                    id="model"
                    value={model}
                    onChange={
                      handleChangeModel
                    }
                    fullWidth
                  >
                    { table_rows_model }
                  </Select>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={th_style}>本体FW</StyledTableCell>
                <StyledTableCell  sx={cell_style}>
                  <Select
                    labelId="firmware1-label"
                    id="firmware1"
                    value={firmware1}
                    onChange={handleChangeFirmware1}
                    fullWidth
                  >
                    { table_rows_firmware1 }
                  </Select>
                    {ErrorText}
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={th_style}>無線モジュールFW</StyledTableCell>
                <StyledTableCell sx={cell_style}>
                  <Select
                    labelId="firmware2-label"
                    id="firmware2"
                    value={firmware2}
                    onChange={handleChangeFirmware2}
                    fullWidth
                  >
                    {table_rows_firmware2}
                  </Select>
                </StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={th_style}>備考</StyledTableCell>
                {table_rows_description}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Dialog open={isOpenConfirmModal}>
        <DialogContent>
          <Typography component="h2">
            公開用のFWの組み合わせを登録します
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className="Thema_White_Color" sx={{ border: 1 }}  onClick={(e) => {handleConfirmButtonClick(e, 'cancel_modal')}}>
            キャンセル
          </Button>
          &nbsp;
          <Button variant="contained" className="Thema_Color" onClick={(e) => {handleConfirmButtonClick(e, 'open_submit_modal')}}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isOpenSelectErrorModal}>
        <DialogContent>
          <Typography component="h2">
              下記の値が未選択です
          </Typography>
          {SelectErrorList.map((v)=>{
            return <p>・{v}</p>
          })}
            &nbsp;
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className="Thema_Color" onClick={(e) => {handleButtonClickError(e, 'close_error_modal')}}>
            閉じる
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isOpenDoneModal}>
        <DialogContent>
          <Typography component="h2">
            {doneModalMessage}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className="Thema_Color" onClick={() => {closeAddPage()}} component={Link} to="/firmware">
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isOpenErrorDoneModal}>
        <DialogContent>
          <Typography component="h2">
            {doneModalMessage.split('\n').map(t => (<div>{t}</div>))}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className="Thema_Color" onClick={() => {closeAllOpenedModal()}}>
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isLoadingDialogOpened}>
        <DialogContent>
          <Box>
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
 
export default EditFirmware;
