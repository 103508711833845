import React from "react";
import { AppBar, Toolbar, Typography } from '@mui/material';
import { AccountCircle } from '@mui/icons-material';

import WIZIoTSettings from "../constants/wiziot.json";

type Props = {
  children?: React.ReactNode,
  email?: string
};

const TopMenu: React.FC<Props> = ({children, email}) => {
  const style: { [key: string]: string } = {
    "background-color": WIZIoTSettings.style.regular.main_color,
  };

  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar sx={style}>
        <Typography>
          WIZIoTマネージャー
        </Typography>
        <div className="mail_right">
          <AccountCircle />
          { email }
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default TopMenu;
