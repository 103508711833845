import React from 'react';
import { Link } from "react-router-dom";
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';

import ListNotification from "./ListNotification";

const AppInfo: React.FC = () => {
  return (
    <Box>
      <Box component="span" display="flex" justifyContent="space-between">
        <Link className="font-thema-color bolder" to="/app_info">
          アプリ管理＞お知らせ管理
        </Link>
        <Button variant="contained" className="Thema_Color button_LONG_width" component={Link} to="/notification/+">お知らせを追加</Button>
      </Box>
      <Divider sx={{ mb: 2 }}>
        <Chip label="お知らせ一覧" />
      </Divider>
      <ListNotification />
    </Box>
  );
}
 
export default AppInfo;
