import React from 'react';
import { useNavigate } from "react-router-dom";
import { authAxios } from "../../../api/axios";
import { AuthContext } from "../../../contexts/Auth";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import WIZIoTSettings from "../../../constants/wiziot.json";

const Thema_Color = WIZIoTSettings.style.regular.main_color;

const border_left_size = 1;
const border_right_size = 1;
const table_style = {
  borderLeft: border_left_size,
  borderRight: border_right_size
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Thema_Color,
    color: "white",
    borderColor: "gray",
    "text-align": "center"
  },
[`&.${tableCellClasses.body}`]: {
    borderColor: "gray"
  }
}));

type Article = {
  model: string;
  statusCode: string;
  content: string;
  kind: 'error' | 'info';
  notify: boolean;
  repeatable: boolean;
  shortMsg: string;
  open: string;
  title: string;
  longMsg: string;
  url: string;
  part: number;
};

const ListDeviceError: React.FC = () => {
  const ctx = React.useContext(AuthContext);
  const navigate = useNavigate();
  const [article, setArticle] = React.useState<Article[]>([]);
  const [isLoadingDialogOpened, setLoadingDialogOpened] = React.useState<boolean>(true);

  React.useEffect(() => {
    authAxios(ctx).get("/device_errors").then((response) => {
      if (Array.isArray(response.data.data)) {
          setArticle(response.data.data);
      }

      closeLoadingDialog();
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 403) {
          ctx.value.access_token = "";
          ctx.value.expires = 0;
          ctx.value.email = "";

          navigate("/");
        }
      }
      closeLoadingDialog();
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // --- 関数群

  const openLoadingDialog = () => {
    setLoadingDialogOpened(true);
  };

  const closeLoadingDialog = () => {
    setLoadingDialogOpened(false);
  };

  // --- 変数群

  const table_rows = [];

  const sorted_article = [...article].sort((a, b) => {
    if (a.model > b.model) {
      return 1;
    } else if (a.model < b.model) {
      return -1;
    } else {
      if (a.kind > b.kind) {
        return 1;
      } else if (a.kind < b.kind) {
        return -1;
      } else {
        if (a.statusCode > b.statusCode) {
          return 1;
        } else {
          return -1;
        }
      }
    }
  });

  for (let i = 0; i < sorted_article.length; i++) {
    let kind_str = '';

    if (sorted_article[i].kind === 'error') {
      kind_str = 'エラー';
    } else if (sorted_article[i].kind === 'info') {
      kind_str = 'お知らせ';
    }

    const notifiable_str = sorted_article[i].notify ? '〇' : '';
    const repeatable_str = sorted_article[i].repeatable ? '〇' : '';

    let open_str = '';

    if (sorted_article[i].open === '/device_list') {
        open_str = '機器リスト';
    } else if (sorted_article[i].open === '/device_detail') {
        if (sorted_article[i].part === 0) {
            open_str = '機器タブ';
        } else if (sorted_article[i].part === 1) {
            open_str = '機器タブ1';
        } else if (sorted_article[i].part === 2) {
            open_str = '機器タブ2';
        }
    } else if (sorted_article[i].open === '/graph') {
        open_str = 'グラフタブ';
    } else if (sorted_article[i].open === '/history') {
        open_str = '履歴タブ';
    }

    let url_tag = (
      <Box>
        {sorted_article[i].url}
      </Box>
    );

    if (sorted_article[i].url.indexOf('http') === 0) {
      url_tag = (
        <a href={sorted_article[i].url} target="_blank" rel="noopener noreferrer" style={{wordBreak: "break-all"}}>
          {sorted_article[i].url}
        </a>
      );
    }

    table_rows.push(
      <TableRow>
        <StyledTableCell sx={table_style}>{sorted_article[i].model}</StyledTableCell>
        <StyledTableCell sx={table_style}>{sorted_article[i].statusCode}</StyledTableCell>
        <StyledTableCell sx={table_style}>{sorted_article[i].content}</StyledTableCell>
        <StyledTableCell sx={table_style}>{kind_str}</StyledTableCell>
        <StyledTableCell sx={table_style}>{notifiable_str}</StyledTableCell>
        <StyledTableCell sx={table_style}>{repeatable_str}</StyledTableCell>
        <StyledTableCell sx={table_style}>{sorted_article[i].shortMsg}</StyledTableCell>
        <StyledTableCell sx={table_style}>{open_str}</StyledTableCell>
        <StyledTableCell sx={table_style}>{sorted_article[i].title}</StyledTableCell>
        <StyledTableCell sx={table_style}>{sorted_article[i].longMsg}</StyledTableCell>
        <StyledTableCell sx={table_style}>{url_tag}</StyledTableCell>
      </TableRow>
    );

  }

  // --- メイン処理

  return (
    <>
      <Box>
        <Box component="span" display="flex">
          <Link className="font-thema-color bolder" to="/app_err">
            アプリ管理＞エラー管理
          </Link>
        </Box>
        <Divider sx={{ mt: 2, mb: 2 }}></Divider>
        <Box display="flex" justifyContent="flex-end" sx={{mb: 2}}>
          <Box className="font-thema-color">
            ※エラー本文内で改行する場合は、文章の先頭に「\n」の文字が入ります。<br/>
            　本文からタップしてリンク先に遷移するテキストは、「{'{{'}」、「{'}}'}」の文字で囲っています。
          </Box>
        </Box>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell sx={table_style} rowSpan={2}>機種名</StyledTableCell>
                <StyledTableCell sx={table_style} rowSpan={2}>ステータスコード</StyledTableCell>
                <StyledTableCell sx={table_style} rowSpan={2}>内容</StyledTableCell>
                <StyledTableCell sx={table_style} rowSpan={2}>種別</StyledTableCell>
                <StyledTableCell sx={table_style} colSpan={4}>アプリの通知</StyledTableCell>
                <StyledTableCell sx={table_style} colSpan={3}>アプリのエラー説明</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell sx={table_style}>通知</StyledTableCell>
                <StyledTableCell sx={table_style}>繰り返し</StyledTableCell>
                <StyledTableCell sx={table_style}>通知テキスト</StyledTableCell>
                <StyledTableCell sx={table_style}>通知遷移先</StyledTableCell>
                <StyledTableCell sx={table_style}>エラータイトル</StyledTableCell>
                <StyledTableCell sx={table_style}>エラー本文※</StyledTableCell>
                <StyledTableCell sx={table_style}>エラー遷移先（任意）</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {table_rows}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Dialog open={isLoadingDialogOpened}>
        <DialogContent>
          <Box>
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ListDeviceError;
