import React from 'react';
import { useNavigate } from "react-router-dom";
import { authAxios } from "../../../api/axios";
import { AuthContext } from "../../../contexts/Auth";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Input from '@mui/material/Input';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import dayjs from 'dayjs';

import WIZIoTSettings from "../../../constants/wiziot.json";

const Thema_Color = WIZIoTSettings.style.regular.main_color;
const border_left_size = 1;
const border_right_size = 1;
const table_style = {
  borderLeft: border_left_size,
  borderRight: border_right_size
};
const columnCount = 4;//表の列数
const column_width = 100 / columnCount + "%";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Thema_Color,
    color: "white",
    borderColor: "gray",
    "text-align": "center"
  },
[`&.${tableCellClasses.body}`]: {
    borderColor: "gray"
  }
}));

// --- 型定義

type Article = {
  name: string;
  model: string;
  version: string;
  modified_on: string;
  digest: string;
};

type Model = {
  id: number;
  model: string;
  description: string;
};

type Inputs = {
  model: string;
  file: File;
  version1: string;
  version2: string;
  version3: string;
};

type Option = {
  value: string;
  label: string;
};

type Props = {
  children?: React.ReactNode;
  category: 'air' | 'cooker' | 'fridge' | 'cellar';
};

// --- state以外の永続変数


const ListMainFW: React.FC<Props> = (props) => {
  const ctx = React.useContext(AuthContext);
  const navigate = useNavigate();
  const [articles, setArticles] = React.useState<Article[]>([]);
  const [models, setModels] = React.useState<Model[]>([]);
  const [file, setFile] = React.useState<File | null>(null);
  const [fileName, setFileName] = React.useState<string>('選択されていません');
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [doneDialogMessage, setDoneDialogMessage] = React.useState<string>('');
  const [isLoadingDialogOpened, setLoadingDialogOpened] = React.useState<boolean>(true);
  const [isDoneDialogOpened, setDoneDialogOpened] = React.useState<boolean>(false);
  const [isUploadDialogOpened, setUploadDialogOpened] = React.useState<boolean>(false);
  const [reloadCounter, setReloadCounter] = React.useState<number>(0);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const {
    control,
    handleSubmit,
    reset
  } = useForm<Inputs>({
    mode: 'onChange'
  });

  React.useEffect(() => {
    authAxios(ctx).get("/mainfw/" + props.category).then((response) => {
      setArticles(response.data.data);

      closeLoadingDialog();
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 403) {
          ctx.value.access_token = "";
          ctx.value.expires = 0;
          ctx.value.email = "";

          navigate("/");
        }
      }
      closeLoadingDialog();
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadCounter]);

  React.useEffect(() => {
    let path = '/';

    if (props.category === 'cooker') {
      path = '/cooker_models';
    } else if (props.category === 'fridge') {
      path = '/fridge_models';
    } else if (props.category === 'air') {
      path = '/air_models';
    } else if (props.category === 'cellar') {
      path = '/cellar_models';
    }

    authAxios(ctx).get(path).then((response) => {
      setModels(response.data.data);

    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 403) {
          ctx.value.access_token = "";
          ctx.value.expires = 0;
          ctx.value.email = "";

          navigate("/");
        }
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --- 関数群

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files && files[0]) {
      setFile(files[0]);
      setFileName(files[0].name);
    }
  };

  const onTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const version: number = parseInt(e.target?.value);

    if (isNaN(version)) {
        setErrorMessage('バージョンは数字のみ有効です');
        return;
    }

    if (!(version >= 0 && version <= 255)) {
        setErrorMessage('バージョンが範囲外の数字です');
        return;
    }

    setErrorMessage(null);
  };

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (!file) {
        setErrorMessage('ファイルを指定してください');
        return;
    }

    if (data.model === undefined) {
        setErrorMessage('機種名を選択してください');
        return;
    }

    const version1: number = parseInt(data.version1);
    const version2: number = parseInt(data.version2);
    const version3: number = parseInt(data.version3);

    if (isNaN(version1)) {
        setErrorMessage('バージョンは数字のみ有効です');
        return;
    }

    if (isNaN(version2)) {
        setErrorMessage('バージョンは数字のみ有効です');
        return;
    }

    if (isNaN(version3)) {
        setErrorMessage('バージョンは数字のみ有効です');
        return;
    }

    if (!(version1 >= 0 && version1 <= 255)) {
        setErrorMessage('バージョンが範囲外の数字です');
        return;
    }

    if (!(version2 >= 0 && version2 <= 255)) {
        setErrorMessage('バージョンが範囲外の数字です');
        return;
    }

    if (!(version3 >= 0 && version3 <= 65535)) {
        setErrorMessage('バージョンが範囲外の数字です');
        return;
    }


    //if (errorMessage !== null) return;

    const formData = new FormData();
    formData.append('file', file);
    formData.append('pkgname', data.model + '_' + data.version1 + '.' + data.version2 + '.' + data.version3 + '.pkg' );

    closeUploadDialog();
    openLoadingDialog();

    try {
      await authAxios(ctx).post("/mainfw/" + props.category, formData, {
        withCredentials: true,
        headers: {
          'content-type': 'multipart/form-data',
        },
      });

      setDoneDialogMessage('保存しました');
      setFile(null);
      setFileName('選択されていません');

      reset();

      closeLoadingDialog();
      openDoneDialog();
    } catch (err) {
      if ((err as any).response.status === 400 && (err as any).response.data?.error?.message === 'already exists') {
        setDoneDialogMessage('そのバージョンは既に存在します');
        closeLoadingDialog();
        openDoneDialog();
      } else if ((err as any).response.status === 400 && (err as any).response.data?.error?.message === 'cannot update published item') {
        setDoneDialogMessage('このバージョンは公開済みのため上書きできません');
        closeLoadingDialog();
        openDoneDialog();
      } else {
        setDoneDialogMessage(`保存に失敗しました(HTTP${(err as any).response.status})`);
        closeLoadingDialog();
        openDoneDialog();
      }
    }
  };

  const openLoadingDialog = () => {
    setLoadingDialogOpened(true);
  };

  const closeLoadingDialog = () => {
    setLoadingDialogOpened(false);
  };

  const openDoneDialog = () => {
    setDoneDialogOpened(true);
  };

  const closeDoneDialog = () => {
    setDoneDialogOpened(false);
  };

  const openUploadDialog = () => {
    setUploadDialogOpened(true);
  };

  const closeUploadDialog = () => {
    setUploadDialogOpened(false);
  };

  // --- 変数群

  const model_desc = WIZIoTSettings.categories.find((e) => e.category === props.category)?.description;
  let table_tags = [];
  let articles_by_model: {[key: string]: Article[]} = {};
  const select_options: Option[] = [];

  // --- メイン処理

  for (let i = 0; i < articles.length; i++) {
    if (articles_by_model[articles[i].model] === undefined) {
        articles_by_model[articles[i].model] = [];
    }

    articles_by_model[articles[i].model].push(articles[i]);
  }

  const keys: string[] = articles_by_model ? Object.keys(articles_by_model) : [];

  keys.sort();

  for (let key of keys) {
    articles_by_model[key].sort((a, b) => a.version > b.version ? 1 : -1);

    let tags = [];

    for (let i = 0; i < articles_by_model[key].length; i++) {
      const d = dayjs(articles_by_model[key][i].modified_on).format('YYYY/MM/DD HH:mm:ss');
      const digest = articles_by_model[key][i].digest.substr(0, 32) + ' ' + articles_by_model[key][i].digest.substr(32);

      tags.push(
        <TableRow>
          { i === 0 && <StyledTableCell sx={table_style} rowSpan={articles_by_model[key].length}>{articles_by_model[key][i].model}</StyledTableCell>
          }
          <StyledTableCell sx={table_style}>{articles_by_model[key][i].version}</StyledTableCell>
          <StyledTableCell sx={table_style}>{d}</StyledTableCell>
          <StyledTableCell sx={table_style}>{digest}</StyledTableCell>
        </TableRow>
      );
    }

    table_tags.push(
      <>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell width={column_width} sx={table_style}>機種名</StyledTableCell>
                <StyledTableCell width={column_width} sx={table_style}>バージョン</StyledTableCell>
                <StyledTableCell width={column_width} sx={table_style}>登録日時</StyledTableCell>
                <StyledTableCell width={column_width} sx={table_style}>チェックサム</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tags}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ m: 2 }}></Box>
      </>
    );
  }

  for (let i = 0; i < models.length; i++) {
    select_options.push(
      {
        value: models[i].model,
        label: models[i].model
      }
    );
  }

  return (
    <>
      <Box>
        <Box component="span" display="flex" justifyContent="space-between">
          <Box>
            <Link className="font-thema-color bolder" to="/firmware">
              機器管理＞公開ファームウェア管理
            </Link>＞本体ファームウェア一覧
          </Box>
          <Box>
          </Box>
        </Box>
        <Divider sx={{ mt: 2, mb: 2 }}></Divider>
        <Box display="flex" sx={{ mt: 2, mb: 2 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="h6" gutterBottom sx={{margin:0}}>{model_desc}</Typography>
          </Box>
          <Box sx={{ "display": "flex", "flex-direction": "column", "align-items": "flex-end" }}>
            <Button variant="contained" className="Thema_Color button_LONG_width" onClick={() => {openUploadDialog();}}>本体FWの追加</Button>
            <Link to="/firmware">
              <Button variant="contained" className="Thema_White_Color button_LONG_width" sx={{ border: 1 }}>一覧に戻る</Button>
            </Link>
            <Box className="font-thema-color">
              ※同じバージョン名で登録すると、上書き保存されます。<br/>
              　ただし、公開済みのバージョンのファイルは上書きできません。
            </Box>
          </Box>
        </Box>
        {table_tags}
      </Box>
      <Dialog open={isLoadingDialogOpened}>
        <DialogContent>
          <Box>
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={isDoneDialogOpened}>
        <DialogContent>
          <Typography component="h2">
            { doneDialogMessage }
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className="Thema_Color" onClick={() => {
            closeDoneDialog();
            openLoadingDialog();
            setReloadCounter(reloadCounter + 1);
          }}>OK</Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isUploadDialogOpened} fullWidth>
        <DialogTitle>
          <Typography color="primary.red">
            {errorMessage}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Input
            hidden
            type="file"
            inputRef={inputRef}
            sx={{display: 'none'}}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {onFileChange(e)}}
          />
          <TableContainer>
            <Table>
              <TableBody>
                <TableRow>
                  <TableCell>機種名</TableCell>
                  <TableCell>
                    <Controller
                      name="model"
                      control={control}
                      render={({ field: { onChange, value} }) => (
                        <Select
                          size="small"
                          value={value}
                          onChange={(e) => {
                            onChange(e.target?.value);
                          }}
                        >
                        {select_options.map((v) => (
                          <MenuItem value={v.value}>{v.label}</MenuItem>
                        ))}
                        </Select>
                      )}
                    />
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>FWファイル</TableCell>
                  <TableCell>
                    <Box sx={{display:"flex", mb: 2}}>
                      <TextField
                        sx={{ margin: "5px" }}
                        variant="outlined"
                        inputProps={{readOnly: true}}
                        value={fileName}
                        size="small"
                        fullWidth
                      />
                      <Button variant="contained" sx={{"margin-left": "10px"}} className="Thema_Color button_SHORT_width" onClick={() => { inputRef.current?.click();}}>参照</Button>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>バージョン</TableCell>
                  <TableCell>
                    <Box sx={{display:"flex", mb: 2,"align-items":"flex-end"}}>
                      <Controller
                         name="version1"
                         control={control}
                         render={({ field }) => (
                           <TextField
                             {...field}
                             type="text"
                             inputProps={{ maxLength: 3 }}
                             sx={{width: 80}}
                             size="small"
                             onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                               field.onChange(e.target?.value);
                               onTextFieldChange(e);
                             }}
                           />
                         )}
                      />
                      <Box>.</Box>
                      <Controller
                         name="version2"
                         control={control}
                         render={({ field }) => (
                           <TextField
                             {...field}
                             type="text"
                             inputProps={{ maxLength: 3 }}
                             sx={{width: 80}}
                             size="small"
                             onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                               field.onChange(e.target?.value);
                               onTextFieldChange(e);
                             }}
                           />
                         )}
                      />
                      <Box>.</Box>
                      <Controller
                         name="version3"
                         control={control}
                         render={({ field }) => (
                           <TextField
                             {...field}
                             type="text"
                             inputProps={{ maxLength: 5 }}
                             sx={{width: 80}}
                             size="small"
                             onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                               field.onChange(e.target?.value);
                               onTextFieldChange(e);
                             }}
                           />
                         )}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className="Thema_White_Color" sx={{ border: 1 }} onClick={() => {closeUploadDialog();}}>キャンセル</Button>
           &nbsp;
          <Button variant="contained" className="Thema_Color" onClick={handleSubmit(onSubmit)}>登録</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
 
export default ListMainFW;
