import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, Typography } from "@mui/material";
import React from "react";

export type FormCheckBoxDialogProps = {
  name: string
  open: boolean
  defaultValue: {label:string,value:boolean}[]
  onCompleted: (data:{label:string,value:boolean}[]) => void
  title?: any
}
export const FormCheckBoxDialog:React.FC<FormCheckBoxDialogProps> = (props) => {
  const [vlist,setVlist] = React.useState(props.defaultValue)

  React.useEffect(()=>{
    if(props.open){
      setVlist(props.defaultValue)
    }
  },[props.open])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const newItem = vlist.map((v,i)=>{
      if(`${props.name}_${i}` === event.target.name) {
        return {...v,value:checked}
      }
      return {...v}
    })
    setVlist(newItem)
  }

  const handleCompleted = (action: 'comfirm' | 'cancel') => {
    if(action === 'comfirm') {
      props.onCompleted(vlist)
    } else if(action === 'cancel') {
      props.onCompleted(props.defaultValue)
    }
  }
  return(
    <Dialog open={props.open}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <FormGroup>
          {vlist.map((v,i) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox 
                    checked={v.value}
                    name={`${props.name}_${i}`}
                    onChange={handleChange}
                  />
                }
                label={v.label}
                key={i}
              />
            )
          })}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" className="Thema_White_Color" sx={{ border: 1 }} onClick={()=>{ handleCompleted('cancel') }}>キャンセル</Button>
         &nbsp;
        <Button variant="contained" className="Thema_Color" onClick={()=>{ handleCompleted('comfirm') }}>OK</Button>
      </DialogActions>
    </Dialog>
  )
}
FormCheckBoxDialog.defaultProps = {
  title: <Typography>選択してください</Typography>
}
