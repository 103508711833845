import React from 'react';
import Index from '../components/Index';

const RouteIndex: React.FC = () => {
  return (
    <>
      <Index />
    </>
  );
};

export default RouteIndex;
