import React from "react";
import { Button } from '@mui/material';
import logo from '../img/aiotlogo_trans.png';

const Index: React.FC = () => {

  return (
    <div className="login_grid">
      <div className="login_column">
        <div className="login_logo">
          <img src={ logo } className="AIoT_logo"  alt="ロゴ" />
        </div>
        <div className="login_button">
          <Button href="/auth" variant="contained" className="Thema_Color Button_Size_Setting" >
            LOGIN
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Index;
