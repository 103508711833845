import React from 'react';
import { Navigate, useLocation } from "react-router-dom";
import { Box, CssBaseline, Toolbar } from '@mui/material';

import TopMenu from '../components/TopMenu';
import SideBar from '../components/SideBarOpenClose';
import Setting from '../components/setting/Setting';
import { AuthContext } from "../contexts/Auth";

const RouteSetting: React.FC = () => {
  const auth = React.useContext(AuthContext);
  const location = useLocation();

  if (!auth.value.isAuthenticated) {
    const state = {
      'redirect' : location.pathname
    }

    return <Navigate replace to="/login" state={state} />;
  }

  const email = auth.value.email;
  const roles = auth.value.roles;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <TopMenu email={ email } />
      <SideBar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Setting email={ email } roles={ roles } />
      </Box>
    </Box>
  );
};

export default RouteSetting;
