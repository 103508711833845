import React from 'react';
import { useNavigate } from "react-router-dom";
import { authAxios } from "../../../api/axios";
import { AuthContext } from "../../../contexts/Auth";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import WIZIoTSettings from "../../../constants/wiziot.json";

const Thema_Color = WIZIoTSettings.style.regular.main_color;

const border_left_size = 1;
const border_right_size = 1;
const table_style = {
  borderLeft: border_left_size,
  borderRight: border_right_size
};
const columnCount = 6;//表の列数
const column_width = 100 / columnCount + "%";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Thema_Color,
    color: "white",
    borderColor: "gray",
    "text-align": "center"
  },
[`&.${tableCellClasses.body}`]: {
    borderColor: "gray"
  }
}));

type SubArticle = {
  id: number;
  status: string;
  model: string;
  datePublished: string | null;
  firmware1: string;
  firmware2: string;
  description: string;
}

type Article = {
  [index: string]: SubArticle[];
};

type OnFinishedFuncType = {
  (): void;
};

type OnDeleteButtonClickedFuncType = {
  (category: string, id: number): void;
};

type OnDropButtonClickedFuncType = {
  (category: string, id: number, description: string): void;
};

type Props = {
  children?: React.ReactNode;
  category: string;
  onFinished: OnFinishedFuncType | undefined;
  onDeleteButtonClicked: OnDeleteButtonClickedFuncType | undefined;
  onDropButtonClicked: OnDropButtonClickedFuncType | undefined;
  reloadCnt: number;
};

const TableListFirmware: React.FC<Props> = ({children, category, onFinished, onDeleteButtonClicked, onDropButtonClicked, reloadCnt}) => {
  const ctx = React.useContext(AuthContext);
  const navigate = useNavigate();
  const [article, setArticle] = React.useState<Article>({});
  const [reloadCounter, setReloadCounter] = React.useState<number>(0);

  if (reloadCounter !== reloadCnt) {
    setReloadCounter(reloadCnt);
  }

  React.useEffect(() => {
    authAxios(ctx).get('/firmware/' + category).then((response) => {

      setArticle(response.data.data);

      if (onFinished) onFinished();
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 403) {
          ctx.value.access_token = "";
          ctx.value.expires = 0;
          ctx.value.email = "";

          navigate("/");
        }
      }

      setArticle({
        'error' : [
          {
            id: 0,
            status: 'draft',
            model: 'error',
            datePublished: null,
            firmware1: 'エラーです',
            firmware2: 'エラーです',
            description: 'エラーです'
          }
        ]
      });

      if (onFinished) onFinished();
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadCounter]);

  let category_tags = [];

  const keys: string[] = article ? Object.keys(article) : [];

  keys.sort();

  for (let key of keys) {
    let tags = [];

    if (article[key].length == 0) {
      tags.push(
        <TableRow>
          <StyledTableCell sx={table_style}>{key}</StyledTableCell>
          <StyledTableCell sx={table_style}>&nbsp;</StyledTableCell>
          <StyledTableCell sx={table_style}>&nbsp;</StyledTableCell>
          <StyledTableCell sx={table_style}>&nbsp;</StyledTableCell>
          <StyledTableCell sx={table_style}>
            <div style={{wordBreak: "break-all"}}>
              &nbsp;
            </div>
          </StyledTableCell>
          <StyledTableCell sx={table_style}>&nbsp;</StyledTableCell>
        </TableRow>
      );
    } else {
      for (let i = 0; i < article[key].length; i++) {
        let tag = (
          <>
          </>
        );

        let button_tags = (
          <>
          </>
        );

        if (i === 0) {
          tag = (
            <StyledTableCell rowSpan={article[key].length} sx={table_style}>{key}</StyledTableCell>
          );
        }

        if (article[key][i].status === 'published') {
          button_tags = (
            <Button variant="contained" className="Delete_Color button_MEDIUM_width" sx={{ ml: 1 }} onClick={() => {
              if (onDropButtonClicked) onDropButtonClicked(category, article[key][i].id, article[key][i].description);
            }}>
              公開中
            </Button>
          );
        } else if (article[key][i].status === 'dropped') {
          button_tags = (
            <Button variant="contained" className="grey_button_color button_MEDIUM_width" sx={{ ml: 1 }} disabled>
              取り下げ
            </Button>
          );
        } else if (article[key][i].status === 'reserved') {
          button_tags = (
            <Button variant="contained" className="blue_button_color button_MEDIUM_width" sx={{ ml: 1 }} onClick={() => {
              if (onDeleteButtonClicked) onDeleteButtonClicked(category, article[key][i].id);
            }}>
              公開予定
            </Button>
          );
        }

        const date_published = article[key][i].datePublished !== null ? (article[key][i].datePublished as string).replaceAll('-', '/') : '';

        tags.push(
            <TableRow>
              {tag}
              <StyledTableCell sx={table_style}>{date_published}</StyledTableCell>
              <StyledTableCell sx={table_style}>{article[key][i].firmware1}</StyledTableCell>
              <StyledTableCell sx={table_style}>{article[key][i].firmware2}</StyledTableCell>
              <StyledTableCell sx={table_style}>
                <div style={{wordBreak: "break-all"}}>
                  {article[key][i].description}
                </div>
              </StyledTableCell>
              <StyledTableCell sx={table_style}>{button_tags}</StyledTableCell>
            </TableRow>
        );
      }
    }

    category_tags.push(
      <>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell width={column_width} sx={table_style} rowSpan={2}>機種名</StyledTableCell>
                <StyledTableCell width={column_width} sx={table_style} rowSpan={2}>公開日</StyledTableCell>
                <StyledTableCell width={column_width} sx={table_style} colSpan={2}>公開バージョンの組み合わせ</StyledTableCell>
                <StyledTableCell width={column_width} sx={table_style} rowSpan={2}>備考</StyledTableCell>
                <StyledTableCell width={column_width} sx={table_style} rowSpan={2}>&nbsp;</StyledTableCell>
              </TableRow>
              <TableRow>
                <StyledTableCell width={column_width} sx={table_style}>本体FW</StyledTableCell>
                <StyledTableCell width={column_width} sx={table_style}>無線モジュールFW</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tags}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ mb: 2 }}></Box>
      </>
    );
  }

  return (
    <>
      {category_tags}
    </>
  );
}
 
export default TableListFirmware;
