import React from 'react';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import NotificationsNone from '@mui/icons-material/NotificationsNone';
import Error from '@mui/icons-material/Error';

import { AuthContext } from "../../contexts/Auth";

const HomeApplication: React.FC = () => {
  const auth = React.useContext(AuthContext);

  let contents_cnt = 0;

  let appinfo_tags = (
      <>
      </>
  );

  let apperr_tags = (
      <>
      </>
  );

  let header_tags = (
    <>
    </>
  );

  let footer_tags = (
    <>
    </>
  );

  if (auth.value.roles?.includes("wiziotmgr_full_admin") || (
        auth.value.customer_roles?.includes("appinfo") && (
          auth.value.roles?.includes("wiziotmgr_appinfo_rw") ||
          auth.value.roles?.includes("wiziotmgr_appinfo_ro") ||
          auth.value.roles?.includes("wiziotmgr_customer_admin") ||
          auth.value.roles?.includes("wiziotmgr_contract_admin")
      )
    )
  ) {
    appinfo_tags = (
      <Grid item xs={6}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
              <NotificationsNone />&nbsp;お知らせ配信管理
            </Typography>
            <Typography>
              アプリへのお知らせの配信・削除などを行います。
            </Typography>
          </CardContent>
          <CardActions>
            <Button className="Thema_Color" variant="contained" to="/app_info" component={Link} fullWidth>お知らせ配信管理画面へ</Button>
          </CardActions>
        </Card>
      </Grid>
    );

    contents_cnt = contents_cnt + 1;
  }

  if (auth.value.roles?.includes("wiziotmgr_full_admin") || (
        auth.value.customer_roles?.includes("apperr") && (
          auth.value.roles?.includes("wiziotmgr_apperr_rw") ||
          auth.value.roles?.includes("wiziotmgr_apperr_ro") ||
          auth.value.roles?.includes("wiziotmgr_customer_admin") ||
          auth.value.roles?.includes("wiziotmgr_contract_admin")
       )
     )
  ) {
    apperr_tags = (
      <Grid item xs={6}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
              <Error />&nbsp;エラー管理
            </Typography>
            <Typography>
              アプリへ通知する各種エラーの内容を管理します。
            </Typography>
          </CardContent>
          <CardActions>
            <Button className="Thema_Color" variant="contained" to="/app_err" component={Link} fullWidth>エラー管理画面へ</Button>
          </CardActions>
        </Card>
      </Grid>
    );

    contents_cnt = contents_cnt + 1;
  }

  if (contents_cnt > 0) {
    header_tags = (
      <>
        <Grid item xs={12}>
          <Box component="div" className="Thema_Header_Color" sx={{ p: 2 }}>
            アプリ管理
          </Box>
        </Grid>
      </>
    );

    if (contents_cnt % 2 === 1) {
      footer_tags = (
        <Grid item xs={6}>
        </Grid>
      );
    }
  }

  return (
    <>
      { header_tags }
      { appinfo_tags }
      { apperr_tags }
      { footer_tags }
    </>
  );
};

export default HomeApplication;
