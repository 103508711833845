import React from 'react';
import { Navigate, useLocation, useParams } from "react-router-dom";
import { Box, CssBaseline, Toolbar } from '@mui/material';

import TopMenu from '../components/TopMenu';
import SideBar from '../components/SideBarOpenClose';
import {EditRecipeContainer} from '../components/appliances/EditRecipeContainer';
import { AuthContext } from "../contexts/Auth";
import { CommonSettingType } from '../components/appliances/recipe/SettingType';
import settingList from '../constants/fridge.json'

const RouteEditFridge: React.FC = () => {
  const auth = React.useContext(AuthContext);
  const location = useLocation();
  const params = useParams()

  if (!auth.value.isAuthenticated) {
    const state = {
      'redirect' : location.pathname
    }

    return <Navigate replace to="/login" state={state} />;
  }

  const email = auth.value.email;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <TopMenu email={ email } />
      <SideBar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <EditRecipeContainer
          action='edit'
          createUrl='/recipe'
          recipeUrl={`/recipe/${params.id}`}
          returnUrl='/fridge'
          linkLabel='冷蔵コース管理'
          settingList={settingList as CommonSettingType[]}
        />
      </Box>
    </Box>
  );
};

export default RouteEditFridge;
