import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Dashboard from '@mui/icons-material/Dashboard';
import { AuthContext } from "../../contexts/Auth";

const HomeVisualize: React.FC = () => {
  const auth = React.useContext(AuthContext);

  if (auth.value.roles?.includes("wiziotmgr_full_admin") || (
        auth.value.customer_roles?.includes("dashboard") && (
          auth.value.roles?.includes("wiziotmgr_dashboard_ro") ||
          auth.value.roles?.includes("wiziotmgr_customer_admin") ||
          auth.value.roles?.includes("wiziotmgr_contract_admin")
      )
    )
  ) {
    const dashboard_url = auth.value.dashboard_url ? auth.value.dashboard_url : '';

    return (
      <>
        <Grid item xs={12}>
           <Box component="div" className="Thema_Header_Color" sx={{ p: 2 }}>
            データの可視化
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                <Dashboard />&nbsp;ダッシュボード
              </Typography>
              <Typography>
                登録機器に関する各種データを確認できます。
              </Typography>
            </CardContent>
            <CardActions>
              <Button variant="contained" className="Thema_Color" component="a" href={ dashboard_url } target="_blank" rel="noopener noreferrer" fullWidth>ダッシュボード画面へ</Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={6}>
        </Grid>
      </>
    );
  } else {
    return (
      <>
      </>
    );
  }
};

export default HomeVisualize;
