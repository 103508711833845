import React from "react";

export type AuthUserContextType = {
  isAuthenticated: boolean,
  email: string,
  delegate: string,
  access_token: string,
  expires: number,
  roles: string[],
  customer_roles: string[],
  dashboard_url: string | null
};

export type AuthContextType = {
  value: AuthUserContextType,
  setValue: React.Dispatch<React.SetStateAction<AuthUserContextType>>
}

const initValue = {
    isAuthenticated: false,
    email: "hoge@aiotcloudex.net",
    delegate: "fuga@aiotcloudex.net",
    access_token: "",
    expires: 0,
    roles: [],
    customer_roles: [],
    dashboard_url: null
} as AuthUserContextType;

export const AuthContext = React.createContext({} as AuthContextType);

export const AuthProvider = (props: { children: React.ReactNode }) => {
  const [value, setValue] = React.useState(initValue);
  return (
    <AuthContext.Provider value={{ value, setValue }}>
      { props.children }
    </AuthContext.Provider>
  );
};

export default AuthProvider;
