import { TextField } from '@mui/material';
import React from 'react';
import {useFormContext,Controller} from 'react-hook-form';


export type FormInputProps = {
  name:string
  label?:string
  placeholder?:string
  rules?: {}
  type?:string
  fullwidth?: boolean
}
export const FormInput:React.FC<FormInputProps> = (props) => {
  const {control} = useFormContext()
  return (
    <Controller 
      control={control} 
      name={props.name}
      rules={props.rules}
      render={({field,fieldState})=>{
        return (
          <TextField
            {...field} 
            fullWidth={props.fullwidth}
            type={props.type}
            label={props.label}
            placeholder={props.placeholder}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
          />
        )
      }}
    />
  )
}
