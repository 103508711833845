import React from 'react';
import { useNavigate } from "react-router-dom";
import { authAxios } from "../../../api/axios";
import { AuthContext } from "../../../contexts/Auth";
import Button from '@mui/material/Button'
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import AttachFile from '@mui/icons-material/AttachFile';

import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

import WIZIoTSettings from "../../../constants/wiziot.json";

const Thema_Color = WIZIoTSettings.style.regular.main_color;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Thema_Color,
    color: "white",
    borderColor: "gray",
    "text-align": "center"
  },
  [`&.${tableCellClasses.body}`]: {
    borderColor: "gray"
  }
}));

type Article = {
  id: number;
  order: number;
  title: string;
  icon: string;
  json_tags: string;
}

const CookerSearch: React.FC = () => {
  const ctx = React.useContext(AuthContext);
  const [article, setArticle] = React.useState<Article[]>([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    authAxios(ctx).get("/cooker_search").then((response) => {
      setArticle(response.data.data as Article[]);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 403) {
          ctx.value.access_token = "";
          ctx.value.expires = 0;
          ctx.value.email = "";

          navigate("/");
        }
      }

      setArticle([
        {
          id: 0,
          order: 0,
          title: 'エラー',
          icon: null as any as string,
          json_tags: '{}'
        }
      ] as Article[]
      );
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

    const cell_style = {
    borderLeft: 1,
    borderRight: 1,
    borderTop: 1,
    borderBottom: 1
  };

  const th_style = {
    borderLeft: 1,
    borderRight: 1,
    borderTop: 1,
    borderBottom: 1,
    backgroundColor: Thema_Color,
    color: "white",
    "text-align": "center"//デフォルトの左寄せをセンタリングで上書き
  };

  const table_row_1 = [];
  const table_row_2 = [];
  const table_row_3 = [];
  const table_row_4 = [];

  for (let i = 0; i < article.length; i++) {
    table_row_1.push(
      <StyledTableCell sx={th_style} className="Thema_Color" colSpan={2}>{article[i].order}</StyledTableCell>
    );

    table_row_2.push(
      <StyledTableCell sx={cell_style} colSpan={2}>{article[i].title}</StyledTableCell>
    );

    if (article[i].icon !== null && article[i].icon !== undefined) {
      const img_url = "/api/v1/assets/" + article[i].icon;

      table_row_3.push(
        <StyledTableCell sx={cell_style} colSpan={2}>
          <div className="info_horizon">
            <div>
              <Button variant="contained" className="Thema_Color" style={{ maxWidth: '38px', maxHeight: '38px', minWidth: '38px', minHeight: '38px' }}>
                <AttachFile />
              </Button>
            </div>
            <div>
              <img className="info_search_icon" src={img_url} alt="画像" />
            </div>
          </div>
        </StyledTableCell>
      );
    } else {
      table_row_3.push(
        <StyledTableCell sx={th_style} colSpan={2}>&nbsp;</StyledTableCell>
      );
    }

    const tags = JSON.parse(article[i].json_tags);
    let name_tag = [];
    if (tags.data === undefined) {
      name_tag.push(
        <TableRow>
          <StyledTableCell sx={cell_style}>&nbsp;</StyledTableCell>
          <StyledTableCell sx={cell_style}>&nbsp;</StyledTableCell>
        </TableRow>
      );
    } else {
      for (let j = 0; j < tags.data.length; j++) {
        const keys = Object.keys(tags.data[j]);
        const values = tags.data[j][keys[0]];
        let tag_row = [];

        if (values === undefined) {
          tag_row.push(<p>&nbsp;</p>);
        } else {
          for (let n = 0; n < values.length; n++) {
            tag_row.push(<p>{values[n]}</p>);
          }
        }
        name_tag.push(
          <TableRow>
            <StyledTableCell sx={cell_style}>{keys[0]}</StyledTableCell>
            <StyledTableCell sx={cell_style}>{tag_row}</StyledTableCell>
          </TableRow>
        );
      }
    }
    table_row_4.push(
      <Table sx={{ Width: 'auto', tableLayout: 'fixed' }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <StyledTableCell sx={th_style}>表示名</StyledTableCell>
            <StyledTableCell sx={th_style}>抽出するタグ</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {name_tag}
        </TableBody>
      </Table>
    )
  }
  return (
    <div>
      <Table sx={{ Width: 'auto', tableLayout: 'fixed' }} aria-label="simple table">
        <TableBody>
          <TableRow>
            <StyledTableCell sx={th_style} width="15%">掲載順</StyledTableCell>
            {table_row_1}
          </TableRow>
          <TableRow>
            <StyledTableCell sx={th_style} width="15%">タイトル名</StyledTableCell>
            {table_row_2}
          </TableRow>
          <TableRow>
            <StyledTableCell sx={th_style} width="15%">タイトルアイコン</StyledTableCell>
            {table_row_3}
          </TableRow>
        </TableBody>
      </Table>
      <div className="info_table_name_tag">
        {table_row_4}
      </div>
    </div>
  );
};
export default CookerSearch;
