import React from 'react';
import { useNavigate } from "react-router-dom";
import { authAxios } from "../../../api/axios";
import { AuthContext } from "../../../contexts/Auth";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';

import TableListFirmware from './TableListFirmware';

import WIZIoTSettings from "../../../constants/wiziot.json";

const Thema_Color = WIZIoTSettings.style.regular.main_color;
const border_left_size = 1;
const border_right_size = 1;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Thema_Color,
    color: "white",
    borderColor: "gray",
    "text-align": "center"
  },
[`&.${tableCellClasses.body}`]: {
    borderColor: "gray"
  }
}));

// --- 型定義

type SubArticle = {
  id: number;
  status: string;
  mode: string;
  datePublished: string | null;
  firmware1: string;
  firmware2: string;
  description: string;
}

type Article = {
  [index: string]: SubArticle[];
};

type NoticeArticle = {
  id: number;
  content: string;
  link: string | null;
};

type Inputs = {
  description: string;
};

type ProcessingDesc = {
  category: string;
  operation: string;
  id: number;
  description: string;
};

// --- state以外の永続変数

let processing_desc = {
  category: '',
  operation: '',
  id: 0
} as ProcessingDesc;

const ListFirmware: React.FC = () => {
  const auth = React.useContext(AuthContext);
  const navigate = useNavigate();
  const [noticeArticle, setNoticeArticle] = React.useState<NoticeArticle[]>([]);
  const [isDoneDialogOpened, setDoneDialogOpened] = React.useState<boolean>(false);
  const [doneDialogMessage, setDoneDialogMessage] = React.useState<string>('');
  const [isLoadingDialogOpened, setLoadingDialogOpened] = React.useState<boolean>(true);
  const [isConfirmDialogOpened, setConfirmDialogOpened] = React.useState<boolean>(false);
  const [confirmDialogMessage, setConfirmDialogMessage] = React.useState<string>('');
  const [isEditDialogOpened, setEditDialogOpened] = React.useState<boolean>(false);
  const [editDialogMessage, setEditDialogMessage] = React.useState<string>('');
  const [reloadCounter, setReloadCounter] = React.useState<number>(0);
  const [airTags, setAirTags] = React.useState(
    <>
    </>
  );
  const [cookerTags, setCookerTags] = React.useState(
    <>
    </>
  );
  const [fridgeTags, setFridgeTags] = React.useState(
    <>
    </>
  );
  const [cellarTags, setCellarTags] = React.useState(
    <>
    </>
  );
  const {
    control,
    handleSubmit,
    setValue
  } = useForm<Inputs>({
    mode: 'onChange'
  });

  React.useEffect(() => {
    for (const i of WIZIoTSettings.categories) {
      if (auth.value.roles?.includes("wiziotmgr_full_admin") || (
          auth.value.customer_roles?.includes(i.category) && (
            auth.value.roles?.includes("wiziotmgr_firmware_ro") ||
            auth.value.roles?.includes("wiziotmgr_firmware_rw") ||
            auth.value.roles?.includes("wiziotmgr_customer_admin") ||
            auth.value.roles?.includes("wiziotmgr_contract_admin")
          )
        )
      ) {
        const tags = (
          <>
            <Divider sx={{ mt: 2, mb: 2 }}></Divider>
            <Box sx={{display:"flex", "align-items": "flex-start" ,marginBottom:"8px"}}>
              <Typography variant="h6" gutterBottom sx={{margin:0}}>{i.description}</Typography>
              <Box sx={{"margin-left":"auto",display:"flex","flex-direction":"column" }}>
                <Button className="Thema_Color button_LONG_width" variant="contained" component={Link} to={`/firmware/${i.category}/+`}>
                  公開情報の追加
                </Button>
                <Button className="Thema_Color button_LONG_width" variant="contained" onClick={() => {
                  onPublishButtonClicked(i.category);
                }}>
                  ファームウェアの公開
                </Button>
                <Button className="Thema_White_Color button_LONG_width" variant="contained" sx={{ border: 1 }} component={Link} to={`/mainfw/${i.category}`}>
                  本体FWの登録
                </Button>
              </Box>
            </Box>
            <TableListFirmware
              category={i.category}
              onFinished={() => {onFinished();}}
              onDropButtonClicked={(arg1, arg2, arg3) => {onDropButtonClicked(arg1, arg2, arg3);}}
              onDeleteButtonClicked={(arg1, arg2) => {onDeleteButtonClicked(arg1, arg2);}}
              reloadCnt={reloadCounter}
            />
          </>
        );

        if (i.category === 'air') {
          setAirTags(tags);
        } else if (i.category === 'cooker') {
          setCookerTags(tags); 
        } else if (i.category === 'fridge') {
          setFridgeTags(tags);
        } else if (i.category === 'cellar') {
          setCellarTags(tags);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadCounter]);

  React.useEffect(() => {
    authAxios(auth).get('/manage_firmware').then((response) => {
      setNoticeArticle(response.data.data as NoticeArticle[]);

      onFinished();
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 403) {
          auth.value.access_token = "";
          auth.value.expires = 0;
          auth.value.email = "";

          navigate("/");
        }
      }

      setNoticeArticle([
        {
          id: 0,
          content: '記事を取得できませんでした',
          link: ''
        }] as NoticeArticle[]
      );

      onFinished();
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadCounter]);

  // --- 関数群

  const setProcessingDesc = (category: string, operation: string, id: number) => {
    processing_desc.category = category;
    processing_desc.operation = operation;
    processing_desc.id = id;

    if (processing_desc.operation === 'publish') {
      setConfirmDialogMessage('公開予定のFWを公開します');
    } else if (processing_desc.operation === 'drop') {
      setConfirmDialogMessage('公開中のFWを取り下げます');
    } else if (processing_desc.operation === 'delete') {
      setConfirmDialogMessage('公開予定のFWを削除します');
    }
  };

  const doPublish = async(category: string) => {
    try {
      const value = {
        'op': 'publish'
      };

      const response = await authAxios(auth).patch(`/firmware/${category}`, value, {
        withCredentials: true,
      });

      if(response.status === 200) {
        setDoneDialogMessage('公開しました');
      } else {
        setDoneDialogMessage(`公開に失敗しました(HTTP${response.status})`);
      }
    } catch (err) {
      setDoneDialogMessage('エラーが発生しました');
    }

    openDoneDialog();
    cnt_finished = 0;
  };

  const doDrop = async(category: string, id: number, description: string) => {
    try {
      const value = {
        'description': description,
        'status': 'dropped'
      };

      const response = await authAxios(auth).patch(`/firmware/${category}/${id}`, value, {
        withCredentials: true,
      });

      if(response.status === 200) {
        setDoneDialogMessage('取り下げました');
      } else {
        setDoneDialogMessage(`取り下げに失敗しました(HTTP${response.status})`);
      }
    } catch (err) {
      setDoneDialogMessage('エラーが発生しました');
    }

    openDoneDialog();
    cnt_finished = 0;
  };

  const doDelete = async(category: string, id: number) => {
    try {
      const response = await authAxios(auth).delete(`/firmware/${category}/${id}`, {
        withCredentials: true,
      });

      if(response.status === 200) {
        setDoneDialogMessage('削除しました');
      } else {
        setDoneDialogMessage(`削除に失敗しました(HTTP${response.status})`);
      }
    } catch (err) {
      setDoneDialogMessage('エラーが発生しました');
    }

    openDoneDialog();
    cnt_finished = 0;
  };

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    closeEditDialog();
    openLoadingDialog();
    doDrop(processing_desc.category, processing_desc.id, data.description);
  };

  const onFinished = () => {
    cnt_finished += 1;

    if (cnt_finished >= max_finished) {
      closeLoadingDialog();
    }
  };

  const onConfirmDialogClosed = () => {
    closeConfirmDialog();

    if (processing_desc.operation === 'publish') {
      openLoadingDialog();
      doPublish(processing_desc.category);
    } else if (processing_desc.operation === 'drop') {
      setEditDialogMessage('備考欄を変更できます');
      setValue('description', processing_desc.description);
      openEditDialog();
    } else if (processing_desc.operation === 'delete') {
      openLoadingDialog();
      doDelete(processing_desc.category, processing_desc.id);
    }
  };

  const onPublishButtonClicked = (category: string) => {
    processing_desc.category = category;
    processing_desc.operation = 'publish';
    processing_desc.id = 0;
    processing_desc.description = '';

    setConfirmDialogMessage('公開予定のFWを公開します');
    openConfirmDialog();
  };

  const onDropButtonClicked = (category: string, id: number, description: string) => {
    processing_desc.category = category;
    processing_desc.operation = 'drop';
    processing_desc.id = id;
    processing_desc.description = description;

    setConfirmDialogMessage('公開中のFWを取り下げます');
    openConfirmDialog();
  };

  const onDeleteButtonClicked = (category: string, id: number) => {
    processing_desc.category = category;
    processing_desc.operation = 'delete';
    processing_desc.id = id;
    processing_desc.description = '';

    setConfirmDialogMessage('公開予定のFWを削除します');
    openConfirmDialog();
  };

  const openDoneDialog = () => {
    setDoneDialogOpened(true);
  };

  const closeDoneDialog = () => {
    setDoneDialogOpened(false);
  };

  const openLoadingDialog = () => {
    setLoadingDialogOpened(true);
  };

  const closeLoadingDialog = () => {
    setLoadingDialogOpened(false);
  };

  const openConfirmDialog = () => {
    setConfirmDialogOpened(true);
  };

  const closeConfirmDialog = () => {
    setConfirmDialogOpened(false);
  };

  const openEditDialog = () => {
    setEditDialogOpened(true);
  };

  const closeEditDialog = () => {
    setEditDialogOpened(false);
  };

  // --- 変数群

  let max_finished = 1;
  let cnt_finished = 0;

  let card_tags = [];

  // --- メイン処理

  for (let i = 0; i < noticeArticle.length; i++) {
    card_tags.push(
      <Box>{noticeArticle[i].content}</Box>
    );

    if (noticeArticle[i].link) {
      card_tags.push(
        <Link to={noticeArticle[i].link as string} target="_blank" rel="noopener noreferrer">
          {noticeArticle[i].link}
        </Link>
      );
    }
  }

  for (const i of WIZIoTSettings.categories) {
    if (auth.value.roles?.includes("wiziotmgr_full_admin") || (
        auth.value.customer_roles?.includes(i.category) && (
          auth.value.roles?.includes("wiziotmgr_firmware_ro") ||
          auth.value.roles?.includes("wiziotmgr_firmware_rw") ||
          auth.value.roles?.includes("wiziotmgr_customer_admin") ||
          auth.value.roles?.includes("wiziotmgr_contract_admin")
        )
      )
    ) {
      max_finished += 1;
    }
  }

  return (
    <>
      <Box>
        <Box component="span" display="flex" justifyContent="space-between">
          <Link className="font-thema-color bolder" to="/firmware">
            機器管理＞公開ファームウェア管理
          </Link>
          <Box>
          </Box>
        </Box>
        <Divider sx={{ mt: 2, mb: 2 }}></Divider>
        <Box sx={{display:"flex", "align-items": "center" ,marginBottom:"8px"}}>
          <Typography variant="h6" gutterBottom sx={{margin:0}}>無線通信モジュールのお知らせ</Typography>
          <Box></Box>
        </Box>
        <Box sx={{width: '100%'}}>
          <Card variant="outlined">
            <CardContent>
              {card_tags}
            </CardContent>
          </Card>
        </Box>
        {airTags}
        {cookerTags}
        {fridgeTags}
        {cellarTags}
      </Box>
      <Dialog open={isDoneDialogOpened}>
        <DialogContent>
          <Typography component="h2">
            { doneDialogMessage }
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className="Thema_Color" onClick={() => {
            closeDoneDialog();
            openLoadingDialog();
            setReloadCounter(reloadCounter + 1);
            }}>OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isLoadingDialogOpened}>
        <DialogContent>
          <Box>
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog open={isConfirmDialogOpened}>
        <DialogContent>
          <Typography component="h2">
            { confirmDialogMessage }
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className="Thema_White_Color" sx={{ border: 1 }} onClick={() => {closeConfirmDialog();}}>キャンセル</Button>
          &nbsp;
          <Button variant="contained" className="Thema_Color" onClick={() => {
            onConfirmDialogClosed();
          }}>OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isEditDialogOpened}>
        <DialogContent>
          <Typography component="h2">
            { editDialogMessage }
          </Typography>
          <Controller
            name="description"
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                type="text"
                fullWidth
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Box sx={{width: '200px'}}></Box>
          <Button variant="contained" className="Thema_White_Color" sx={{ border: 1 }} onClick={() => {closeEditDialog();}}>キャンセル</Button>
          &nbsp;
          <Button variant="contained" className="Thema_Color" onClick={handleSubmit(onSubmit)}>
            公開を取り下げる
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
 
export default ListFirmware;
