import React from 'react';
import { useNavigate } from "react-router-dom";
import { authAxios } from "../../../api/axios";
import { AuthContext } from "../../../contexts/Auth";
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';

import WIZIoTSettings from "../../../constants/wiziot.json";

const Thema_Color = WIZIoTSettings.style.regular.main_color;

const border_left_size = 1;
const border_right_size = 1;
const table_style = {
  borderLeft: border_left_size,
  borderRight: border_right_size
};
const columnCount = 2;//表の列数
const column_width = 100 / columnCount + "%";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Thema_Color,
    color: "white",
    borderColor: "gray",
    "text-align": "center"
  },
[`&.${tableCellClasses.body}`]: {
    borderColor: "gray"
  }
}));

type Article = {
  id: number;
  model: string;
  description: string;
}

type OnFinishedFuncType = {
  (): void;
};

type Props = {
  children?: React.ReactNode;
  category: 'air' | 'cooker' | 'fridge' | 'cellar';
  onFinished: OnFinishedFuncType | undefined;
}

const ListModelNameAny: React.FC<Props> = ({children, category, onFinished}) => {
  const ctx = React.useContext(AuthContext);
  const [article, setArticle] = React.useState<Article[]>([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    let path = '/';

    if (category === 'cooker') {
      path = '/cooker_models';
    } else if (category === 'fridge') {
      path = '/fridge_models';
    } else if (category === 'air') {
      path = '/air_models';
    } else if (category === 'cellar') {
      path = '/cellar_models';
    } else {
      setArticle([
        {
          id: 0,
          model: 'エラー',
          description: 'エラーです'
        }] as Article[]);

      return;
    }

    authAxios(ctx).get(path).then((response) => {
      setArticle(response.data.data as Article[]);

      if (onFinished) onFinished();
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 403) {
          ctx.value.access_token = "";
          ctx.value.expires = 0;
          ctx.value.email = "";

          navigate("/");
        }
      }

      setArticle([
        {
          id: 0,
          model: 'エラー',
          description: 'エラーです'
        }] as Article[]
      );

      if (onFinished) onFinished();
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const table_rows = [];

  for (let i = 0; i < article.length; i++) {
    table_rows.push(
      <TableRow>
        <StyledTableCell sx={table_style}>{article[i].model}</StyledTableCell>
        <StyledTableCell sx={table_style}>{article[i].description}</StyledTableCell>
      </TableRow>
    );

  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell width={column_width} sx={table_style}>機種名</StyledTableCell>
            <StyledTableCell width={column_width} sx={table_style}>説明（任意）</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {table_rows}
        </TableBody>
      </Table>
    </TableContainer>
   );
};

export default ListModelNameAny;
