import React from 'react';

const NotFound: React.FC = () => {
  return (
    <>
     <div>ページが存在しません</div>
    </>
  );
};

export default NotFound;

