import React from 'react';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';

import { AuthContext } from "../../../contexts/Auth";
import ListModelNameAny from './ListModelNameAny';

const ListModelName: React.FC = () => {
  // --- hook群

  const auth = React.useContext(AuthContext);
  const [isLoadingDialogOpened, setLoadingDialogOpened] = React.useState<boolean>(true);

  // --- 関数群

  const onFinished = () => {
    cnt_finished += 1;

    if (cnt_finished >= max_finished) {
      closeLoadingDialog();
    }
  };

  const openLoadingDialog = () => {
    setLoadingDialogOpened(true);
  };

  const closeLoadingDialog = () => {
    setLoadingDialogOpened(false);
  };

  // --- 変数群

  let max_finished = 0;
  let cnt_finished = 0;
 
  let air_tags = (
    <>
    </>
  );

  let cooker_tags = (
    <>
    </>
  );

  let fridge_tags = (
    <>
    </>
  );

  let cellar_tags = (
    <>
    </>
  );

  if (auth.value.roles?.includes("wiziotmgr_full_admin") || (
      auth.value.customer_roles?.includes("air") && (
        auth.value.roles?.includes("wiziotmgr_content_ro") ||
        auth.value.roles?.includes("wiziotmgr_content_rw") ||
        auth.value.roles?.includes("wiziotmgr_customer_admin") ||
        auth.value.roles?.includes("wiziotmgr_contract_admin")
      )
    )
  ) {
    air_tags = (
      <>
        <Divider sx={{ mt: 2, mb: 2 }}></Divider>
        <Box sx={{display:"flex", "align-items": "center" ,marginBottom:"8px"}}>
          <Typography variant="h5" gutterBottom sx={{margin:0}}>空調機器</Typography>
          <Box sx={{"margin-left": "auto"}}>
            <Link to="/model_name/air">
              <Button variant="contained" className="Thema_Color button_MEDIUM_width"><span className="text_justify">編集</span></Button>
            </Link>
          </Box>
        </Box>
        <ListModelNameAny category="air" onFinished={() => {onFinished();}} />
      </>
    );

    max_finished += 1;
  }

  if (auth.value.roles?.includes("wiziotmgr_full_admin") || (
      auth.value.customer_roles?.includes("cooker") && (
        auth.value.roles?.includes("wiziotmgr_content_ro") ||
        auth.value.roles?.includes("wiziotmgr_content_rw") ||
        auth.value.roles?.includes("wiziotmgr_customer_admin") ||
        auth.value.roles?.includes("wiziotmgr_contract_admin")
      )
    )
  ) {
    cooker_tags = (
      <>
        <Divider sx={{ mt: 2, mb: 2 }}></Divider>
        <Box sx={{display:"flex", "align-items": "center" ,marginBottom:"8px"}}>
          <Typography variant="h5" gutterBottom sx={{margin:0}}>調理機器</Typography>
          <Box sx={{"margin-left": "auto"}}>
            <Link to="/model_name/cooker">
              <Button variant="contained" className="Thema_Color button_MEDIUM_width"><span className="text_justify">編集</span></Button>
            </Link>
          </Box>
        </Box>
        <ListModelNameAny category="cooker" onFinished={() => {onFinished();}} />
      </>
    );

    max_finished += 1;
  }

  if (auth.value.roles?.includes("wiziotmgr_full_admin") || (
      auth.value.customer_roles?.includes("fridge") && (
        auth.value.roles?.includes("wiziotmgr_content_ro") ||
        auth.value.roles?.includes("wiziotmgr_content_rw") ||
        auth.value.roles?.includes("wiziotmgr_customer_admin") ||
        auth.value.roles?.includes("wiziotmgr_contract_admin")
      )
    )
  ) {
    fridge_tags = (
      <>
        <Divider sx={{ mt: 2, mb: 2 }}></Divider>
        <Box sx={{display:"flex", "align-items": "center" ,marginBottom:"8px"}}>
          <Typography variant="h5" gutterBottom sx={{margin:0}}>冷蔵機器</Typography>
          <Box sx={{"margin-left": "auto"}}>
            <Link to="/model_name/fridge">
              <Button variant="contained" className="Thema_Color button_MEDIUM_width"><span className="text_justify">編集</span></Button>
            </Link>
          </Box>
        </Box>
        <ListModelNameAny category="fridge" onFinished={() => {onFinished();}} />
      </>
    );

    max_finished += 1;
  }

  if (auth.value.roles?.includes("wiziotmgr_full_admin") || (
      auth.value.customer_roles?.includes("cellar") && (
        auth.value.roles?.includes("wiziotmgr_device_ro") ||
        auth.value.roles?.includes("wiziotmgr_device_rw") ||
        auth.value.roles?.includes("wiziotmgr_customer_admin") ||
        auth.value.roles?.includes("wiziotmgr_contract_admin")
      )
    )
  ) {
    cellar_tags = (
      <>
        <Divider sx={{ mt: 2, mb: 2 }}></Divider>
        <Box sx={{display:"flex", "align-items": "center" ,marginBottom:"8px"}}>
          <Typography variant="h5" gutterBottom sx={{margin:0}}>ワイン・日本酒セラー</Typography>
          <Box sx={{"margin-left": "auto"}}>
            <Link to="/model_name/cellar">
              <Button variant="contained" className="Thema_Color button_MEDIUM_width"><span className="text_justify">編集</span></Button>
            </Link>
          </Box>
        </Box>
        <ListModelNameAny category="cellar" onFinished={() => {onFinished();}} />
      </>
    );

    max_finished += 1;
  }

  return (
    <>
      <Box>
        <Box component="span" display="flex" justifyContent="space-between">
          <Link className="font-thema-color bolder" to="/model_name">
            機器管理＞機種名登録
          </Link>
          <Box>
          </Box>
        </Box>
        {air_tags}
        {cooker_tags}
        {fridge_tags}
        {cellar_tags}
      </Box>
      <Dialog open={isLoadingDialogOpened}>
        <DialogContent>
          <Box>
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
export default ListModelName;
