import React from 'react';
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import { FixedSizeList, ListChildComponentProps } from 'react-window';
import { authAxios } from "../../api/axios";
import { AuthContext } from "../../contexts/Auth";
import { Link } from "react-router-dom";

const information_width = "100%"; //仮想windowの幅
const information_height = 120; //仮想windowの高さ
const information_itemsize = 36; //お知らせとお知らせの余白
const information_count = 4; //基本としてはinformations配列の数
const information_overscancount = 3; //予め読み込んでおく数

type Article = {
  id: number;
  content: string;
  link: string;
};

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;

  return (
    <ListItem style={style} key={index} component="div" className="list_margin" disablePadding>
        <Typography>
         {data[index]}
        </Typography>
    </ListItem>
  );
}

const HomeInformation: React.FC = () => {
  const ctx = React.useContext(AuthContext);
  const [article, setArticle] = React.useState<Article[]>([]);
  const navigate = useNavigate();

  React.useEffect(() => {
    authAxios(ctx).get('/manage_home').then((response) => {
      setArticle(response.data.data as Article[]);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 403) {
          ctx.value.access_token = "";
          ctx.value.expires = 0;
          ctx.value.email = "";

          navigate("/");
        }
      }

      setArticle([
        {
          id: 0,
          content: '記事を取得できませんでした',
          link: ''
        }] as Article[]
      );
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const information = [];

  for (let i = 0; i < article.length; i++) {
    information.push(article[i].content);

    if (article[i].link) {
      information.push(
        <Link to={article[i].link as string} target="_blank" rel="noopener noreferrer">
          {article[i].link}
        </Link>
      );
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Box component="div" className="Thema_Header_Color" sx={{ p: 2 }}>
          お知らせ
        </Box>
        <Card variant="outlined">
          <Box sx={{width: '100%', height: information_height, maxWidth: information_width, bgcolor: 'background.paper' }}>
            <FixedSizeList
              className="list_flow"
              height={information_height}
              width={information_width}
              itemSize={information_itemsize}
              itemCount={information_count}
              overscanCount={information_overscancount}
              itemData={information}
            >
              {renderRow}
            </FixedSizeList>
         </Box>
        </Card>
      </Grid>
    </>
  );
};

export default HomeInformation;
