import { Box, Dialog, DialogContent, Grid, Typography, DialogActions, Button, LinearProgress, LinearProgressProps } from "@mui/material";
import React from "react";
import { authAxios } from "../../api/axios";
import { AuthContext } from "../../contexts/Auth";
import { FormFileInput } from "./FormFileInput";


export type FormFileUploadType = {
  name: string
  uploadUrl:string
  rules?: any
  defaultValue?: string
  onChange: (value:{id:string|undefined,file:File}) => any //nameに設定するものを返す
}

const ProgressBar = (props:LinearProgressProps & {value: number}) => {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

export const FormFileUpload: React.FC<FormFileUploadType> = (props) => {
    const {uploadUrl,name,rules,defaultValue,onChange} = props
    const ctx = React.useContext(AuthContext);
    const [isOpenUpload, setIsOpenUpload] = React.useState<boolean>(false)
    const [isCompleted,setIsCompleted] = React.useState<boolean>(false)
    const [dialogLabel,setDialogLabel] = React.useState<string>('')
    const [percent,setPercent] = React.useState<number>(0)
    const dialogLabelProcessing = 'ファイルアップロード中'
    const dialogLabelSuccess = 'ファイルアップロード成功'
    const dialogLabelFailed = 'ファイルアップロード失敗'
  
  
    //ファイルアップロード
    const uploadFile = async (
      url:string,
      file:File,
      onProgress:(progress:number) => void
    ) => {
      const data = new FormData()
      data.append('file', file)
      const config = {
        withCredentials: true,
        headers: {
          "content-type": "multipart/form-data",
        },
      }
      return authAxios(ctx,(e)=>{
        if(e.event.type === 'progress') {
          onProgress(Math.round(e.progress * 100)) // 0～100
        }
      })
      .post(url, data, config)
    }

    const openDialog = () => {
      setDialogLabel(dialogLabelProcessing)
      setIsOpenUpload(true)
      setIsCompleted(false)
      setPercent(0)
    }
    const closeDialog = () => {
      setIsOpenUpload(false)
    }
    const fileUpload = async (file:File):Promise<string | undefined> => {
      //モーダルを開く
      openDialog()
      //ファイルをアップロード
      let newFileId = undefined
      await uploadFile(
        uploadUrl,
        file,
        (progress:number)=>{
          setPercent(progress)
        }
      ).then((response) => {
        if(response.status === 200) {
          const fileid = response?.data?.data?.id;
          if(fileid !== undefined && fileid !== null){
            setDialogLabel(`${dialogLabelSuccess}`)
            newFileId=fileid
          } else {
            setDialogLabel(`${dialogLabelFailed}：fileIdエラー`)
          }
        } else {
          setDialogLabel(`${dialogLabelFailed}：fileIdエラー`)
        }
        setIsCompleted(true)
      }).catch((error)=>{
        const statusCode = error?.response?.status
        const s = statusCode && Math.floor(statusCode/100)
        if(s) {
          if (s === 4) {
            setDialogLabel(`${dialogLabelFailed}：認証エラー（HTTP${statusCode}）`)
  
          } else if (s === 5) {
            setDialogLabel(`${dialogLabelFailed}：サーバーエラー（HTTP${statusCode}）`)
  
          } else {
            setDialogLabel(`${dialogLabelFailed}：レスポンスエラー（HTTP${statusCode}）`)
          }
        } else {
          setDialogLabel(`${dialogLabelFailed}：その他のエラー：${error}`)
        }
        setIsCompleted(true)
      })

      return newFileId
    }
  
    return (
      <Box>
        <FormFileInput
          name={name}
          rules={rules}
          onChangeFile={async (file) => {
            // ファイルのアップロード
            const id = await fileUpload(file)
            return onChange({id:id,file:file})
          }}
        />
        <Dialog
          open={isOpenUpload}
          onClose={()=>{ return }}
          fullWidth={true}
          maxWidth='lg'
        >
          <DialogContent>
            <Grid container direction={'column'}>
              {
                <Grid xs={12} item>
                  <ProgressBar value={percent} />
                </Grid>
              }
              <Grid item xs={12}>
                <Typography>{dialogLabel}</Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              disabled={!isCompleted}
              variant="contained"
              className="Thema_Color"
              onClick={()=>{
                closeDialog()
              }}
            >
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    )
  }
