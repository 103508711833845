import React from 'react';
import { useNavigate } from "react-router-dom";
import { authAxios } from "../../../api/axios";
import { AuthContext } from "../../../contexts/Auth";
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';
import Input from '@mui/material/Input';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useForm, SubmitHandler } from 'react-hook-form';

import TablePagination from '@mui/material/TablePagination';
import { useTheme } from '@mui/material/styles';
import TableFooter from '@mui/material/TableFooter';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';

import WIZIoTSettings from "../../../constants/wiziot.json";

const Thema_Color = WIZIoTSettings.style.regular.main_color;

const border_left_size = 1;
const border_right_size = 1;
const table_style = {
  borderLeft: border_left_size,
  borderRight: border_right_size
};
const columnCount = 3;//表の列数
const column_width = 100 / columnCount + "%";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Thema_Color,
    color: "white",
    borderColor: "gray",
    "text-align": "center"
  },
[`&.${tableCellClasses.body}`]: {
    borderColor: "gray"
  }
}));

const page_option=[5, 10, { label: 'All', value: -1 }];

type Article = {
  name: string;
  model: string;
  version: string;
  subtype: string;
}

type Inputs = {
  file: File;
}

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

const ListWireless: React.FC = () => {
  const ctx = React.useContext(AuthContext);
  const [article, setArticle] = React.useState<Article[]>([]);
  const [file, setFile] = React.useState<File | null>(null);
  const [fileName, setFileName] = React.useState<string>('選択されていません');
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [isDoneDialogOpened, setDoneDialogOpened] = React.useState<boolean>(false);
  const [doneModalMessage, setDoneModalMessage] = React.useState<string>('');
  const [isLoadingDialogOpened, setLoadingDialogOpened] = React.useState<boolean>(true);
  const [reloadCounter, setReloadCounter] = React.useState<number>(0);
  const navigate = useNavigate();
  const inputRef = React.useRef<HTMLInputElement>(null);
  const {
    handleSubmit
  } = useForm<Inputs>({
    mode: 'onChange'
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  React.useEffect(() => {
    authAxios(ctx).get('/wirelessfw').then((response) => {

      setArticle(response.data.data as Article[]);

      closeLoadingDialog();
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 403) {
          ctx.value.access_token = "";
          ctx.value.expires = 0;
          ctx.value.email = "";

          navigate("/");
        }
      }

      setArticle([
        {
          model: 'エラー',
          version: '',
          subtype: ''
        }] as Article[]
      );

      closeLoadingDialog();
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloadCounter]);

  // --- 関数群

  const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files && files[0]) {
      setFile(files[0]);
      setFileName(files[0].name);

      const re = /^[A-Za-z0-9-]{1,16}_[0-9]+\.[0-9]+\.[0-9]+_[0-9A-Z]+\.pkg$/g;

      if (!re.test(files[0].name)) {
        setErrorMessage('所定の形式ではありません');
      } else {
        setErrorMessage(null);
      }
    }
  };

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    if (!file) return;

    if (errorMessage !== null) return;

    const formData = new FormData();
    formData.append('file', file);

    openLoadingDialog();

    try {
      const response = await authAxios(ctx).post('/wirelessfw', formData, {
        withCredentials: true,
        headers: {
          'content-type': 'multipart/form-data',
        },
      });

      if(response.status === 200) {
        setDoneModalMessage('保存しました');
        setFile(null);
        setFileName('選択されていません');
      } else {
        setDoneModalMessage(`保存に失敗しました(HTTP${response.status})`);
      }

      closeLoadingDialog();
      openDoneDialog();
    } catch (err) {
      setDoneModalMessage('エラーが発生しました');

      closeLoadingDialog();
      openDoneDialog();
    }
  };

  const openDoneDialog = () => {
    setDoneDialogOpened(true);
  };

  const closeDoneDialog = () => {
    setDoneDialogOpened(false);
  };

  const openLoadingDialog = () => {
    setLoadingDialogOpened(true);
  };

  const closeLoadingDialog = () => {
    setLoadingDialogOpened(false);
  };

  // ---

  const firmware_tags = [];

  for (let i = 0; i < article.length; i++) {
    firmware_tags.push(
      <TableRow>
        <StyledTableCell sx={table_style}>{article[i].model}</StyledTableCell>
        <StyledTableCell sx={table_style}>{article[i].version}</StyledTableCell>
        <StyledTableCell sx={table_style}>{article[i].subtype}</StyledTableCell>
      </TableRow>
    );
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - firmware_tags.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Box>
        <Box component="span" display="flex" justifyContent="space-between">
          <Link className="font-thema-color bolder" to="/firmware">機器管理＞無線モジュールファームウェア管理</Link>
          <Box></Box>
        </Box>
        <Divider sx={{ mb: 2 }}></Divider>
        <Box sx={{display:"flex", "align-items": "center" ,marginBottom:"8px"}}>
          <Typography variant="h6" gutterBottom sx={{margin:0}}>登録FW一覧</Typography>
          <Box></Box>
        </Box>
        <Box>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 700 }} aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell width={column_width} sx={table_style}>型番</StyledTableCell>
                  <StyledTableCell width={column_width} sx={table_style}>Ver.</StyledTableCell>
                  <StyledTableCell width={column_width} sx={table_style}>SubType</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  (rowsPerPage > 0 ? firmware_tags.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : firmware_tags
                  ).map((firmware_tags) => (<>{firmware_tags}</>))
                }
                {
                  emptyRows > 0 && (
                   <TableRow style={{ height: 53 * emptyRows }}>
                     <TableCell colSpan={6} />
                   </TableRow>
                   )
                } 
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                   labelRowsPerPage= '表示データ数'
                   rowsPerPageOptions={page_option}
                   colSpan={3}
                   count={firmware_tags.length}
                   rowsPerPage={rowsPerPage}
                   page={page}
                   SelectProps={{inputProps: {'aria-label': 'rows per page'},native: true,}}
                   onPageChange={handleChangePage}
                   onRowsPerPageChange={handleChangeRowsPerPage}
                   ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
        <Divider sx={{ mt: 2, mb: 2 }}></Divider>
        <Box sx={{display:"flex", "align-items": "center" ,marginBottom:"8px"}}>
          <Typography variant="h6" gutterBottom sx={{margin:0}}>FW登録</Typography>
          <Box></Box>
        </Box>
        <Input
          hidden
          type="file"
          inputRef={inputRef}
          sx={{display: 'none'}}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {onChangeHandler(e)}}
        />
        <Box sx={{display:"flex", mb: 2}}>
          <TextField
            sx={{margin: "5px"}}
            variant="outlined"
            inputProps={{readOnly: true}}
            value={fileName}
            error={errorMessage !== null}
            helperText={errorMessage}
            size="small"
            fullWidth
          />
          <Button variant="contained" className="Thema_Color button_SHORT_width" onClick={() => { inputRef.current?.click();}}>参照</Button>
          <Button variant="contained" className="Thema_Color button_SHORT_width" onClick={handleSubmit(onSubmit)}>登録</Button>
        </Box>
      </Box>
      <Dialog open={isDoneDialogOpened}>
        <DialogContent>
          <Typography component="h2">
            { doneModalMessage }
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" className="Thema_Color" onClick={() => {
            closeDoneDialog();
            openLoadingDialog();
            setReloadCounter(reloadCounter + 1);
            }}>OK
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isLoadingDialogOpened}>
        <DialogContent>
          <Box>
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
 
export default ListWireless;
