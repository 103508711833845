import React from 'react';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { Sensors } from '@mui/icons-material';

import { AuthContext } from "../../contexts/Auth";

const HomeAIoT: React.FC = () => {
  const auth = React.useContext(AuthContext);

  let contents_cnt = 0;

  let wireless_tags = (
    <>
    </>
  );

  let header_tags = (
    <>
    </>
  );

  let footer_tags = (
    <>
    </>
  );

  if (auth.value.roles?.includes("wiziotmgr_full_admin") ||
      (auth.value.customer_roles?.includes("aiot") && (
        auth.value.roles?.includes("wiziotmgr_customer_admin") ||
        auth.value.roles?.includes("wiziotmgr_contract_admin")
      )
    )
  ) {
    wireless_tags = (
      <>
        <Grid item xs={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                <Sensors />&nbsp;無線モジュールFW管理
              </Typography>
              <Typography>
                無線モジュールファームウェアの管理を行います。
              </Typography>
            </CardContent>
            <CardActions>
              <Button className="Thema_Color" variant="contained" to="/wireless" component={Link} fullWidth>
                無線モジュールFW管理画面へ
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </>
    );

    contents_cnt = contents_cnt + 1;
  }

  if (contents_cnt > 0) {
    header_tags = (
      <Grid item xs={12}>
        <Box component="div" className="Thema_Header_Color" sx={{ p: 2 }}>
          AIoT専用メニュー
        </Box>
      </Grid>
    );

    if (contents_cnt % 2 === 1) {
      footer_tags = (
        <Grid item xs={6}>
        </Grid>
      );
    }
  }

  return (
    <>
      { header_tags }
      { wireless_tags }
      { footer_tags }
    </>
  );
};

export default HomeAIoT;
