import React from 'react';
import { Navigate, useLocation } from "react-router-dom";
import { Box, CssBaseline, Toolbar } from '@mui/material';
import TopMenu from '../components/TopMenu';
import SideBar from '../components/SideBarOpenClose';
import EditFirmware from '../components/devices/firmware/EditFirmware';
import { AuthContext } from "../contexts/Auth";


type Props = {
  children?: React.ReactNode;
  category: string;
};


const RouteEditFirmware: React.FC<Props> = (props) => {
  const auth = React.useContext(AuthContext);
  const location = useLocation();

  if (!auth.value.isAuthenticated) {
    const state = {
      'redirect' : location.pathname
    }
    return <Navigate replace to="/login" state={state} />;
  
  }

  const email = auth.value.email;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <TopMenu email={ email } />
      <SideBar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <EditFirmware
          action='add'
          linkLabel='公開ファームウェア管理'
          category={props.category}
        />
      </Box>
    </Box>
  );
};

export default RouteEditFirmware;
