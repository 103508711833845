import { 
  Dialog,
  DialogContent, 
  Grid, 
  CircularProgress, 
  Typography, 
  DialogActions, 
  Button 
} from "@mui/material";
import React from "react";
import { RecipeItem } from "./EditRecipe";
import { authAxios } from "../../../api/axios";
import { AuthContext } from "../../../contexts/Auth";

export type RecipeSubmitResultType = 'success'|'failed'|'cancel'
export type RecipeSubmitMethodType = 'post' | 'patch' | 'delete' | '_noAction' // _noActionは何もせずに終わること

export type RecipeSubmitHandlerReturnProp = {
  status: RecipeSubmitResultType
  message?: string
}

export type RecipeSubmitDialogProps = {
  open: boolean
  url: string
  values: RecipeItem[]
  method: RecipeSubmitMethodType
  onResponseSuccess: (response:any) => RecipeSubmitHandlerReturnProp 
  onResponseErrorCatch: (error:any) => RecipeSubmitHandlerReturnProp
  onClose: (result:RecipeSubmitResultType) => void
  label?: string 
}

export const RecipeSubmitDialog = (props:RecipeSubmitDialogProps) => {
  const {url,open,label,method,values,onResponseErrorCatch,onResponseSuccess,onClose} = props
  const [loading,setLoading] = React.useState(false)
  const [dialogLabel,setDialogLabel] = React.useState('')
  const [completed,setCompleted] = React.useState(false)
  const [result,setResult] = React.useState<RecipeSubmitResultType>('cancel')
  const ctx = React.useContext(AuthContext)

  React.useEffect(()=>{
    if(open) {
      setDefault()
    }
  },[open])

  const setDefault = () => {
    setLoading(false)
    setCompleted(false)
    setResult('cancel')
    setDialogLabel(label || `実行しますか？`)
  }

  const submitFunction = ()=>{
    if(method === 'post') {
      return authAxios(ctx).post(url,values,{withCredentials: true})
    } else if(method === 'patch') {
      return authAxios(ctx).patch(url,values,{withCredentials: true})
    } else if(method === 'delete') {
      return authAxios(ctx).delete(url,{withCredentials: true})
    }
    return authAxios(ctx).post(url,values,{withCredentials: true})
  }

  const submitRecipe = async () => {
    if(method === '_noAction') {
      const ret = onResponseSuccess(undefined)
      setResult(ret.status)
      setDialogLabel(ret.message || '成功しました')
      setLoading(false)
      setCompleted(true)
      return
    }
    setLoading(true)
    setCompleted(false)
    await submitFunction().then((response)=>{
      const ret = onResponseSuccess(response)
      setResult(ret.status)
      setDialogLabel(ret.message || '成功しました')
    })
    .catch((error)=>{
      const ret = onResponseErrorCatch(error)
      setResult(ret.status)
      setDialogLabel(ret.message || '失敗しました')
    })
    .finally(()=>{
      setLoading(false)
      setCompleted(true)
    })
  }
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth='xs'
    >
      <DialogContent>
        {
          loading ? (
            <Grid container alignItems={'center'} justifyItems={'center'} direction={'column'}>
              <Grid item xs={12}>
                <CircularProgress/>
              </Grid>
            </Grid>
          ) : (
            <Typography>{dialogLabel}</Typography>
          )
        }
      </DialogContent>
      <DialogActions>
        {
          completed ? (
            <>
              <Button variant="contained" className="Thema_Color" disabled={loading} onClick={()=>{ onClose(result) }} >閉じる</Button>
            </>
          ):(dialogLabel.indexOf("削除")!== -1)?
          (
            <>
              <Button variant="contained" className="Thema_White_Color" sx={{ border: 1 }} disabled={loading} onClick={()=>{ onClose('cancel')}}>キャンセル</Button>
               &nbsp;
              <Button variant="contained" className="Thema_Color" disabled={loading} onClick={()=>{ submitRecipe() }}>OK</Button>
            </>
          )
          :
         (
            <>
              <Button variant="contained" className="Thema_White_Color" sx={{ border: 1 }} disabled={loading} onClick={()=>{ onClose('cancel')}}>キャンセル</Button>
              &nbsp;
              <Button variant="contained" className="Thema_Color" disabled={loading} onClick={()=>{ submitRecipe() }}>OK</Button>
            </>
          )
        }
      </DialogActions>
    </Dialog>
  )
}
