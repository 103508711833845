import React from 'react';
import { useNavigate } from "react-router-dom";
import { authAxios } from "../../../api/axios";
import { AuthContext } from "../../../contexts/Auth";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';

import TableBody from '@mui/material/TableBody';
import { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';

import WIZIoTSettings from "../../../constants/wiziot.json";

const Thema_Color = WIZIoTSettings.style.regular.main_color;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Thema_Color,
    color: "white",
    borderColor: "gray",
    "text-align": "center"
  },
  [`&.${tableCellClasses.body}`]: {
    borderColor: "gray"
  }
}));

/*
type Article = {
  id: number;
  order: number;
  display: string;
  json_tags_join_CookerTags_C001_key_tag: string;
  condition: string;
}
// 本来このような構造を期待している
*/

const CookerSummary: React.FC = () => {
  const ctx = React.useContext(AuthContext);
  const [article, setArticle] = React.useState<any[]>([]);
  const navigate = useNavigate();

  const email = ctx.value.email;
  const tokenizer1 = email.split('@');
  const tokenizer2 = tokenizer1[0].split('_');
  const item_key = tokenizer2.length >= 2 ? 'json_tags_join_CookerTags_' + tokenizer2[1].toUpperCase() + '_key_tag' : '';

  React.useEffect(() => {
    authAxios(ctx).get("/cooker_summary").then((response) => {
      if (item_key !== '') {
        setArticle(response.data.data as any[]);
      } else {
        setArticle([
          {
            'id': 0,
            'order': 0,
            'display': 'エラー',
            item_key: '{}',
            'condition': '記事が取得できませんでした'
          }] as any[]
        );
      }
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 403) {
          ctx.value.access_token = "";
          ctx.value.expires = 0;
          ctx.value.email = "";

          navigate("/");
        }
      }

      setArticle([
        {
          'id': 0,
          'order': 0,
          'display': 'エラー',
          item_key: '{}',
          'condition': '記事が取得できませんでした'
        }] as any[]
      );
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cell_style = {
    borderLeft: 1,
    borderRight: 1,
    borderTop: 1,
    borderBottom: 1
  };

  const th_style = {
    borderLeft: 1,
    borderRight: 1,
    borderTop: 1,
    borderBottom: 1,
    backgroundColor: Thema_Color,
    color: "white",
    "text-align": "center"//デフォルトの左寄せをセンタリングで上書き
  };

  const table_row_1 = [];
  const table_row_2 = [];
  const table_row_3 = [];
  const table_row_4 = [];

  for (let i = 0; i < article.length; i++) {
    table_row_1.push(
      <StyledTableCell sx={th_style}>{article[i]['order']}</StyledTableCell>
    );

    table_row_2.push(
      <StyledTableCell sx={cell_style}>{article[i]['display']}</StyledTableCell>
    );

    let tag_row = [];
    if (article[i][item_key]) {
      const tags = JSON.parse(article[i][item_key]);
      if (tags.tags === undefined) {
        tag_row.push(<p>&nbsp;</p>);
      } else {
        for (let j = 0; j < tags.tags.length; j++) {
          tag_row.push(<p>{tags.tags[j]}</p>);
        }
      }
    }
    table_row_3.push(
      <StyledTableCell sx={cell_style}>{tag_row}</StyledTableCell>
    );

    table_row_4.push(
      <StyledTableCell sx={cell_style}>{article[i]['condition']}</StyledTableCell>
    );
  }

  return (
    <Table sx={{ Width: 'auto', tableLayout: 'fixed' }} aria-label="simple table">
      <TableBody>
        <TableRow>
          <StyledTableCell sx={th_style}>掲載順</StyledTableCell>
          {table_row_1}
        </TableRow>
        <TableRow>
          <StyledTableCell sx={th_style}>表示名</StyledTableCell>
          {table_row_2}
        </TableRow>
        <TableRow>
          <StyledTableCell sx={th_style}>抽出するタグ・材料</StyledTableCell>
          {table_row_3}
        </TableRow>
        <TableRow>
          <StyledTableCell sx={th_style}>タグ以外の抽出条件</StyledTableCell>
          {table_row_4}
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default CookerSummary;
