import React from 'react';
import { Navigate, useLocation } from "react-router-dom";
import { Box, CssBaseline, Toolbar } from '@mui/material';

import TopMenu from '../components/TopMenu';
//import SideBar from '../components/SideBar';
import SideBar from '../components/SideBarOpenClose';
import { AuthContext } from "../contexts/Auth";
import { EditRecipeContainer } from '../components/appliances/EditRecipeContainer';
import { CommonSettingType } from '../components/appliances/recipe/SettingType';
import settingList from '../constants/cooker.json';

const RouteAddCooker: React.FC = () => {
  const auth = React.useContext(AuthContext);
  const location = useLocation();

  if (!auth.value.isAuthenticated) {
    const state = {
      'redirect' : location.pathname
    }

    return <Navigate replace to="/login" state={state} />;
  }

  const email = auth.value.email;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <TopMenu email={ email } />
      <SideBar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <EditRecipeContainer
          action='add'
          createUrl='/cooker'
          recipeUrl='/cooker/0'
          returnUrl='/cooker'
          linkLabel='調理レシピ管理'
          settingList={settingList as CommonSettingType[]}
        />
      </Box>
    </Box>
  );
};

export default RouteAddCooker;
