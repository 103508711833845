import { Dialog, DialogTitle, DialogContent, FormControl, RadioGroup, FormControlLabel, Radio, DialogActions, Button, Typography } from "@mui/material";
import React from "react";

export type FormRadioDialogProps = {
  name: string
  open: boolean
  onCompleted: (data:string) => void
  values: {label:string, value:string }[]
  defaultValue: string
  title?: any
}

export const FormRadioDialog:React.FC<FormRadioDialogProps> = (props) => {
  const [selected,setSelected] = React.useState(props.defaultValue)

  React.useEffect(()=>{
    if(props.open) {
      setSelected(props.defaultValue)
    }
  },[props.open])

  const handleCompleted = (reason:'confirm'|'cancel') => {
    if(reason === 'confirm') {
      props.onCompleted(selected)
    } else {
      props.onCompleted(props.defaultValue)
    }
  }
  return(
    <Dialog
      open={props.open}
    >
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>
        <FormControl>
          <RadioGroup
            name={props.name}
            onChange={(e)=>{
              setSelected(e.target.value)
            }}
            value={selected}
          >
            {props.values.map((v,i)=>{
                return <FormControlLabel label={v.label} value={v.value} control={<Radio/>} key={i}/>
            })}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" className="Thema_White_Color" sx={{ border: 1 }} onClick={()=>{handleCompleted('cancel')}}>キャンセル</Button>
         &nbsp;
        <Button variant="contained" className="Thema_Color" onClick={()=>{handleCompleted('confirm')}}>OK</Button>
      </DialogActions>
    </Dialog>
  )
}

FormRadioDialog.defaultProps = {
  title: <Typography>選択してください</Typography>
}
