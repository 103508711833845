import React from 'react';
import { CommonSettingType, getDefaultValues } from './SettingType';
import { 
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Grid,
  Card,
  CardMedia,
} from '@mui/material';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { FormInput } from '../../common/FormInput';
import { FormTextarea } from '../../common/FormTextarea';
import { FormFileUpload } from '../../common/FormFileUpload';


const FormTableCellInput = (
  props:{
    name: string
    setting: CommonSettingType
    editable: boolean
  }
) => {
  const {name,setting,editable} = props
  const {watch} = useFormContext()
  const field = watch(name)
  return(
    <>
    {
      !editable ? (
        <Typography>
          {field}
        </Typography>
      ):(
        <FormInput
          fullwidth
          name={name}
          rules={setting.rules}
          type={setting.inputType === 'number' ? 'number' : undefined}
        />
      )
    }
    </>
  )
}
const FormTableCellTextArea = (
  props:{
    name: string
    setting: CommonSettingType
    editable: boolean
  }
) => {
  const {name,setting,editable} = props
  const {watch} = useFormContext()
  const field = watch(name)
  return(
    <>
    {
      !editable ? (
        <Typography>
          {field}
        </Typography>
      ):(
        <FormTextarea
          fullWidth
          name={name}
          rules={setting.rules}
        />
      )
    }
    </>
  )
}
const FormTableCellImage = (
  props: {
    name: string
    setting: CommonSettingType
    editable: boolean
    defaultValue?: string
    minWidth?: number
    maxWidth?: number
    height?: number
  }
) => {
  const {name,setting,editable,defaultValue,minWidth,maxWidth,height} = props
  const {watch,setValue} = useFormContext()
  const imgField = watch(name)
  const getUrl = () => {
    const u = imgField !== undefined && imgField !== null ? `/api/v1/assets/${imgField.id}` : defaultValue
    return u
  }
  const [url,setUrl] = React.useState<string|undefined>(getUrl())

  React.useEffect(()=>{
    setUrl(getUrl())
  },[imgField])

  return(
    <>
      {
        url && (
          <Card sx={{minWidth: minWidth || 300, maxWidth: maxWidth || 300}}>
            <CardMedia
              component={'img'}
              sx={{height: height}}
              src={url}
              title='画像'
            />
          </Card>
        )
      }
      {
        editable && (
          <FormFileUpload
            name={name}
            uploadUrl='/files'
            onChange={({id,file}) => {
              setValue(name,{id:id,type:file.type})
            }}
            rules={setting.rules}
          />
        )
      }
    </>
  )
}

export const FormTable = (props:{
  name:string
  rowSettings: CommonSettingType[]
  editable: boolean
  rowIndexLabel?: string
}) => {
  const {name,rowSettings,editable,rowIndexLabel} = props
  const {watch,setValue} = useFormContext()
  const tableData = watch(name) as any[]

  const removeTableRow = (removeIndex: number) => {
    setValue(name,tableData.filter(
      (value,index)=>{
        return index !== removeIndex
      }
    ))
  }

  return (
    <TableContainer component={Paper} sx={{width: '100%'}}>
      <Table>
        <TableHead>
          <TableRow>
            {
              rowIndexLabel && (
                <TableCell>
                  <Typography>
                    {rowIndexLabel}
                  </Typography>
                </TableCell>
              )
            }
            {
              rowSettings.map((v,i)=>{
                return(
                  <TableCell>{v.text}</TableCell>
                )
              })
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            tableData.map((row,rowIndex)=>{
              return(
                <TableRow key={rowIndex}>
                  {
                    // 行番号
                    rowIndexLabel && (
                      <TableCell>
                        <Typography>
                          {rowIndex+1}
                        </Typography>
                      </TableCell>
                    )
                  }
                  {
                    rowSettings.map((setting) => {
                      let CellItem = <></>
                      if(setting.interface === 'input') {
                        CellItem = <FormTableCellInput name={`${name}.${rowIndex}.${setting.name}`} setting={setting} editable={editable}/>
                      } else if(setting.interface === 'textarea') {
                        CellItem = <FormTableCellTextArea name={`${name}.${rowIndex}.${setting.name}`} setting={setting} editable={editable}/>
                      } else if(setting.interface === 'image') {
                        CellItem = <FormTableCellImage name={`${name}.${rowIndex}.${setting.name}`} setting={setting} editable={editable}/>
                      } 
                      return <TableCell>{CellItem}</TableCell>
                    })
                  }
                  {
                    // 削除ボタン
                    editable && (
                      <TableCell>
                        <Button variant="contained" className="Thema_White_Color button_SHORT_width" sx={{ border: 1 }} onClick={()=>{removeTableRow(rowIndex)}}>削除</Button>
                      </TableCell>
                    )
                  }
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export const FormEditTableDialog = (
  props: {
    open: boolean
    defaultValue: any[]
    rowSettings: CommonSettingType[]
    onCompleted: (data:any[]) => void
    rowIndexLabel?: string
  }
) => {
  const {open,defaultValue,rowSettings,onCompleted,rowIndexLabel} = props
  const name='table'
  const methods = useForm({defaultValues:{ [name]: defaultValue }})
  const field:any[] = methods.watch(name)

  React.useEffect(()=>{
    if(open) {
      methods.reset({ [name]: defaultValue })
    }
  },[open])

  const addTableRow = () => {
    const rowDefaultValues = getDefaultValues(undefined,rowSettings)
    field.push(rowDefaultValues)
    methods.setValue(name,field)
  }
  //行データが空っぽかどうかを判定する
  const isRowEmpty = (rowValue:any) => {
    // rowValueには行データが入っている
    // 例）調理レシピの「作り方」の場合：{ op: 'hogehoge', img: {id: 'fugafuga',type: 'foobar'}}
    return rowSettings.every((setting,index)=>{
              //デフォルト値と同じだったら空っぽ、という判定
              return rowValue[setting.name] === setting.default
            })
  }

  const handleCompleted = (reason:'confirm'|'cancel') => {
    if(reason === 'confirm'){
      methods.trigger().then((result)=>{
        if(result){
          const completedField = field.filter((rowValue,index)=>{
            return !isRowEmpty(rowValue) //空じゃないデータだけを取り出す
          })
          onCompleted(completedField)
        }
      })
    } else if(reason === 'cancel') {
      onCompleted(defaultValue)
    }
  }

  return (
    <>
      <FormProvider {...methods}>
        <form>
          <Dialog
            open={open}
            maxWidth='xl'
            fullWidth
          >
            <DialogContent>
              <Grid container>
                <Grid item xs={12}>
                  {/* 行追加ボタン */}
                  <Button variant="contained" className="Thema_Color button_MEDIUM_width" onClick={()=>{ addTableRow() }}>
                   行追加
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <FormTable
                    name={name}
                    editable={true}
                    rowSettings={rowSettings}
                    rowIndexLabel={rowIndexLabel}
                  />
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" className="Thema_White_Color" sx={{ border: 1 }} onClick={()=>{handleCompleted('cancel')}}>キャンセル</Button>
               &nbsp;
              <Button variant="contained" className="Thema_Color" onClick={()=>{handleCompleted('confirm')}}>OK</Button>
            </DialogActions>
          </Dialog>
        </form>
      </FormProvider>
    </>
  )
}
