import React from 'react';
import { Link } from "react-router-dom";
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { AddToHomeScreen, Keyboard } from '@mui/icons-material';

import { AuthContext } from "../../contexts/Auth";

const HomeDevices: React.FC = () => {
  const auth = React.useContext(AuthContext);

  let contents_cnt = 0;

  let device_tags = (
    <>
    </>
  );

  let firmware_tags =  (
    <>
    </>
  );

  let header_tags = (
    <>
    </>
  );

  let footer_tags = (
    <>
    </>
  );

  if (auth.value.roles?.includes("wiziotmgr_full_admin") ||
      (auth.value.customer_roles?.includes("device") && (
        auth.value.roles?.includes("wiziotmgr_device_ro") ||
        auth.value.roles?.includes("wiziotmgr_device_rw") ||
        auth.value.roles?.includes("wiziotmgr_customer_admin") ||
        auth.value.roles?.includes("wiziotmgr_contract_admin")
      )
    )
  )  {
    device_tags = (
      <>
        <Grid item xs={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                <AddToHomeScreen />&nbsp;機種名登録
              </Typography>
              <Typography>
                機器の機種名情報の登録・追加を行います。
              </Typography>
            </CardContent>
            <CardActions>
              <Button className="Thema_Color" variant="contained" to="/model_name" component={Link} fullWidth>
                機種名登録画面へ
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </>
    );

    contents_cnt = contents_cnt + 1;
  }

  if (auth.value.roles?.includes("wiziotmgr_full_admin") ||
      (auth.value.customer_roles?.includes("firmware") && (
        auth.value.roles?.includes("wiziotmgr_firmware_ro") ||
        auth.value.roles?.includes("wiziotmgr_firmware_rw") ||
        auth.value.roles?.includes("wiziotmgr_customer_admin") ||
        auth.value.roles?.includes("wiziotmgr_contract_admin")
      )
    )
  ) {
    firmware_tags = (
      <>
        <Grid item xs={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center' }}>
                <Keyboard />&nbsp;公開ファームウェア管理
              </Typography>
              <Typography>
                アプリから更新可能なファームウェアを登録します。
              </Typography>
            </CardContent>
            <CardActions>
              <Button className="Thema_Color" variant="contained" to="/firmware" component={Link} fullWidth>
                公開ファームウェア管理画面へ
              </Button>
            </CardActions>
          </Card>
        </Grid>
      </>
    );

    contents_cnt = contents_cnt + 1;
  }

  if (contents_cnt > 0) {
    header_tags = (
      <Grid item xs={12}>
        <Box component="div" className="Thema_Header_Color" sx={{ p: 2 }}>
          機器管理
        </Box>
      </Grid>
    );

    if (contents_cnt % 2 === 1) {
      footer_tags = (
        <Grid item xs={6}>
        </Grid>
      );
    }
  }

  return (
    <>
      { header_tags }
      { device_tags }
      { firmware_tags}
      { footer_tags }
    </>
  );
};

export default HomeDevices;
