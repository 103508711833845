import React from 'react';
import { Navigate, useLocation } from "react-router-dom";
import { Box, CssBaseline, Toolbar } from '@mui/material';

import TopMenu from '../components/TopMenu';
import SideBar from '../components/SideBarOpenClose';
import CookerInfo from '../components/appliances/cookerinfo/CookerInfo';
import { AuthContext } from "../contexts/Auth";

const RouteCookerInfo: React.FC = () => {
  const auth = React.useContext(AuthContext);
  const location = useLocation();

  if (!auth.value.isAuthenticated) {
    const state = {
      'redirect' : location.pathname
    }

    return <Navigate replace to="/login" state={state} />;
  }

  if (!(
    auth.value.customer_roles?.includes("cooker") && (
      auth.value.roles?.includes("wiziotmgr_full_admin") ||
      auth.value.roles?.includes("wiziotmgr_contract_admin") ||
      auth.value.roles?.includes("wiziotmgr_customer_admin") ||
      auth.value.roles?.includes("wiziotmgr_content_po") ||
      auth.value.roles?.includes("wiziotmgr_content_ro") ||
      auth.value.roles?.includes("wiziotmgr_content_rw"))
   )) {
    return (
      <>
      </>
    );
  }

  const email = auth.value.email;

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <TopMenu email={ email } />
      <SideBar />
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <CookerInfo />
      </Box>
    </Box>
  );
};

export default RouteCookerInfo;
