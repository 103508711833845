import { AttachFile } from "@mui/icons-material";
import { FormControl, Grid, TextField, Typography, FormHelperText } from "@mui/material";
import Button from '@mui/material/Button';
import React from "react";
import { Controller, useFormContext } from "react-hook-form";


export type FormFileInputProps = {
  name: string
  onChangeFile: (file:File) => Promise<any> //nameに設定する値を返す
  rules?: any
  defaultValue?: string
}
export const FormFileInput:React.FC<FormFileInputProps> = (props) => {
  const inputRef = React.useRef(null)
  const {setValue,control} = useFormContext()
  const [displayName,setDisplayName] = React.useState(props.defaultValue || 'ファイルが選択されていません')
  const [errorMsg, setErrorMsg] = React.useState(undefined)

  const validationFile = (rules:any,file:File) => {
    if(!file){return false} //ファイルがないなら何も通してはダメ
    if(!rules){return true} //ルールがないならなんでも通してOK

    let error=false
    //ファイルの種類をチェック
    if(!error && rules.accept) {
      if(rules.accept.match && file.type.match(rules.accept.match)) {
        /* OK */
      } else {
        /* NG */
        error = true
        setErrorMsg(rules.accept.message)
      }
    }
    //ファイルのサイズをチェック
    if(!error && rules.maxSize) {
      if(rules.maxSize.value && file.size <= rules.maxSize.value) {
        /*OK*/
      } else {
        /*NG*/
        error = true
        setErrorMsg(rules.maxSize.message)
      }
    }
    return !error
  }

  return(
    <>
      <Controller
        name={props.name}
        control={control}
        rules={props.rules}
        render={({field,fieldState})=>{
          return(
            <FormControl error={(errorMsg || fieldState.error)?true:false}>
              <Grid container>
                <Grid item container xs={12}>
                  <Grid item xs={11}>
                    {/* 隠し要素 */}
                    <TextField
                      hidden
                      name={props.name}
                      inputRef={inputRef}
                      type='file'
                      inputProps={{accept:props?.rules?.accept?.accept}}
                      onChange={async (event)=>{
                        const e = event as React.ChangeEvent<HTMLInputElement>
                        const file = e.target.files?.[0]
                        if(file && validationFile(props.rules,file)) {
                          setErrorMsg(undefined)
                          setDisplayName(file.name)
                          const newValue = await props.onChangeFile(file)
                          if(newValue!==undefined){
                            setValue(props.name,newValue)
                          }
                        }
                      }}
                      style={{display:'none'}}
                    />
                    {/* ファイル名の表示 */}
                    <Typography
                      onClick={()=>{
                        (inputRef.current as any)?.click()
                      }}
                      style={{border:'solid 1px #DDDDDD'}}
                    >
                      {displayName}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    {/* クリップボタンの表示 */}
                    <Typography
                      onClick={()=>{
                        (inputRef.current as any)?.click()
                      }}
                    >
                     <Button variant="contained" className="Thema_Color" style={{ maxWidth: '25px', maxHeight: '25px', minWidth: '25px', minHeight: '25px' }}><AttachFile/></Button>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {/* エラー文の表示 */}
                  { 
                    // fieldStateのエラー文の方が優先的に表示される
                    (fieldState.error && <FormHelperText>{fieldState.error.message}</FormHelperText>)
                    ||(errorMsg && <FormHelperText>{errorMsg}</FormHelperText>) 
                  }
                </Grid>
              </Grid>
            </FormControl>
          )
        }}
      />
    </>
  )
}
