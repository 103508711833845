import React from 'react';
import { Link } from "react-router-dom";
import { Box, Button, Card, CardMedia, FormControl, FormHelperText, Grid, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form';
import { CommonSettingRulesType, CommonSettingType, getSetting } from './SettingType';
import { AuthContext } from '../../../contexts/Auth';
import { FormInput } from '../../common/FormInput';
import { FormTextarea } from '../../common/FormTextarea';
import { FormDropdown } from '../../common/FormDropdown';
import { FormRadioDialog } from '../../common/FormRadioDialog';
import { FormFileUpload } from '../../common/FormFileUpload';
import { FormCheckBoxDialog } from '../../common/FormCheckBoxDialog';
import { FormTable, FormEditTableDialog } from './FormTableDialog';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { styled } from '@mui/material/styles';
import { tableCellClasses } from '@mui/material/TableCell';
import WIZIoTSettings from "../../../constants/wiziot.json";

const Thema_Color = WIZIoTSettings.style.regular.main_color;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Thema_Color,
    color: "white",
    borderColor: "gray",
    "text-align": "center"
  },
[`&.${tableCellClasses.body}`]: {
    borderColor: "gray"
  }
}));

type ChoiceValue = {
  text: string
  value: any
};

type RecipeSchema = {
  field: string
  type: string
  interface: string
  text: string
  choice: ChoiceValue[]
}

export type RecipeItem = {
  name: string
  value: any
  schema?: RecipeSchema
  extra?: any
}

export type EditRecipeProps = {
  recipeItemList: RecipeItem[] 
  submitRecipeItems: (recipeItem:RecipeItem[]) => void
  deleteRecipeItems: () => void
  settingList: CommonSettingType[]
  defaultValues?: any
  returnUrl: string
}

// x人数分について表示
export type ForNumType = '1人分' | '2人分' | '3人分' | '4人分'
// x人数分についての配列
export const ForNumTypeList:ForNumType[] = ['1人分' , '2人分' , '3人分' , '4人分']

const FormRecipeInput = (props:{item:RecipeItem,setting:CommonSettingType}) => {
  const {item,setting} = props
  const getRule = (rules?: CommonSettingRulesType) => {
    let ret = rules
    if(ret?.pattern){
      const patternConverted = {
        ...ret,
        pattern:{
          value: (typeof(ret.pattern.value) === 'string') ? new RegExp(ret.pattern.value) : ret.pattern.value,
          message:ret.pattern.message
        }
      }
      ret = patternConverted
    }
    return ret
  }
  return(
    <FormInput
      fullwidth
      name={item.name}
      placeholder={'テキスト入力'}
      type={setting.inputType}
      rules={getRule(setting.rules)}
    />
  )
}
const FormRecipeTextArea = (props:{item:RecipeItem,setting:CommonSettingType}) => {
  const {item,setting} = props
  return(
    <FormTextarea
      fullWidth
      name={item.name}
      rules={setting.rules}
    />
  )
}
const FormRecipeDropdown = (props:{item:RecipeItem,setting:CommonSettingType}) => {
  const {item,setting} = props
  const dropdownValue = item.schema?.choice?.map((v)=>{ return {label: v.text, value: v.value}}) || []
  return(
    <FormDropdown
      name={item.name}
      rules={setting.rules}
      values={dropdownValue}
    />
  )
}
const FormRecipeImage = (props:{item:RecipeItem,setting:CommonSettingType}) => {
  const {item,setting} = props
  const {watch} = useFormContext()

  const getImageUrl = (id:string | undefined) => {
    return (id && id !== '') ? `/api/v1/assets/${id}` : `${process.env.PUBLIC_URL}/noimage.png`
  }

  const imageId = watch(item.name)
  const [imageUrl,setImageUrl] = React.useState(getImageUrl(imageId))

  React.useEffect(()=>{
    setImageUrl(getImageUrl(imageId))
  },[imageId])
  return(
    <Box>      
      <Card sx={{minWidth: 300, maxWidth: 300}}>
        <CardMedia
          component={'img'}
          src={imageUrl}
          title='画像'
        />
      </Card>
      <FormFileUpload
        name={item.name}
        uploadUrl={'/files'}
        rules={setting.rules}
        onChange={({id,file})=>{
          return id
        }}
      />
    </Box>
  )
}
const FormRecipeModalRadio = (props:{item:RecipeItem,setting:CommonSettingType}) => {
  const {item} = props
  const [open,setOpen] = React.useState(false)
  const {watch,setValue} = useFormContext()
  const value = watch(item.name)
  const getValueKey = () => {return Object.keys(value)[0]}
  const [valueKey,setValueKey] = React.useState(getValueKey())

  React.useEffect(()=>{
    setValueKey(getValueKey())
  },[value])
  return(
    <Grid container>
      <Grid item xs={10}>
        {value[valueKey] || '（未選択）'}
      </Grid>
      <Grid item xs={2}>
        <Button variant="contained" className="Thema_Color"
          onClick={()=>{setOpen(true)}}
        >
          <KeyboardArrowDownIcon />
        </Button>
      </Grid>
      <FormRadioDialog
          name={item.name}
          open={open}
          onCompleted={(data)=>{
            setValue(item.name,{[valueKey]: data})
            setOpen(false)
          }}
          values={item?.schema?.choice?.map?.((v)=>{ return {label:v.text,value:v.value as string}}) || []}
          defaultValue={value?.[valueKey] || ''}
        />
    </Grid>
  )
}
const FormRecipeModalCheckbox = (props:{item:RecipeItem,setting:CommonSettingType}) => {
  const {item,setting} = props
  const [open,setOpen] = React.useState(false)
  const {watch,setValue,control} = useFormContext()
  const value = watch(item.name)
  const getValueKey = () => {return Object.keys(value)[0]}
  const [valueKey,setValueKey] = React.useState(getValueKey())

  React.useEffect(()=>{
    setValueKey(getValueKey())
  },[value])

  const getRule = () => {
    const rules = []

    // requiredルールがある場合下記をpush
    if(setting.rules?.required) {
      rules.push(
        {
          validate: {
            message: (value:any) => {
              if(value[valueKey].length === 0) {
                return setting.rules?.required
              }
              return undefined
            }
          }
        }
      )
    }
    // その他のルールはこのあたり追加

    const ret = {}
    rules.forEach((v)=>{
      Object.assign(ret,v)
    })
    return ret
  }

  return(
    <>
      <Controller
        name={item.name}
        control={control}
        rules={
          getRule()
        }
        render={({fieldState})=>{ 
          return (
            <FormControl error={fieldState.error? true: false} fullWidth>
              <Grid container>
                <Grid item xs={10}>
                  { value[valueKey]?.map?.((v:any)=>{return v as string}).join('；') || '（未選択）'}
                </Grid>
                <Grid item xs={2}>
                  <Button variant="contained" className="Thema_Color"
                    onClick={()=>{setOpen(true)}}
                  >
                    <KeyboardArrowDownIcon />
                  </Button>
                </Grid>
                <FormCheckBoxDialog 
                  name={item.name}
                  defaultValue={
                    item.schema?.choice.map((v, i) => {
                      const checked = (value[valueKey] as string[]).includes(v.value)
                      return { label: v.text, value: checked }
                    }) || []
                  }
                  open={open}
                  onCompleted={
                    (data) => {
                      const newItem = data.filter((v,i)=>{return v.value}).map((v,i)=>{return v.label})
                      setValue(item.name,{[valueKey]: newItem})
                      setOpen(false)
                    }
                  }
                />
                <Grid item xs={12}>
                  {
                    fieldState.error && (
                      <FormHelperText>{fieldState.error.message}</FormHelperText>
                    )
                  }
                </Grid>
              </Grid>
            </FormControl>
          )
        }}
      />
    </>
  )
}
const FormRecipeCookerSequence = (props:{item:RecipeItem,setting:CommonSettingType}) => {
  const {item,setting} = props
  const {watch} = useFormContext()
  const value = watch(item.name)
  return(
    <Box>
      {value && <Typography><InsertDriveFileIcon/>{value}</Typography>}
      <FormFileUpload
        rules={setting.rules}
        name={item.name}
        uploadUrl={'/files'}
        onChange={({id})=>{
          return id
        }}
      />
    </Box>
  )
}
const FormRecipeCookerOperation = (props:{item:RecipeItem,setting:CommonSettingType}) => {
  const {item,setting} = props
  const {setValue,watch,control} = useFormContext()
  const tableKeyName = 'operation'

  // テーブルの行についての設定
  const rowSetting : CommonSettingType[] = setting.children || []

  const [open,setOpen] = React.useState(false)
  const fieldData = watch(item.name)

  const getRule = () => {
    const rules = []

    // requiredルールがある場合下記をpush
    if(setting.rules?.required) {
      rules.push(
        {
          validate: {
            message: (value:any) => {
              if(value[tableKeyName].length === 0) {
                return setting.rules?.required
              }
              return undefined
            }
          }
        }
      )
    }
    // その他のルールはこのあたり追加

    const ret = {}
    rules.forEach((v)=>{
      Object.assign(ret,v)
    })
    return ret
  }


  return(
    <Box>
      <Controller
        name={item.name}
        control={control}
        rules={
          getRule()
        }
        render={({fieldState})=>{
          return(
            <>
              <FormControl error={fieldState.error? true: false} fullWidth>
                <Grid container>
                  {/* 編集ボタン */}
                  <Grid item xs={12}>
                    <Button variant="contained" className="Thema_Color button_SHORT_width" onClick={()=>{setOpen(true)}}>
                      編集
                    </Button>
                    <FormEditTableDialog
                      open={open}
                      rowSettings={rowSetting}
                      rowIndexLabel={'番号'}
                      defaultValue={fieldData[tableKeyName]}
                      onCompleted={(data) => {
                        setValue(item.name,{[tableKeyName]:data})
                        setOpen(false)
                      }}
                    />
                  </Grid>
                  {/* テーブル表示 */}
                  <Grid item xs={12}>
                    <FormTable
                      name={`${item.name}.${tableKeyName}`}
                      rowSettings={rowSetting}
                      editable={false}
                      rowIndexLabel={'番号'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {
                      fieldState.error && (
                        <FormHelperText>{fieldState.error.message}</FormHelperText>
                      )
                    }
                  </Grid>
                </Grid>
              </FormControl>
            </>
          )
        }}
      />
    </Box>
  )
}
const FormRecipeCookerStuff = (props:{item:RecipeItem,setting:CommonSettingType}) => {
  const {item,setting} = props
  const {setValue,watch,control} = useFormContext()
  const keyStuff = 'stuff'

  // 全ての「x人分」を合わせたformデータ
  const fieldData = watch(item.name)

  // テーブルの行についての設定
  const rowSettings:CommonSettingType[] = setting.children || []
  
  // 編集モーダルの開閉フラグ
  const [open,setOpen] = React.useState<boolean>(false)

  // 表示するx人分について制御するState
  const getDefaultForNum = () => {
    for(let i=0; i<ForNumTypeList.length; i++){
      if(fieldData[keyStuff][ForNumTypeList[i]].length > 0) {
        return ForNumTypeList[i]
      }
    }
    return '1人分'
  }
  const defaultForNum: ForNumType = getDefaultForNum()
  const [forNum,setForNum] = React.useState<ForNumType>(defaultForNum)  

  // x人分を選択するドロップダウン
  const ForNumDropdown = () => {
    return(
      <Select
        value={forNum}
        onChange={(e)=>{
          const selectedItem = e.target.value as ForNumType
          setForNum(selectedItem)
        }}
      >
        {
          ForNumTypeList.map((v)=>{
            return(
              <MenuItem value={v}>{v}</MenuItem>
            )
          })
        }
      </Select>
    )
  }

  const getRule = () => {
    const rules = []

    // requiredルールがある場合下記をpush
    if(setting.rules?.required) {
      rules.push(
        {
          validate: {
            message: (value:any) => {
              const isAllEmpty = Object.keys(value[keyStuff]).every((v)=>{ return value[keyStuff][v].length === 0})
              return isAllEmpty ? setting.rules?.required : undefined
            }
          }
        }
      )
    }
    // その他のルールはこのあたり追加

    const ret = {}
    rules.forEach((v)=>{
      Object.assign(ret,v)
    })
    return ret
  }

  return(
    <Grid>
      <Grid item xs={12}>
        {/* 人数表示のプルダウン */}
        <Grid item xs={12}>
          <ForNumDropdown />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name={item.name}
          control={control}
          rules={
            getRule()
          }
          render={({fieldState})=>{
            return(
              <FormControl error={fieldState.error ? true: false} fullWidth>
                <Grid container>
                  {/* 編集ボタン */}
                  <Grid item xs={12}>
                    <Button variant="contained" className="Thema_Color button_SHORT_width" onClick={()=>{setOpen(true)}}>
                       編集
                    </Button>
                    <FormEditTableDialog
                      open={open}
                      rowSettings={rowSettings}
                      defaultValue={fieldData[keyStuff][forNum]}
                      onCompleted={(data) => {
                        setValue(`${item.name}.${keyStuff}.${forNum}`,data)
                        setOpen(false)
                      }}
                    />
                  </Grid>
                  {/* テーブル表示 */}
                  <Grid item xs={12}>
                    <FormTable
                      editable={false}
                      name={`${item.name}.${keyStuff}.${forNum}`}
                      rowSettings={rowSettings}
                    />
                  </Grid>
                  {/* エラーテキスト */}
                  <Grid item xs={12}>
                    {
                      fieldState.error && (
                        <FormHelperText>{fieldState.error.message}</FormHelperText>
                      )
                    }
                  </Grid>
                </Grid>
              </FormControl>
            )
          }}
        />
      </Grid>
    </Grid>
  )
}
const FormRecipeItemTable = (
    props: {
      recipeItemList:RecipeItem[],
      settingList:CommonSettingType[]
    }
  ) => {
  
  const {recipeItemList,settingList} = props
  const ctx = React.useContext(AuthContext)

  const getComponent = (item:RecipeItem,setting:CommonSettingType) => {
    if(setting) {
      if(setting.interface === 'input') {
        return <FormRecipeInput item={item} setting={setting}/>
      } else if(setting.interface === 'textarea') {
        return <FormRecipeTextArea item={item} setting={setting}/>
      } else if(setting.interface === 'radio') {
        return <></> //未対応
      } else if(setting.interface === 'checkbox') {
        return <></> //未対応
      } else if(setting.interface === 'dropdown') {
        return <FormRecipeDropdown item={item} setting={setting}/>
      } else if(setting.interface === 'image') {
        return <FormRecipeImage item={item} setting={setting}/>
      } else if(setting.interface === 'file') {
        return <></> //未対応
      } else if(setting.interface === 'modalRadio') {
        return <FormRecipeModalRadio item={item} setting={setting}/>
      } else if(setting.interface === 'modalCheckbox') {
        return <FormRecipeModalCheckbox item={item} setting={setting}/>
      } else if(setting.interface === 'cookerSequence') {
        return <FormRecipeCookerSequence item={item} setting={setting}/>
      } else if(setting.interface === 'cookerOperation') {
        return <FormRecipeCookerOperation item={item} setting={setting}/>
      } else if(setting.interface === 'cookerStuff') {
        return <FormRecipeCookerStuff item={item} setting={setting}/>
      }
    }
    return <></>
  }

  const getLabel = (item:RecipeItem,setting:CommonSettingType) => {
    const label = item.schema?.text || setting.text || ''
    const must = setting.rules?.required?'※':''
    return(
      <Typography>
        {`${label}${must}`}
      </Typography>
    )
  }

const cell_style = {
    borderLeft: 1,
    borderRight: 1,
    borderTop: 1,
    borderBottom: 1
  };

  const th_style = {
    borderLeft: 1,
    borderRight: 1,
    borderTop: 1,
    borderBottom: 1,
    backgroundColor: WIZIoTSettings.style.regular.main_color,
    color: "white",
    "text-align": "center"//デフォルトの左寄せをセンタリングで上書き
  };

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table>
          <TableBody>
            {recipeItemList.map((v)=>{
              const setting = getSetting(v.name,ctx,settingList)
              const tlabel = setting && getLabel(v,setting) || <></>
              const tdata = setting && getComponent(v,setting) || <></>
              
              // 設定がないものは表示しない
              if(setting === undefined) {
                return <></>
              }

              return (
                <TableRow>
                  <StyledTableCell sx={th_style}>{tlabel}</StyledTableCell>
                  <StyledTableCell sx={cell_style}>{tdata}</StyledTableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}

//送信、削除ボタンのところとレシピを編集するテーブルを表示する
export const EditRecipe: React.FC<EditRecipeProps> = (props) => {
  const {recipeItemList,submitRecipeItems,deleteRecipeItems,defaultValues,settingList} = props
  const methods = useForm({defaultValues:{...defaultValues || {}}}) //defaultValuesがうまく設定できない場合はresetを使う必要がありそう

  const ActionButtons = () =>{
    const submitButtonLabel = '保存'
    const deleteButtonLabel = 'コースの削除'
    return(
      <>
        <Box sx={{"display":"flex", "flex-direction":"column"}}>
          <Box sx={{"margin-left":"auto"}}>
            <Box display="flex">
              <Link to={props.returnUrl}>
                <Button variant="contained" className="Thema_White_Color button_MEDIUM_width" sx={{ border: 1 }}>キャンセル</Button>
              </Link>
              {/* 送信ボタン */}
              <Button variant="contained" className="Thema_Color button_MEDIUM_width"
                  onClick={()=>{
                  methods.trigger().then((result)=>{
                  if(result) {
                  const submitValue = methods.getValues()
                  handleSubmit(submitValue) }})}}>
                <span className="text_justify">{submitButtonLabel}</span>
              </Button>
            </Box>
            <Box>
              <Button variant="contained" className="Thema_White_Color button_LONG_width" sx={{ border: 1 }} onClick={()=>{deleteRecipeItems()}}>
                {deleteButtonLabel}
              </Button>
            </Box>
          </Box>
        </Box>
      </>
    )
  }

  // validationがOKだったら呼ばれる
  const handleSubmit = (data:any) => {
    const submitItem = recipeItemList.map((v)=>{
      return {...v,value:data?.[v.name]}
    })
    submitRecipeItems(submitItem)
  }
  return (
    <>
      <FormProvider {...methods}>
        <form>
          <Grid container>
            <Grid item xs={12}>
              <ActionButtons />
            </Grid>
            <Grid item xs={12}>
              <FormRecipeItemTable recipeItemList={recipeItemList} settingList={settingList}/>
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </>
  )
}
