import React from 'react';
import { Navigate } from "react-router-dom";
import { AuthContext, AuthUserContextType } from "../contexts/Auth";
import axios from "../api/axios"

const RouteLogout: React.FC = () => {
  const auth = React.useContext(AuthContext);

  if (auth.value.isAuthenticated) { 
    const processLogout = async () => {
      const config = {
        withCredentials: true,
        headers: {
          "Authorization": "Bearer " + auth.value.access_token
        }
      };

      try {
        await axios.post("/auth/logout", {}, config);

        const auth_user = {
          email: "",
          delegate: "",
          isAuthenticated: false,
          access_token: "",
          expires: 0,
          roles: [],
          customer_roles: [],
          dashboard_url: null
        } as AuthUserContextType;

        auth.setValue(auth_user);

      } catch(err) {
      }
    };

    processLogout();
  }

  return <Navigate replace to="/" />;
}
export default RouteLogout;
