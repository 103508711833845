import React from 'react';
//import logo from './logo.svg';
import './App.css';
import { Routes, Route } from 'react-router-dom';
import RouteHome from './routes/RouteHome';
import RouteIndex from './routes/RouteIndex';
import RouteLogout from './routes/RouteLogout';
import RouteLogin from './routes/RouteLogin';
import RouteAppInfo from './routes/RouteAppInfo';
import RouteAppErr from './routes/RouteAppErr';
import RouteListFridge from './routes/RouteListFridge';
import RouteAddFridge from './routes/RouteAddFridge';
import RouteEditFridge from './routes/RouteEditFridge';
import RouteListCooker from './routes/RouteListCooker';
import RouteAddCooker from './routes/RouteAddCooker';
import RouteEditCooker from './routes/RouteEditCooker';
import RouteCookerInfo from './routes/RouteCookerInfo';
import RouteSetting from './routes/RouteSetting';
import RouteAddNotification from './routes/RouteAddNotification';
import RouteEditNotification from './routes/RouteEditNotification';
import RouteListModelName from './routes/RouteListModelName';
import RouteEditModelName from './routes/RouteEditModelName';
import RouteEditFirmware from './routes/RouteEditFirmware';
import RouteListFirmware from './routes/RouteListFirmware';
import RouteListWireless from './routes/RouteListWireless';
import RouteListMainFW from './routes/RouteListMainFW';
import RouteEditUser from './routes/RouteEditUser';
import RouteAddUser from './routes/RouteAddUser';
import RouteSelfUser from './routes/RouteSelfUser';
import RouteInquiry from './routes/RouteInquiry'
import NotFound from './routes/NotFound'
import AuthProvider from "./contexts/Auth";

function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<RouteIndex />} />
        <Route path="/login" element={<RouteLogin />} />
        <Route path="/home" element={<RouteHome />} />
        <Route path="/app_info" element={<RouteAppInfo />} />
        <Route path="/notification/+" element={<RouteAddNotification />} />
        <Route path="/notification/:id" element={<RouteEditNotification />} />
        <Route path="/app_err" element={<RouteAppErr />} />
        <Route path="/fridge" element={<RouteListFridge />} />
        <Route path="/fridge/+" element={<RouteAddFridge />} />
        <Route path="/fridge/:id" element={<RouteEditFridge />} />
        <Route path="/cooker" element={<RouteListCooker />} />
        <Route path="/cooker/+" element={<RouteAddCooker />} />
        <Route path="/cooker/:id" element={<RouteEditCooker />} />
        <Route path="/cooker_info" element={<RouteCookerInfo />} />
        <Route path="/setting" element={<RouteSetting />} />
        <Route path="/user/+" element={<RouteAddUser />} />
        <Route path="/user/self" element={<RouteSelfUser />} />
        <Route path="/user/:id" element={<RouteEditUser />} />
        <Route path="/logout" element={<RouteLogout />} />
        <Route path="/model_name/air" element={<RouteEditModelName category="air"/>} />
        <Route path="/model_name/cooker" element={<RouteEditModelName category="cooker" />} />
        <Route path="/model_name/fridge" element={<RouteEditModelName category="fridge" />} />
        <Route path="/model_name/cellar" element={<RouteEditModelName category="cellar" />} />
        <Route path="/model_name" element={<RouteListModelName />} />
        <Route path="/firmware/air/+" element={<RouteEditFirmware category="air"/>} />
        <Route path="/firmware/cooker/+" element={<RouteEditFirmware category="cooker"/>} />
        <Route path="/firmware/fridge/+" element={<RouteEditFirmware category="fridge"/>} />
        <Route path="/firmware/cellar/+" element={<RouteEditFirmware category="cellar"/>} />
        <Route path="/firmware" element={<RouteListFirmware />} />
        <Route path="/mainfw/air" element={<RouteListMainFW category="air" />} />
        <Route path="/mainfw/cooker" element={<RouteListMainFW category="cooker" />} />
        <Route path="/mainfw/fridge" element={<RouteListMainFW category="fridge" />} />
        <Route path="/mainfw/cellar" element={<RouteListMainFW category="cellar" />} />
        <Route path="/wireless" element={<RouteListWireless />} />
        <Route path="/inquiry" element={<RouteInquiry />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AuthProvider>
  );
}

export default App;
