import { TextField } from '@mui/material';
import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';


export type FormTextareaProps = {
	name: string
	fullWidth?: boolean
	rules?: any
	rows?: number
	placeholder?:string
	label?:string
}

export const FormTextarea:React.FC<FormTextareaProps> = (props) => {
  const {control} = useFormContext()

  return (
    <Controller 
      control={control} 
      name={props.name}
      rules={props.rules}
      render={({field,fieldState})=>{
        return (
          <TextField
						{...field} 
						fullWidth={props.fullWidth}
						multiline
            rows={props.rows}
						label={props.label}
            placeholder={props.placeholder}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
          />
        )
      }}
    />
  )
}
