import React from 'react';
import { useNavigate, Link } from "react-router-dom";
import Button from '@mui/material/Button'
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import { authAxios } from "../../api/axios";
import { AuthContext } from "../../contexts/Auth";

import WIZIoTSettings from "../../constants/wiziot.json";

const Thema_Color = WIZIoTSettings.style.regular.main_color;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: Thema_Color,
    color: "white",
    borderColor: "gray",
    "text-align": "center"
  },
  [`&.${tableCellClasses.body}`]: {
    borderColor: "gray"
  }
}));

type UserItem = {
  id: string;
  email: string;
  lastName: string;
  firstName: string;
  roles: string[];
}

type Props = {
  children?: React.ReactNode;
  email: string;
  roles: string[];
};

let loading_done_1 = false;
let loading_done_2 = false;

const Setting: React.FC<Props> = ({children, email, roles}) => {
  const auth = React.useContext(AuthContext);
  const [users, setUsers] = React.useState<UserItem[]>([]);
  const [myself, setMyself] = React.useState<UserItem>({email: email} as UserItem);
  const [isLoadingDialogOpened, setLoadingDialogOpened] = React.useState<boolean>(true);
  const navigate = useNavigate();

  React.useEffect(() => {
    loading_done_1 = false;

    authAxios(auth).get("/user/self").then((response) => {
      setMyself(response.data.data as UserItem);

      loading_done_1 = true;

      if (loading_done_1 && loading_done_2) {
        closeLoadingDialog();
      }
    }).catch((error) => {
      if (error.response) {
        if (error.response.status === 403) {
          auth.value.access_token = "";
          auth.value.expires = 0;
          auth.value.email = "";
          auth.value.delegate = "";

          navigate("/");
        }
      }

      setMyself({
        id: '0',
        email: email,
        lastName: '',
        firstName: '',
        roles: []
      } as UserItem);

      loading_done_1 = true;

      if (loading_done_1 && loading_done_2) {
        closeLoadingDialog();
      }
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (auth.value.roles?.includes("wiziotmgr_full_admin") ||
        auth.value.roles?.includes("wiziotmgr_contract_admin") ||
        auth.value.roles?.includes("wiziotmgr_customer_admin")) {

      loading_done_2 = false;

      authAxios(auth).get("/user").then((response) => {
        setUsers(response.data.data.users as UserItem[]);

        loading_done_2 = true;

        if (loading_done_1 && loading_done_2) {
          closeLoadingDialog();
        }
      }).catch((error) => {
        if (error.response) {
          if (error.response.status === 403) {
            auth.value.access_token = "";
            auth.value.expires = 0;
            auth.value.email = "";
            auth.value.delegate = "";

            navigate("/");
          }
        }

        setUsers([
          {
            id: '0',
            email: 'hoge@localhost',
            lastName: 'データを取得できませんでした',
            firstName: '',
            roles: []
          }
        ] as UserItem[]);

        loading_done_2 = true;

        if (loading_done_1 && loading_done_2) {
          closeLoadingDialog();
        }
      });
    } else {
      loading_done_2 = true;

      if (loading_done_1 && loading_done_2) {
        closeLoadingDialog();
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // --- 関数群

  const getAdminButton = (roles: string[]) => {
    let admin_tags = (
      <>
        一般ユーザー
      </>
    );

    if (roles?.includes('wiziotmgr_customer_admin')) {
      admin_tags = (
        <>
          管理者
        </>
      );
    }

    if (roles?.includes('wiziotmgr_contract_admin')) {
      admin_tags = (
        <>
          契約管理者
        </>
      );
    }

    if (roles?.includes('wiziotmgr_full_admin')) {
      admin_tags = (
        <>
          AIoT管理者
        </>
      );
    }

    return admin_tags;
  }

  const getPrivButtons = (roles: string[]) => {
    const privs = [];

    if (roles?.includes('wiziotmgr_dashboard_ro')) {
      privs.push(
        <Button className="blue_button_color" sx={{ m: 1 }} disabled>
          ダッシュボード<br />
          閲覧
        </Button>
      );
    }

    if (roles?.includes('wiziotmgr_cooker_rw')) {
      privs.push(
        <Button className="aiot_orange_button_color" sx={{ m: 1 }} disabled>
         調理レシピ<br />
         修正
        </Button>
      );
    }
 
    if (roles?.includes('wiziotmgr_cooker_ro')) {
      privs.push(
        <Button className="blue_button_color" sx={{ m: 1 }} disabled>
          調理レシピ<br />
          閲覧
        </Button>
      );
    }

    if (roles?.includes('wiziotmgr_fridge_rw')) {
      privs.push(
        <Button className="aiot_orange_button_color" sx={{ m: 1 }} disabled>
          冷蔵コース<br />
          修正
        </Button>
      );
    }

    if (roles?.includes('wiziotmgr_fridge_ro')) {
      privs.push(
        <Button className="blue_button_color" sx={{ m: 1 }} disabled>
          冷蔵コース<br />
          閲覧
        </Button>
      );
    } 

    if (roles?.includes('wiziotmgr_appinfo_rw')) {
      privs.push(
        <Button className="aiot_orange_button_color" sx={{ m: 1 }} disabled>
          お知らせ管理<br />
          修正
        </Button>
      );
    }

    if (roles?.includes('wiziotmgr_appinfo_ro')) {
      privs.push(
        <Button className="blue_button_color" sx={{ m: 1 }} disabled>
          お知らせ管理<br />
          閲覧
        </Button>
      );
    }

    if (roles?.includes('wiziotmgr_apperr_rw')) {
      privs.push(
        <Button className="aiot_orange_button_color" sx={{ m: 1 }} disabled>
          エラー管理<br />
          修正
        </Button>
      );
    }

    if (roles?.includes('wiziotmgr_apperr_ro')) {
      privs.push(
        <Button className="blue_button_color" sx={{ m: 1 }} disabled>
          エラー管理<br />
          閲覧
        </Button>
      );
    }

    if (roles?.includes('wiziotmgr_content_po')) {
      privs.push(
        <Button className="blue_button_color" sx={{ m: 1 }} disabled>
          コンテンツ管理<br />
          公開限定閲覧
        </Button>
      );
    }

    if (roles?.includes('wiziotmgr_content_ro')) {
      privs.push(
        <Button className="blue_button_color" sx={{ m: 1 }} disabled>
          コンテンツ管理<br />
          閲覧
        </Button>
      );
    }

    if (roles?.includes('wiziotmgr_content_rw')) {
      privs.push(
        <Button className="aiot_orange_button_color" sx={{ m: 1 }} disabled>
          コンテンツ管理<br />
          修正
        </Button>
      );
    }

    if (roles?.includes('wiziotmgr_device_ro')) {
      privs.push(
        <Button className="blue_button_color" sx={{ m: 1 }} disabled>
          機種名登録<br />
          閲覧
        </Button>
      );
    }

    if (roles?.includes('wiziotmgr_device_rw')) {
      privs.push(
        <Button className="aiot_orange_button_color" sx={{ m: 1 }} disabled>
          機種名登録<br />
          修正
        </Button>
      );
    }

    if (roles?.includes('wiziotmgr_firmware_ro')) {
      privs.push(
        <Button className="blue_button_color" sx={{ m: 1 }} disabled>
          ファームウェア管理<br />
          閲覧
        </Button>
      );
    }

    if (roles?.includes('wiziotmgr_firmware_rw')) {
      privs.push(
        <Button className="aiot_orange_button_color" sx={{ m: 1 }} disabled>
          ファームウェア管理<br />
          修正
        </Button>
      );
    }

    return privs;
  }

  const openLoadingDialog = () => {
    setLoadingDialogOpened(true);
  };

  const closeLoadingDialog = () => {
    setLoadingDialogOpened(false);
  };

  // --- 定数群

  const cell_style = {borderLeft: 1, borderRight: 1};
  const admin_tags = getAdminButton(roles);
  const privs = getPrivButtons(roles);

  let admin_mode_tags = (
    <>
    </>
  );

  if (auth.value.roles?.includes("wiziotmgr_full_admin") ||
      auth.value.roles?.includes("wiziotmgr_contract_admin") ||
      auth.value.roles?.includes("wiziotmgr_customer_admin")) {
    const users_tags = [];

    for (let i = 0; i < users.length; i++) {
      if (users[i].roles?.includes('wiziotmgr_contract_admin')) {
        continue;
      }

      const user_admin_tags = getAdminButton(users[i].roles);
      const user_privs = getPrivButtons(users[i].roles);
      const user_link = '/user/' + users[i].id;

      users_tags.push(
        <TableRow>
          <StyledTableCell sx={cell_style}>{users[i].lastName} {users[i].firstName}</StyledTableCell>
          <StyledTableCell sx={cell_style}>
            <Link to={user_link}>{users[i].email}</Link>
          </StyledTableCell>
          <StyledTableCell sx={cell_style}>{user_admin_tags}</StyledTableCell>
          <StyledTableCell sx={cell_style}>{user_privs}</StyledTableCell>
        </TableRow>
      );
    }

    admin_mode_tags = (
      <>
        <Divider sx={{ mb: 2, mt: 2 }}>
          <Chip label="ユーザー一覧" />
        </Divider>
        <Box display="flex" sx={{ mt: 2, mb: 2 }}>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Link to="/user/+">
            <Button variant="contained" className="Thema_Color button_LONG_width">ユーザーを追加</Button>
          </Link>
        </Box>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell sx={cell_style}>氏名</StyledTableCell>
                <StyledTableCell sx={cell_style}>Eメールアドレス</StyledTableCell>
                <StyledTableCell sx={cell_style}>タイプ</StyledTableCell>
                <StyledTableCell sx={cell_style}>権限</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users_tags}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    );
  }

  return (
    <>
      <Box>
        <Box component="span" display="flex" justifyContent="space-between">
          <Link className="font-thema-color bolder" to="/setting">
            設定＞ユーザー設定
          </Link>
        </Box>
          <Divider sx={{ mb: 2 }}>
          <Chip label="あなたの情報" />
        </Divider>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <StyledTableCell sx={cell_style}>氏名</StyledTableCell>
                <StyledTableCell sx={cell_style}>Eメールアドレス</StyledTableCell>
                <StyledTableCell sx={cell_style}>タイプ</StyledTableCell>
                <StyledTableCell sx={cell_style}>権限</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledTableCell sx={cell_style}>{myself.lastName} {myself.firstName}</StyledTableCell>
                <StyledTableCell sx={cell_style}>
                  <Link to="/user/self">{email}</Link>
                </StyledTableCell>
                <StyledTableCell sx={cell_style}>{admin_tags}</StyledTableCell>
                <StyledTableCell sx={cell_style}>{privs}</StyledTableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {admin_mode_tags}
      </Box>
      <Dialog open={isLoadingDialogOpened}>
        <DialogContent>
          <Box>
            <CircularProgress />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
 
export default Setting;
